import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CgSpinner } from "react-icons/cg";
import AdminNavBar from "../../AdminNavBar";
import { Helmet } from "react-helmet";

import { toast } from "react-hot-toast";
//import loading pages
import ListLoading from "../../../../component/ListLoading";
import locationData from "../../../../Location.json";

//icons
import search from "../../../../img/icn/search.png";
import add from "../../../../img/icn/add.png";
import cancel from "../../../../img/icn/cancel.png";
import delete_icon from "../../../../img/icn/delete.png";
import date from "../../../../img/icn/date.png";
import locationIcn from "../../../../img/icn/location.png";

import price from "../../../../img/icn/money.png";
import action from "../../../../img/icn/action.png";

import axios from "axios";

function GroupBuildingManage() {
  const token = localStorage.getItem("ad-t");
  const location = useLocation();
  const renderListLoadingPage = () => {
    return <ListLoading />;
  };
  const [loading, setLoading] = useState(false);

  const [statusCode, setStatusCode] = useState();

  //search
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [totalItems, setTotalItems] = useState();

  const [divisionId, setDivisionId] = useState("");
  const [district, setDistrict] = useState([]);
  const [districtId, setDistrictId] = useState("");

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  ///Setting font style
  const font = localStorage.getItem("lng");
  let setFont;
  if (font == "bn") {
    setFont = "font-TiroBangla";
  } else if (font == "en") {
    setFont = "font-Poppins-Regular";
  }

  //search engine
  const handleDivision = (e) => {
    const getDivisionId = e.target.value;
    // Check if "Select Division" is selected
    if (getDivisionId === "") {
      // Reset the district state
      setDistrict([]);
    } else {
      // Find the selected division and set the district data
      const getDistrictData = locationData.find(
        (division) => division.division_id === getDivisionId
      ).district;
      setDistrict(getDistrictData);
    }
    // Set the divisionId
    setDivisionId(getDivisionId);
  };

  // Modify the handleDistrict function to set sub-districts
  const handleDistrict = (e) => {
    const districtId = e.target.value;
    setDistrictId(districtId);
  };

  //search
  useEffect(() => {
    if (!localStorage.getItem("ad-t")) {
      navigate("/admin");
      return; // Add a return statement to exit early
    }

    const fetchSuggestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/propertyGroupSearchAd?searchText=${searchText}&division=${divisionId}&district=${districtId}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSuggestions(response.data.items);
      } catch (error) {
        console.error(error);
      }
    };

    // Only fetch suggestions if there is text in the input
    if (searchText.trim() !== "") {
      fetchSuggestions();
    } else {
      setSuggestions([]);
    }
  }, [searchText]);

  const handleSearch = async () => {
    setSearchLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/propertyGroupSearchAd?searchText=${searchText}&division=${divisionId}&district=${districtId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSearchResults(response.data.items);
      setTotalItems(response.data.totalItems);
      setSuggestions([]);
      setSearchLoading(false);

      if (response.data.totalItems === 0) {
        toast.error("No result found");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClear = () => {
    setSearchText("");
    setSearchResults([]);
    setSuggestions([]);
  };

  const [postsAPI, setPostsAPI] = useState([]);

  const queryParams = new URLSearchParams(location.search);
  const currentPageQueryParam = queryParams.get("page");
  const initialPage = currentPageQueryParam
    ? parseInt(currentPageQueryParam)
    : 1;

  const [currentPage, setCurrentPage] = useState(initialPage);
  const postPerPage = 8;
  const [totalPages, setTotalPages] = useState(1);

  // Function to fetch data based on page and limit

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/admin/getGrpupBuildingCardForAdmin?page=${currentPage}&limit=${postPerPage}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPostsAPI(response.data);
      setStatusCode(response.status);
      setTotalPages(Math.ceil(response.data.totalItems / postPerPage));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const updateURL = (page) => {
    const newParams = new URLSearchParams(queryParams);
    newParams.set("page", page);
    window.history.replaceState(
      null,
      null,
      `${location.pathname}?${newParams.toString()}`
    );
  };

  // Function to handle opening detail view
  const handleOpenDetail = (item) => {
    navigate(`/admin/Group-Wise-Building-Details-Ad/${item._id}`);
  };

  //Action modal
  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const handleAction = (event, item) => {
    event.stopPropagation();
    setShowModal(true);
    setSelectedPost(item);
  };

  // Enable/Disable post
  const handleToggleEnableDisableClicked = async () => {
    const postId = selectedPost._id;
    const newEnabledStatus = !selectedPost.enabled;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/ToggleEnabledGroupWisePost`,
        {
          postId: selectedPost._id,
          enabled: newEnabledStatus, // Corrected this line to use `enabled` instead of `approval`
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setSelectedPost({ ...selectedPost, enabled: newEnabledStatus });
        setPostsAPI((prevData) => {
          const updatedEnabledPosts = prevData.enabledPosts.filter(
            (item) => item._id !== postId
          );
          const updatedDisabledPosts = prevData.disabledPosts.filter(
            (item) => item._id !== postId
          );
          if (newEnabledStatus) {
            updatedEnabledPosts.push({
              ...selectedPost,
              enabled: newEnabledStatus,
            });
          } else {
            updatedDisabledPosts.push({
              ...selectedPost,
              enabled: newEnabledStatus,
            });
          }
          return {
            ...prevData,
            enabledPosts: updatedEnabledPosts,
            disabledPosts: updatedDisabledPosts,
          };
        });
        toast.success(
          `Post has been ${newEnabledStatus ? "enabled" : "disabled"}.`
        );
      } else {
        toast.error("Failed to toggle the post status.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while toggling the post status.");
    }
  };

  //delete click
  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteClicked = () => {
    setShowDelete(true);
  };

  const handleDeletePost = () => {
    const postId = selectedPost._id;

    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/api/admin/deleteGroupBuildingPost/${postId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Post deleted successfully");

          // Update the state to remove the deleted post from both enabled and disabled lists
          setPostsAPI((prevPosts) => {
            const updatedEnabledPosts = prevPosts.enabledPosts.filter(
              (post) => post._id !== postId
            );
            const updatedDisabledPosts = prevPosts.disabledPosts.filter(
              (post) => post._id !== postId
            );

            return {
              ...prevPosts,
              enabledPosts: updatedEnabledPosts,
              disabledPosts: updatedDisabledPosts,
            };
          });
        } else {
          console.error(
            "Failed to delete group building post:",
            response.data.error
          );
          toast.error("Failed to delete post");
        }
      })
      .catch((error) => {
        console.error("Error deleting group building post:", error);
        toast.error("Error deleting post");
      });

    setShowModal(false);
    setShowDelete(false);
  };

  const handleEditClicked = () => {
    const postId = selectedPost._id;
    setShowModal(false);
    navigate(`/admin/Group-Building-Edit/${postId}`);
  };

  //cancel click
  const handleCancel = () => {
    setShowDelete(false);
    setShowModal(false);
  };

  return (
    <div className={`MainSection   ${setFont}  px-2 min-h-screen `}>
      <Helmet>
        <title>Group Building Manage - Admin Panel</title>
      </Helmet>
      <div className="md:flex md:my-5 my-1">
        <AdminNavBar activePage="Group-Building-Manage" />

        <div className="w-full ">
          {/* search section*/}
          <div className=" md:my-5 py-2 lg:py-4 md:mt-5 mt-2 w-[98%] md:w-[95%] lg:w-full px-2 md:px-10 lg:px-5  md:items-center bg-[#ffffff]  rounded-xl  duration-200 ">
            <div className=" flex items-center justify-between  ">
              <div className="">
                <Link to={"/admin/Set-Group-Building-Post"}>
                  <div className="md:h-10 h-8 md:w-[150px] w-[130px] flex space-x-1 md:mt-0 mt-2 justify-center items-center bg-green-200 rounded-lg border border-green-500 hover:bg-green-400 duration-200">
                    <img
                      className="md:h-8 h-6 md:w-8 w-6"
                      src={add}
                      alt="add"
                    />
                    <p className=" md:text-base text-sm">Add Post</p>
                  </div>
                </Link>
              </div>

              <div className="  px-2 ">
                <div className="flex flex-wrap  w-full space-y-2 sm:space-y-0 items-center">
                  {/* Location*/}
                  <div className=" md:ml-3 ">
                    <div className="flex lg:space-x-3">
                      <div className=" 2xl:h-10 h-8  w-[130px]">
                        <select
                          name="Division"
                          required
                          className="2xl:h-10 h-8 text-xs 2xl:text-sm w-full bg-gray-50 border  border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 focus:border-green-500 block  dark:bg-white dark:border-green-500 dark:placeholder-gray-400 dark:text-black dark:focus:ring-green-500 dark:focus:border-green-500"
                          onChange={(e) => handleDivision(e)}
                        >
                          <option value="" className="text-pink-600">
                            Select Division
                          </option>
                          {locationData.map((getDivision, index) => (
                            <option value={getDivision.division_id} key={index}>
                              {getDivision.division_name}
                            </option>
                          ))}
                        </select>
                      </div>

                      {district.length > 0 && (
                        <div className=" 2xl:h-10 h-8 ml-1  ">
                          <select
                            name="states"
                            className="2xl:h-10 h-8 text-xs 2xl:text-sm w-full bg-gray-50 border px-1 border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 focus:border-green-500 block  dark:bg-white dark:border-green-500 dark:placeholder-gray-400 dark:text-black dark:focus:ring-green-500 dark:focus:border-green-500"
                            onChange={(e) => handleDistrict(e)}
                          >
                            <option value="" className="text-pink-600">
                              Select District
                            </option>
                            {district.map((getDistrict, index) => (
                              <option
                                value={getDistrict.district_id}
                                key={index}
                              >
                                {getDistrict.district_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="md:mt-0 mt-2   items-center flex duration-200">
                    <input
                      type="text"
                      className="2xl:h-10 text-xs 2xl:text-sm h-8  w-full  border border-green-500 md:ml-2 rounded-lg p-2 duration-200"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Title, Division, District, (optional)"
                    />
                    <button
                      onClick={handleSearch}
                      className="2xl:h-10 h-8 sm:px-3 sm:bg-green-200 sm:border sm:border-green-500   rounded-xl sm:ml-3 ml-1 space-x-2 flex justify-center items-center duration-200"
                    >
                      {searchLoading && (
                        <CgSpinner
                          size={20}
                          className=" animate-spin duration-200"
                        />
                      )}
                      <span className="hidden sm:inline text-xs 2xl:text-sm duration-200">
                        Search
                      </span>

                      <img
                        src={search}
                        alt="search"
                        className="inline sm:hidden w-6 h-6"
                      />
                    </button>
                  </div>

                  {(searchText.trim() !== "" ||
                    suggestions.length > 0 ||
                    searchResults.length > 0) && (
                    <div
                      onClick={handleClear}
                      className=" w-6 h-6  xl:h-7 xl:w-7 2xl:h-9 2xl:w-9 flex items-center cursor-pointer ml-3 duration-200 "
                    >
                      <img className=" w-full h-full " src={cancel} alt="" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Display loading indicator */}

          <div className="MainBody flex duration-200 mt-5 border border-gray-200 rounded-xl">
            {statusCode !== 200 ? (
              <div className="h-full w-full flex justify-center items-center">
                <div className="space-y-3 px-2 w-full">
                  {renderListLoadingPage()}
                </div>
              </div>
            ) : (
              <div className="MainBody w-full mt-5 px-5 space-y-2 mb-14 duration-200 ">
                <p className="font-bold mb-5">Manage Group Wise Post</p>

                {suggestions && suggestions.length > 0 ? (
                  <div className="">
                    <p className=" text-center my-5 text-yellow-700 bg-yellow-100">
                      Matching with....
                    </p>

                    <div>
                      {suggestions.map((result) => (
                        <DefaultCard
                        key={result._id}
                        result={result}
                        handleOpenDetail={handleOpenDetail}
                        handleAction={handleAction}
                      />
                      ))}
                    </div>
                  </div>
                ) : searchResults && searchResults.length > 0 ? (
                  <div>
                    <p className=" text-center my-5 text-green-700 bg-green-200">
                      {totalItems} result found
                    </p>

                    {searchResults.map((result) => (
                      <DefaultCard
                      key={result._id}
                      result={result}
                      handleOpenDetail={handleOpenDetail}
                      handleAction={handleAction}
                    />
                    ))}
                  </div>
                ) : postsAPI &&
                  postsAPI.enabledPosts &&
                  postsAPI.disabledPosts ? (
                  <div className="items-center mt-5 duration-200 ">
                    {/* Disabled posts section */}
                    {postsAPI.disabledPosts.length > 0 && (
                      <>
                        <p className="text-red-600 font-semibold">
                          Disabled Posts ({postsAPI.disabledPosts.length})
                        </p>
                        {postsAPI.disabledPosts.map((post) => (

                          <DefaultCard
                            key={post._id}
                            result={post}
                            handleOpenDetail={handleOpenDetail}
                            handleAction={handleAction}
                          />
                        ))}
                      </>
                    )}

                    {/* Enabled posts section */}
                    <h2 className="text-green-600 font-semibold">
                      Enabled Posts ({postsAPI.enabledPosts.length})
                    </h2>
                    {postsAPI.enabledPosts.map((post) => (

                      <DefaultCard
                            key={post._id}
                            result={post}
                            handleOpenDetail={handleOpenDetail}
                            handleAction={handleAction}
                          />
                    ))}
                  </div>
                ) : (
                  <p className="text-2xl">
                    No Group Wise Building posts available.
                  </p>
                )}

                <div className="flex justify-center mt-3 mb-3 items-center">
                  {/* Pagination buttons */}
                  <div className="pagination text-center mt-5 h-10">
                    {/* Pagination buttons */}
                    {totalPages > 1 && (
                      <div className="pagination text-center mt-5 h-10">
                        {currentPage > 1 && (
                          <button
                            className="pagination-button h-8 w-24 mx-1 mr-3 rounded-md border border-green-600 duration-200"
                            onClick={() => {
                              if (currentPage > 1) {
                                setCurrentPage(currentPage - 1);
                                //updateURL(currentPage - 1);
                              }
                            }}
                          >
                            {"« Previous"}
                          </button>
                        )}

                        {Array.from({ length: totalPages }).map((_, index) => (
                          <button
                            key={index}
                            className={`pagination-button h-8 w-7 mx-1 rounded-md border border-green-600 duration-200 ${
                              currentPage === index + 1
                                ? "active bg-green-400"
                                : ""
                            }`}
                            onClick={() => {
                              setCurrentPage(index + 1);
                              updateURL(index + 1);
                            }}
                          >
                            {index + 1}
                          </button>
                        ))}

                        {currentPage < totalPages && (
                          <button
                            className="pagination-button h-8 ml-3 w-24 mx-1 rounded-md border border-green-600 duration-200"
                            onClick={() => {
                              if (currentPage < totalPages) {
                                setCurrentPage(currentPage + 1);
                                updateURL(currentPage + 1);
                              }
                            }}
                          >
                            {"Next »"}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {showModal && (
            <div className="fixed z-50  px-3 inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white  md:p-5 p-4 rounded-2xl relative duration-200">
                <button
                  className="px-4 py-2 absolute right-0  bg-red-300 rounded-l-md"
                  onClick={handleCancel}
                >
                  Cancel
                </button>

                <div className="  ">
                  <h3 className="text-xl  mb-4 text-red-700 font-bold">
                    Take action
                  </h3>

                  <div className="flex justify-center items-center ">
                    <div className="   ">
                      <div className=" flex justify-center ">
                        <img
                          className="w-[15rem] max-h-[200px] object-contain lg:w-[25rem] duration-200"
                          src={selectedPost.imageUrls}
                          alt="user"
                        />
                      </div>

                      <div className=" text-center ">
                        <p
                          className="text-bold lg:text-xl line-clamp-1  cursor-pointer underline text-blue-600"
                          onClick={() => handleOpenDetail(selectedPost)}
                        >
                          {" "}
                          {selectedPost.postTitle}{" "}
                        </p>

                        <p>Post Date: {selectedPost.postDate} </p>
                        <p className="">Admin Id: {selectedPost.adminId} </p>
                        <p className=" text-green-600 font-semibold">
                          Price: {selectedPost.price}{" "}
                        </p>
                        <p className="mb-3">
                          Location: {selectedPost.subDistrict},{" "}
                          {selectedPost.district}, {selectedPost.division}{" "}
                        </p>

                        <p
                          className={`inline uppercase font-bold ${
                            selectedPost.enabled === true
                              ? "text-white bg-green-500 p-1 rounded-md"
                              : "text-red-700 bg-red-200 p-1 rounded-md"
                          }`}
                        >
                          {selectedPost.enabled === true
                            ? "Post enabled"
                            : "Post disabled"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 flex justify-center items-center space-x-3 ">
                    {!showDelete && (
                      <>
                        {selectedPost.enabled === true ? (
                          <button
                            className="px-4 py-2 bg-red-400 text-white rounded-md"
                            onClick={() =>
                              handleToggleEnableDisableClicked(selectedPost.id)
                            }
                          >
                            Disable Post
                          </button>
                        ) : (
                          <button
                            className="px-4 py-2 bg-green-500 text-white rounded-md"
                            onClick={() =>
                              handleToggleEnableDisableClicked(selectedPost.id)
                            }
                          >
                            Enable Post
                          </button>
                        )}
                        <button
                          className=" px-4 py-2 bg-yellow-500 text-white rounded-md"
                          onClick={handleEditClicked}
                        >
                          Edit Post
                        </button>
                        <button
                          className=" px-4 py-2 bg-red-500 text-white rounded-md"
                          onClick={handleDeleteClicked}
                        >
                          Delete Post
                        </button>
                      </>
                    )}
                  </div>
                  <div>
                    {showDelete ? (
                      <div className="p-2  border bg-red-100 rounded-xl text-center items-center">
                        <div className="   font-bold ">
                          <p className="text-sm lg:text-base ">
                            {`Do you really want to delete `}
                            <span className="text-red-500">
                              {selectedPost.postTitle} ?
                            </span>
                          </p>

                          <p className="lg:text-base text-sm">
                            Its also delete All the{" "}
                            <span className="text-red-500">
                              User Booking data.
                            </span>
                          </p>
                        </div>

                        <button
                          className=" px-4 mt-4 py-2 bg-red-500 text-white rounded-md"
                          onClick={handleDeletePost}
                        >
                          Delete Post
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}


const DefaultCard = ({ result, handleOpenDetail, handleAction }) => (
  <div className="flex duration-200 space-x-10">
    <div
      className="w-full rounded-lg flex justify-between items-center p-1 duration-200 cursor-pointer"
      onClick={() => handleOpenDetail(result)}
    >
      <div className="flex w-full">
        <div
          className={`border w-full ${
            result.enabled === false ? "bg-red-100" : "bg-green-50"
          } border-gray-400 mb-3 rounded-lg p-1 md:p-4 flex justify-between items-center`}
        >
          <div className="flex max-w-[80%]">
            <img
              src={result.imageUrls[0]}
              alt={result.postTitle}
              className="mr-2 md:h-24 h-16 rounded-lg md:w-24 w-16"
            />
            <div className="space-y-[2px] mt-[-5px]">
              <p className="line-clamp-1 font-bold md:text-base text-xs">
                {result.postTitle}
              </p>
              <div className="flex items-center">
                <img src={date} alt="added date" className="h-5 w-5 mr-2" />
                <p className="md:text-base text-[10px]">{result.postDate}</p>
              </div>
              <div className="flex items-center">
                <img
                  src={locationIcn}
                  alt="locations"
                  className="h-5 w-5 mr-2"
                />
                <p className="lg:text-sm text-xs">
                  {result.subDistrict}, {result.district}, {result.division}
                </p>
              </div>
              <div className="flex items-center">
                <img src={price} alt="prices" className="h-[18px] w-[18px] mr-2" />
                <p className="md:text-base text-[10px]">{result.price + " Tk"}</p>
              </div>
            </div>
          </div>
          {result.bookings > 0 && (
            <div className="md:text-base px-2 text-gray-500 text-[10px]">
              <p>{result.bookings} Booking</p>
            </div>
          )}
          <div className="flex">
            <div
              className="cursor-pointer bg-red-200 rounded-md px-2 py-2"
              onClick={(event) => handleAction(event, result)}
            >
              <div className="flex items-center">
                <img
                  src={action}
                  alt="action"
                  className="md:h-7 h-5 md:w-7 w-5"
                />
                <p className="md:text-base hidden ml-2 lg:block">Action</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);


export default GroupBuildingManage;
