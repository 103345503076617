import React, { useState, useEffect } from "react";
import AdminNavBar from "../admin/AdminNavBar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import ListLoading from "../../component/ListLoading";
import icn_user from "../../img/icn/icn_user.png";

function UserManagement() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("ad-t");
  const [userData, setUserData] = useState();

  ///Setting font style
  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const font = localStorage.getItem("lng");
  let setFont;
  if (font == "bn") {
    setFont = "font-TiroBangla";
  } else if (font == "en") {
    setFont = "font-Poppins-Regular";
  }

  useEffect(() => {
    if (!localStorage.getItem("ad-t")) {
      navigate("/admin");
      return; // Add a return statement to exit early
    }

    const fetchPrivateData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ad-t")}`,
        },
      };

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/userlist`,
          config
        );
        setUserData(data.users);
        setIsLoading(false);
      } catch (error) {
        localStorage.removeItem("ad-t");
        localStorage.removeItem("ad-n");
        setError("You are not authorized, please login");
        setIsLoading(false); // Make sure to set isLoading to false in case of an error
      }
    };

    fetchPrivateData();
  }, [navigate]); // Include navigate as a dependency

  // Function to handle opening detail view
  const handleOpenDetail = (id) => {
    navigate(`/ProfileDetails/${id}`);
  };

  const renderListLoadingPage = () => {
    return <ListLoading />;
  };

  //Action modal
  const [showModal, setShowModal] = useState(false);
  const [userSelectedData, setUserSelectedData] = useState(null);

  const handleUserAction = (event, item) => {
    event.stopPropagation();

    setShowModal(true);
    setUserSelectedData(item);
  };

  //delete click
  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteClicked = () => {
    setShowEnable(false);
    setTakeActionSuspend(false);
    setShowSuspend(false);
    setShowDisable(false);
    setShowDelete(true);
  };

  const handleDeleteAccount = async () => {
    const userId = userSelectedData.id;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      // Step 1: Delete user account, including posts and images
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/admin/deleteUser/${userId}`,
        config
      );
      setIsLoading(false);
      toast.success("User deleted with all posts");
      setShowModal(false);
      setUserData((prevUser) => prevUser.filter((user) => user.id !== userId));
    } catch (error) {
      // Handle errors here
      console.error("Error deleting user account:", error);
      // You may want to set an error state or show a notification to the user
    }
  };

  //suspend click
  const [showSuspend, setShowSuspend] = useState(false);
  const handleSuspendClicked = () => {
    setShowEnable(false);
    setTakeActionSuspend(false);
    setShowDelete(false);
    setShowDisable(false);
    setShowSuspend(true);
  };

  const [suspendDays, setSuspendDays] = useState("3");

  const handleSuspendAccount = async () => {
    const userId = userSelectedData.id;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const requestBody = {
      suspendDays: suspendDays,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/suspendTempUser/${userId}`,
        requestBody,
        config
      );
      setIsLoading(false);
      toast.success("User Suspended");
      setShowModal(false);
    } catch (error) {
      // Handle errors here
      console.error("Error Suspending user account:", error);
      // You may want to set an error state or show a notification to the user
    }
  };

  //suspend Take Action For Suspend account
  const [showTakeActionSuspend, setTakeActionSuspend] = useState(false);
  const handleTakeActionForSuspendClicked = () => {
    setShowSuspend(false);
    setShowDelete(false);
    setShowDisable(false);
    setShowEnable(false);
    setTakeActionSuspend(true);
  };

  const handleTakeActionForSuspendAccount = async () => {
    const userId = userSelectedData.id;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/suspendTempUser/${userId}`,
        {},
        config
      );
      setIsLoading(false);
      toast.success("User Suspend");
      setShowModal(false);
    } catch (error) {
      // Handle errors here
      console.error("Error Suspending user account:", error);
      // You may want to set an error state or show a notification to the user
    }
  };

  //Disable account
  const [showDisable, setShowDisable] = useState(false);
  const handleDisableClicked = async () => {
    setTakeActionSuspend(false);
    setShowSuspend(false);
    setShowEnable(false);
    setShowDelete(false);
    setShowDisable(true);
  };

  const handleDisableAccount = async () => {
    const userId = userSelectedData.id;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/disableUser/${userId}`,
        {},
        config
      );
      setIsLoading(false);
      toast.success("User Disabled");

      setUserData((prevData) =>
        prevData.map((user) =>
          user.id === userId ? { ...user, profileStatus: "Disabled" } : user
        )
      );

      setShowModal(false);
    } catch (error) {
      // Handle errors here
      console.error("Error Disabling user account:", error);
      // You may want to set an error state or show a notification to the user
    }
  };

  //Enable account
  const [showEnable, setShowEnable] = useState(false);
  const handleEnableClicked = async () => {
    setShowSuspend(false);
    setShowDelete(false);
    setShowDisable(false);
    setShowEnable(true);
  };
  const handleEnableAccount = async () => {
    const userId = userSelectedData.id;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/admin/enableUser/${userId}`,
        {},
        config
      );
      setIsLoading(false);
      toast.success("User Enabled");
      setUserData((prevData) =>
        prevData.map((user) =>
          user.id === userId ? { ...user, profileStatus: "normal" } : user
        )
      );

      setShowModal(false);
    } catch (error) {
      // Handle errors here
      console.error("Error Disabling user account:", error);
      // You may want to set an error state or show a notification to the user
    }
  };

  //cancel click
  const handleCancel = () => {
    setTakeActionSuspend(false);
    setShowModal(false);
    setShowDelete(false);
    setShowSuspend(false);
    setShowDisable(false);
    setShowEnable(false);
  };

  return (
    <div className={`MainBody overflow-auto min-h-screen px-2 ${setFont} `}>
      <Helmet>
        <title>User Manage - Admin Panel</title>
      </Helmet>
      <div className="md:flex md:my-5 my-1">
        <AdminNavBar activePage="UserManage" />
        <div className="w-full">
          <div>
            <p className="font-semibold text-3xl text-gray-500 mb-5">
              User Management
            </p>
          </div>

          {isLoading ? (
            <div className=" w-[90%] flex justify-center items-center">
              <div className=" space-y-3  px-2 w-full">
                {renderListLoadingPage()}
              </div>
            </div>
          ) : (
            <div className="flex space-x-10 ">
              <div className="    ">
                <p>All user ({userData.length})</p>
                {userData.length === 0 ? (
                  <p>No user available</p>
                ) : (
                  <div>
                    {userData.map((item) => (
                      <div key={item.id} className="flex w-full">
                        <div
                          onClick={() => handleOpenDetail(item.id)} // Add onClick event
                          // onClick={() => handleOpenDetail(item)}
                          className={`my-1 ${
                            item.profileStatus === "Suspended"
                              ? "bg-red-200"
                              : item.profileStatus === "Disabled"
                              ? "bg-red-500"
                              : null
                          } bg-green-100 w-full  p-1 border border-green-500 rounded-xl lg:h-[80px] shadow-md hover:shadow-lg  duration-200 flex justify-between items-center`}
                        >
                          <div className="flex w-full items-center space-x-3">
                            <div className="">
                              {item.proPic ? (
                                <img
                                  className="h-16 w-16"
                                  src={item.proPic}
                                  alt="user"
                                />
                              ) : (
                                <img
                                  className="h-16 w-16"
                                  src={icn_user}
                                  alt="default user icon"
                                />
                              )}
                            </div>

                            <div>
                              <p className="font-bold lg:text-base text-xs">
                                {item.name}
                              </p>
                              <p className="text-xs">number : {item.number}</p>
                            </div>
                          </div>
                          <button
                            onClick={(event) => handleUserAction(event, item)}
                            className="  lg:h-8 lg:w-20  rounded-lg bg-green-400 cursor-pointer lg:text-base text-sm p-1"
                          >
                            Action
                          </button>
                        </div>

                        {showModal && (
                          <div className="fixed inset-0 flex items-center z-50 justify-center bg-[#00000021] ">
                            <div className="bg-white  p-3 rounded-2xl  relative">
                              <button
                                className="px-4 py-2 absolute right-0  bg-red-300 rounded-l-md"
                                onClick={handleCancel}
                              >
                                Cancel
                              </button>

                              <div className=" h-full ">
                                <h3 className="lg:text-2xl text-lg  mb-4 text-green-500 font-bold">
                                  Take user action
                                </h3>

                                <div className="flex justify-center items-center">
                                  <div className=" ">
                                    <div className="flex justify-center">
                                      {userSelectedData.proPic ? (
                                        <img
                                          className="h-16 w-16"
                                          src={userSelectedData.proPic}
                                          alt="user"
                                        />
                                      ) : (
                                        <img
                                          className="h-16 w-16"
                                          src={icn_user}
                                          alt="default user icon"
                                        />
                                      )}
                                    </div>

                                    <div className=" text-center">
                                      <p
                                        className="text-bold lg:text-xl cursor-pointer underline text-blue-600"
                                        onClick={() =>
                                          handleOpenDetail(userSelectedData.id)
                                        }
                                      >
                                        {" "}
                                        {userSelectedData.name}{" "}
                                      </p>
                                      <p>user id: {userSelectedData.id} </p>
                                      <p>Phone: {userSelectedData.number} </p>
                                      <p className="inline">Profile Status: </p>
                                      <p
                                        className={`inline uppercase font-bold ${
                                          item.profileStatus === "Suspended"
                                            ? "text-red-700 bg-red-200 p-1 rounded-md"
                                            : item.profileStatus === "Disabled"
                                            ? "text-white bg-red-500 p-1 rounded-md"
                                            : "text-green-700"
                                        }`}
                                      >
                                        {userSelectedData.profileStatus}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="lg:mt-10 mt-5 flex justify-center items-center space-x-2 ">
                                  {" "}
                                  {userSelectedData.profileStatus ===
                                  "Suspended" ? (
                                    <button
                                      className="px-4 py-2  bg-green-300 rounded-md"
                                      onClick={
                                        handleTakeActionForSuspendClicked
                                      }
                                    >
                                      Take Suspended Action
                                    </button>
                                  ) : (
                                    <button
                                      className="lg:px-4 px-1 lg:py-2 p-1  bg-red-300 rounded-md"
                                      onClick={handleSuspendClicked}
                                    >
                                      Suspend
                                    </button>
                                  )}
                                  {userSelectedData.profileStatus ===
                                  "Disabled" ? (
                                    <button
                                      className="lg:px-4 px-1 lg:py-2 p-1   bg-green-400 rounded-md"
                                      onClick={handleEnableClicked}
                                    >
                                      Enable Account
                                    </button>
                                  ) : (
                                    <button
                                      className=" lg:px-4 lg:py-2 p-1  bg-red-400 rounded-md"
                                      onClick={handleDisableClicked}
                                    >
                                      Disable Account
                                    </button>
                                  )}
                                  <button
                                    className=" lg:px-4 lg:py-2 p-1 bg-red-500  rounded-md"
                                    onClick={handleDeleteClicked}
                                  >
                                    {" "}
                                    Delete Account
                                  </button>
                                </div>

                                <div className=" ">
                                  {showDisable ? (
                                    <div className=" lg:mx-20  lg:mt-10 mt-5 lg:py-5 p-2 border bg-red-100 rounded-xl text-center items-center">
                                      <div className="  font-bold ">
                                        <p className="lg:text-base text-sm">
                                          {`Do you really want to Disable `}
                                          <span className="text-red-500">
                                            {userSelectedData.name}'s
                                          </span>
                                          {" account?"}
                                        </p>

                                        <p className="lg:text-base text-sm">
                                          <span className="text-red-500">
                                            {userSelectedData.name}
                                          </span>{" "}
                                          can not login in this site until you
                                          active this account.
                                        </p>

                                        <button
                                          className=" p-2 lg:mt-10 mt-3  bg-red-500 text-white rounded-md"
                                          onClick={handleDisableAccount}
                                        >
                                          Disable Account
                                        </button>
                                      </div>
                                    </div>
                                  ) : null}

                                  {showEnable ? (
                                    <div className=" lg:mx-20  lg:mt-10 mt-5 lg:py-5 p-2  border bg-green-100 rounded-xl text-center items-center">
                                      <div className="  font-bold ">
                                        <p className="lg:text-base text-sm">
                                          {`Do you really want to Enable `}
                                          <span className="text-red-500">
                                            {userSelectedData.name}'s
                                          </span>
                                          {" account?"}
                                        </p>

                                        <p className="lg:text-base text-sm">
                                          <span className="text-red-500">
                                            {userSelectedData.name}
                                          </span>{" "}
                                          can login in this site again and its
                                          will be normal
                                        </p>
                                      </div>

                                      <button
                                        className=" px-4 lg:mt-10 mt-5 py-2 bg-green-500 text-white rounded-md"
                                        onClick={handleEnableAccount}
                                      >
                                        Enable Account
                                      </button>
                                    </div>
                                  ) : null}

                                  {showDelete ? (
                                    <div className="lg:mx-20  lg:mt-10 mt-2 lg:py-5 p-2  border bg-red-100 rounded-xl text-center items-center">
                                      <div className="  font-bold ">
                                        <p className="lg:text-base text-sm">
                                          {`Do you really want to delete `}
                                          <span className="text-red-500">
                                            {userSelectedData.name}'s
                                          </span>
                                          {" account?"}
                                        </p>

                                        <p className="lg:text-base text-sm">
                                          Its also delete{" "}
                                          <span className="text-red-500">
                                            {userSelectedData.name}'s
                                          </span>{" "}
                                          all posts, comments and other
                                          activities.
                                        </p>
                                      </div>

                                      <button
                                        className=" p-2 lg:mt-10 mt-3 bg-red-500 text-white rounded-md"
                                        onClick={handleDeleteAccount}
                                      >
                                        Delete Account
                                      </button>
                                    </div>
                                  ) : null}

                                  {showSuspend ? (
                                    <div className=" lg:mx-20  lg:mt-10 mt-5 lg:py-5 p-2  border bg-red-100 rounded-xl text-center items-center">
                                      <div className="  font-bold ">
                                        <p className="lg:text-base text-sm">
                                          {`Do you really want to Suspend `}
                                          <span className="text-red-500">
                                            {userSelectedData.name}'s
                                          </span>
                                          {" account?"}
                                        </p>

                                        <p className="lg:text-base text-sm">
                                          {userSelectedData.name} can not make
                                          any sell/rent post in this site.
                                        </p>
                                      </div>

                                      <div className=" mt-3 flex justify-center items-center ">
                                        <div className="">
                                          <label className=" text-left block text-sm font-medium text-gray-600">
                                            Suspend by Days
                                          </label>

                                          <div className="flex items-center space-x-3 ">
                                            <select
                                              onChange={(e) =>
                                                setSuspendDays(e.target.value)
                                              }
                                              id="SuspendTime"
                                              name="SuspendTime"
                                              className="p-2 border rounded-md"
                                            >
                                              <option value="3">3 days</option>
                                              <option value="7">7 Days</option>
                                              <option value="15">
                                                15 Days
                                              </option>
                                              <option value="30">
                                                30 Days
                                              </option>
                                              <option value="60">
                                                60 Days
                                              </option>
                                              <option value="90">
                                                90 Days
                                              </option>
                                              <option value="Suspended">
                                                Life Time
                                              </option>
                                            </select>

                                            <button
                                              className="  h-8 px-2  bg-red-500 text-white rounded-md"
                                              onClick={handleSuspendAccount}
                                            >
                                              Suspend
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}

                                  {showTakeActionSuspend ? (
                                    <div className=" lg:mx-20  lg:mt-10 mt-5 lg:py-5 p-2  border bg-red-100 rounded-xl text-center items-center">
                                      <div className="  font-bold ">
                                        <p>
                                          {`Take action for `}
                                          <span className="text-red-500">
                                            {userSelectedData.name}.
                                          </span>
                                        </p>

                                        <p>
                                          <span className="text-red-500">
                                            {userSelectedData.name}
                                          </span>{" "}
                                          can not make any sell/rent post in
                                          this site until you undo suspend.
                                        </p>
                                      </div>

                                      <div className=" lg:h-[150px]  py-5 lg:flex  lg:space-x-7 space-x-3 justify-center items-center ">

                                        <div className=" flex flex-col  items-center">
                                          <label
                                            for="SuspendTime"
                                            className=" text-left block text-sm font-medium text-gray-600"
                                          >
                                            Suspend by Days
                                          </label>

                                          <div className="flex items-center space-x-3 ">
                                            <select
                                              onChange={(e) =>
                                                setSuspendDays(e.target.value)
                                              }
                                              id="SuspendTime"
                                              name="SuspendTime"
                                              className=" p-2  border rounded-md"
                                            >
                                              <option value="3">3 days</option>
                                              <option value="7">7 Days</option>
                                              <option value="15">
                                                15 Days
                                              </option>
                                              <option value="30">
                                                30 Days
                                              </option>
                                              <option value="60">
                                                60 Days
                                              </option>
                                              <option value="90">
                                                90 Days
                                              </option>
                                              <option value="Suspended">
                                                Life Time
                                              </option>
                                            </select>

                                            <button
                                              className=" h-8 px-2  bg-red-500 text-white rounded-md"
                                              onClick={handleSuspendAccount}
                                            >
                                              Suspend
                                            </button>
                                          </div>
                                        </div>

                                        <div className="border-l h-3/4  border-gray-500"></div>

                                        <button
                                          className=" h-8 px-2  mt-5 lg:mt-0 bg-green-500 text-white rounded-md"
                                          onClick={handleEnableAccount}
                                        >
                                          Undo Suspend
                                        </button>


                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserManagement;
