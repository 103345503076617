import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../context/UserContext";
import { toast } from "react-hot-toast";
import { CgSpinner } from "react-icons/cg";
import locationData from "../../../Location.json";
import { Helmet } from "react-helmet";
//icons

import CardLoading from "../../../component/HorizontalCardLoading";
import logo from "../../../img/icn/Logo.png";
import search from "../../../img/icn/search.png";
import save from "../../../img/icn/love.png";
import unsave from "../../../img/icn/unsave.png";
import locations from "../../../img/icn/location.png";
import camera from "../../../img/icn/camera.png";
import bed from "../../../img/icn/bed.png";
import bath from "../../../img/icn/bath.png";
import area from "../../../img/icn/area.png";
import garage from "../../../img/icn/garage.png";
import conditions from "../../../img/icn/conditions.png";
import cancel from "../../../img/icn/cancel.png";

import axios from "axios";

const GroupWiseBuilding = () => {
  const renderListLoadingPage = () => {
    return <CardLoading />;
  };

  //search
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [searchLoading, setSearchLoading] = useState(false);

  //const [sellType, setSellType] = useState("Buy");
  const [divisionId, setDivisionId] = useState("");
  const [district, setDistrict] = useState([]);
  const [districtId, setDistrictId] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = localStorage.getItem("userToken");

  //language
  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  ///Setting font style
  const font = localStorage.getItem("lng");
  let setFont;
  if (font == "bn") {
    setFont = "font-TiroBangla";
  } else if (font == "en") {
    setFont = "font-Poppins-Regular";
  }

  //get current user data
  const userToken = localStorage.getItem("userToken");

  //get user saved post
  const { user } = useUser();

  const [savedPosts, setSavedPosts] = useState([]);

  const userID = user?.id;
  const status = user?.status;

  //get user SavePost
  useEffect(() => {
    // Check if user exists before making the Axios request
    if (user) {
      const handleFetchSavedPosts = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/profile/getSavePost`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`, // Ensure userToken is defined
              },
            }
          );
          setSavedPosts(response.data.SavedPosts);
        } catch (error) {
          console.error("Error making Axios request:", error);
        }
      };

      handleFetchSavedPosts();
    }
  }, [user]); // Run when the user changes

  const queryParams = new URLSearchParams(location.search);
  const currentPageQueryParam = queryParams.get("page");
  const initialPage = currentPageQueryParam
    ? parseInt(currentPageQueryParam)
    : 1;

  const [currentPage, setCurrentPage] = useState(initialPage);
  const postPerPage = 8;
  const [loading, setLoading] = useState(true);
  const [dataFromAPI, setDataFromAPI] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  // Function to fetch data based on page and limit
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/group_building_card?page=${currentPage}&limit=${postPerPage}`
      );
      setDataFromAPI(response.data.items);
      setTotalPages(Math.ceil(response.data.totalItems / postPerPage));
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const updateURL = (page) => {
    const newParams = new URLSearchParams(queryParams);
    newParams.set("page", page);
    window.history.replaceState(
      null,
      null,
      `${location.pathname}?${newParams.toString()}`
    );
  };

  //search engine
  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/propertyGroupSearch?searchText=${searchText}&division=${divisionId}&district=${districtId}`
        );
        setSuggestions(response.data.items);
      } catch (error) {
        console.error(error);
      }
    };

    // Only fetch suggestions if there is text in the input
    if (searchText.trim() !== "") {
      fetchSuggestions();
    } else {
      setSuggestions([]); // Clear suggestions if the input is empty
    }
  }, [searchText]);

  const handleSearch = async () => {
    setSearchLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/propertyGroupSearch?searchText=${searchText}&division=${divisionId}&district=${districtId}`
      );
      setSearchResults(response.data.items);
      setTotalItems(response.data.totalItems);
      if (response.data.totalItems === 0) {
        toast.error("No result found");
      }
      setSearchLoading(false);
      setSuggestions([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClear = () => {
    setSearchText("");
    setDistrictId("");
    setDistrict([]);
    setSearchResults([]);
    setSuggestions([]);
  };

  //search engine
  const handleDivision = (e) => {
    const getDivisionId = e.target.value;
    // Check if "Select Division" is selected
    if (getDivisionId === "") {
      // Reset the district state
      setDistrict([]);
    } else {
      // Find the selected division and set the district data
      const getDistrictData = locationData.find(
        (division) => division.division_id === getDivisionId
      ).district;
      setDistrict(getDistrictData);
    }
    // Set the divisionId
    setDivisionId(getDivisionId);
  };

  // Modify the handleDistrict function to set sub-districts
  const handleDistrict = (e) => {
    const districtId = e.target.value;
    setDistrictId(districtId);
  };

  // Function to handle opening detail view
  const handleOpenDetail = (item) => {
    navigate(`/Group-Wise-Building-Details/${item._id}`);
  };

  //Save unsave Optimistic UI Update
  const handleSaveUnSavePost = async (event, postId) => {
    event.stopPropagation();

    if (!currentUser) {
      localStorage.setItem("redirectUrl", location.pathname);
      toast.error("You have to login first");
      navigate("/SignIn");
    } else {
      const userID = user.id;
      const PostID = postId;
      const isPostSaved = savedPosts.some(
        (savedPost) => savedPost._id === PostID
      );

      try {
        if (isPostSaved) {
          // Optimistically update the UI
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
          toast.success("Post Unsaved");

          // Make the network request in the background
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/profile/deleteUserSavedPost/${PostID}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
        } else {
          // Optimistically update the UI
          const newSavedPost = { _id: PostID }; // Create a minimal representation of the saved post
          setSavedPosts((prevPosts) => [...prevPosts, newSavedPost]);
          toast.success("Post Saved");

          // Make the network request in the background
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/profile/setSavePost`,
            { userID, PostID },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

          // Update the UI with the full post details from the server response
          const savedPost = response.data.SavedPosts.find(
            (post) => post._id === PostID
          );
          setSavedPosts((prevPosts) =>
            prevPosts.map((post) => (post._id === PostID ? savedPost : post))
          );
        }
      } catch (error) {
        // Revert the optimistic UI update on error
        if (isPostSaved) {
          setSavedPosts((prevPosts) => [...prevPosts, { _id: PostID }]);
        } else {
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
        }

        console.error("Error saving/unsaving post:", error);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.error;
          console.error("Server error message:", errorMessage);
          //toast.error(`Error: ${errorMessage}`);
        } else {
          //toast.error("Error saving/unsaving post");
        }
      }
    }
  };

  // send intersted location to backend
  const [shareDivision, setShareDivision] = useState("");
  const [shareLocation, setShareLocation] = useState("");
  const [shareNumber, setShareNumber] = useState("");
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const handleShareSubmit = async (e) => {
    e.preventDefault();
    setSpinnerLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/group_building_desired`,
        {
          division: shareDivision,
          location: shareLocation,
          number: shareNumber,
        }
      );
      setShareLocation("");
      setShareNumber("");
      setSpinnerLoading(false);
      const message = response.data.message;
      toast.success(message);
      console.log(response.data); // You can handle the response accordingly
    } catch (error) {
      const errorMessage = error.response.data.error;
      setShareLocation("");
      setShareNumber("");
      setSpinnerLoading(false);
      toast.error(errorMessage);
      console.error("Error submitting data:", error);
    }
  };

  function getRelativeTime(postDate) {
    const post = new Date(postDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now - post) / 1000);

    if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    }
  }

  return (
    <div className={`MainSection  ${setFont} min-h-screen w-full flex justify-center`}>
      <Helmet>
        <title>Group Wise Property - Loko Property</title>
      </Helmet>
      <div className="w-[95%] md:w-[90%] xl:w-[85%] 2xl:w-[75%]">
        <div className="mt-4 xl:mt-5 2xl:mt-14">
          <div>
            <p className=" text-3xl xl:text-4xl 2xl:text-5xl  font-bold text-gray-500">
              Group Wise Building
            </p>
          </div>

          <div className="mt-2">
            <p className=" xl:text-xl 2xl:text-2xl font-bold text-gray-400">
              LET'S BUILD SOMETHING TOGETHER
            </p>
          </div>

          <div className="mt-2">
            <p className="text-sm text-justify  2xl:text-sm text-gray-600">
              Our Vision is Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen book. It
              has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages
            </p>
          </div>
        </div>

        {/* search section*/}

        <div className=" my-3 py-2 lg:py-3 w-[98%] md:w-[95%] lg:w-full px-2 md:px-10 lg:px-5  md:items-center bg-[#ffffff]  rounded-xl  duration-200 ">
          <div className=" flex justify-center  ">
            <div className="  ">
              <p className="lg:ml-4 italic text-gray-400 text-sm pb-1">
                search whatever you looking for....
              </p>

              <div className="flex flex-wrap items-center">
                {/* Location*/}
                <div className=" mb-2 ">
                  <div className="flex ">
                    <div className=" 2xl:h-10 h-8 w-[130px] mr-2">
                      <select
                        name="Division"
                        required
                        className="2xl:h-10 h-8 text-xs 2xl:text-sm w-full bg-gray-50 border  px-1 border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 focus:border-green-500 block  dark:bg-white dark:border-green-500 dark:placeholder-gray-400 dark:text-black dark:focus:ring-green-500 dark:focus:border-green-500"
                        onChange={(e) => handleDivision(e)}
                      >
                        <option value="" className="text-pink-600">
                          Select Division
                        </option>
                        {locationData.map((getDivision, index) => (
                          <option value={getDivision.division_id} key={index}>
                            {getDivision.division_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {district.length > 0 && (
                      <div className=" 2xl:h-10 h-8 mr-2 ">
                        <select
                          name="states"
                          className="2xl:h-10 h-8 text-xs 2xl:text-sm w-full bg-gray-50 border px-1 border-gray-300 text-gray-900 rounded-lg focus:ring-green-500 focus:border-green-500 block  dark:bg-white dark:border-green-500 dark:placeholder-gray-400 dark:text-black dark:focus:ring-green-500 dark:focus:border-green-500"
                          onChange={(e) => handleDistrict(e)}
                        >
                          <option value="" className="text-pink-600">
                            Select District
                          </option>
                          {district.map((getDistrict, index) => (
                            <option value={getDistrict.district_id} key={index}>
                              {getDistrict.district_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>

                <div className="md:mt-0 mb-2 ml-0  items-center flex duration-200">
                  <input
                    type="text"
                    className="2xl:h-10 text-xs 2xl:text-sm h-8 min-w-[210px]  border border-green-500  rounded-lg p-2 duration-200"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Title, Division, District, (optional)"
                  />

                  <button
                    onClick={handleSearch}
                    className="2xl:h-10 h-8 sm:px-3  sm:bg-green-200 sm:border sm:border-green-500 rounded-xl sm:ml-3 ml-1 space-x-2 flex justify-center items-center duration-200"
                  >
                    {searchLoading && (
                      <CgSpinner
                        size={20}
                        className=" animate-spin duration-200"
                      />
                    )}
                    <span className="hidden sm:inline text-xs 2xl:text-sm duration-200">
                      Search
                    </span>

                    <img
                      src={search}
                      alt="search"
                      className="inline sm:hidden w-6 h-6"
                    />
                  </button>

                  {(searchText.trim() !== "" ||
                    suggestions.length > 0 ||
                    searchResults.length > 0) && (
                    <div
                      onClick={handleClear}
                      className=" w-6 md:w-6 xl:h-7 xl:w-7 2xl:h-9 2xl:w-9 flex items-center cursor-pointer ml-3 duration-200 "
                    >
                      <img
                        className="h-6 md:w-6 xl:h-7 xl:w-7 2xl:h-9 2xl:w-9 "
                        src={cancel}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Display loading indicator */}
        {loading ? (
          <div className=" w-full flex justify-center  items-center ">
            <div className=" px-2 w-full">{renderListLoadingPage()}</div>
          </div>
        ) : dataFromAPI.length === 0 ? (
          <div className="h-[50vh] w-full flex text-center justify-center items-center duration-200">
            <p className="text-xl xl:text-3xl 2xl:text-5xl  font-bold text-gray-400">
              No post available
            </p>
          </div>
        ) : (
          <div className=" md:flex">
            <div className="md:w-[70%] w-[98%]">
              {suggestions && suggestions.length > 0 ? (
                <div className=" duration-200">
                  <p className=" text-center my-2 text-yellow-700 bg-yellow-100">
                    Matching with....
                  </p>

                  <div className=" items-center 2xl:mt-5 mt-2 duration-200">
                    {suggestions.map((item) => (
                      <PropertyCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                        handleSaveUnSavePost={handleSaveUnSavePost}
                        savedPosts={savedPosts}
                        getRelativeTime={getRelativeTime}
                      />
                    ))}
                  </div>
                </div>
              ) : searchResults && searchResults.length > 0 ? (
                <div className=" duration-200">
                  <p className=" text-center 2xl:mt-5 mt-2 text-green-700 cursor-pointer bg-green-200">
                    {totalItems} result found
                  </p>

                  <div className=" items-center mt-5 duration-200">
                    {searchResults.map((item) => (
                      <PropertyCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                        handleSaveUnSavePost={handleSaveUnSavePost}
                        savedPosts={savedPosts}
                        getRelativeTime={getRelativeTime}
                      />
                    ))}
                  </div>
                </div>
              ) : dataFromAPI.length > 0 ? (
                <div className="  items-center 2xl:mt-5 mt-2 duration-200">
                  {/* main post */}
                  <div className="space-y-8 ">
                    {dataFromAPI.map((item) => (
                      <PropertyCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                        handleSaveUnSavePost={handleSaveUnSavePost}
                        savedPosts={savedPosts}
                        getRelativeTime={getRelativeTime}
                      />
                    ))}
                  </div>
                  {/* Pagination buttons */}
                  <div className="pagination text-center mt-5 h-10">
                    {totalPages > 1 && (
                      <div className="pagination text-center mt-5 h-10">
                        {currentPage > 1 && (
                          <button
                            className="pagination-button h-8 w-24 mx-1 mr-3 rounded-md border border-green-600 duration-200"
                            onClick={() => {
                              if (currentPage > 1) {
                                setCurrentPage(currentPage - 1);
                                updateURL(currentPage - 1);
                              }
                            }}
                          >
                            {"« Previous"}
                          </button>
                        )}

                        {Array.from({ length: totalPages }).map((_, index) => (
                          <button
                            key={index}
                            className={`pagination-button h-8 w-7 mx-1 rounded-md border border-green-600 duration-200 ${
                              currentPage === index + 1
                                ? "active bg-green-400"
                                : ""
                            }`}
                            onClick={() => {
                              setCurrentPage(index + 1);
                              updateURL(index + 1);
                            }}
                          >
                            {index + 1}
                          </button>
                        ))}

                        {currentPage < totalPages && (
                          <button
                            className="pagination-button h-8 ml-3 w-24 mx-1 rounded-md border border-green-600 duration-200"
                            onClick={() => {
                              if (currentPage < totalPages) {
                                setCurrentPage(currentPage + 1);
                                updateURL(currentPage + 1);
                              }
                            }}
                          >
                            {"Next »"}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>

            <div className="md:w-[30%] md:mt-5 flex justify-center md:ml-3">
              <div className="md:ml-3 ">
                <div className=" p-3 shadow-md text-center">
                  <h2 className="text-xl 2xl:text-2xl font-bold text-gray-600">
                    Let us know your interest
                  </h2>
                  <div
                    className={` w-full flex justify-center  items-center duration-200 `}
                  >
                    <form
                      className=" p-5 rounded-2xl"
                      onSubmit={handleShareSubmit}
                    >
                      {/* Division DropDown*/}
                      <div className=" flex justify-center ">
                        <div className=" ">
                          <p className="text-sm 2xl:text-md  text-gray-500 font-bold">
                            In which location you would like to have your share?
                          </p>

                          <div className="mt-5 inline-block">
                            <div className="relative h-10  w-[250px]">
                              <select
                                required
                                className="peer h-full w-full rounded-[7px] border-2 border-blue-gray-600 border-t-transparent bg-transparent px-3 text-[15px] text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-red-500 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                                onChange={(e) =>
                                  setShareDivision(e.target.value)
                                }
                              >
                                <option value="">Choose</option>
                                <option value="ঢাকা">ঢাকা</option>
                                <option value="রাজশাহী">রাজশাহী</option>
                                <option value="চট্রোগ্রাম">চট্রোগ্রাম</option>
                                <option value="খুলনা">খুলনা</option>
                                <option value="বরিশাল">বরিশাল</option>
                                <option value="সিলেট">সিলেট</option>
                                <option value="রংপুর">রংপুর</option>
                                <option value="ময়মনসিংহ">ময়মনসিংহ</option>
                              </select>
                              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[12px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Select Division
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-10">
                        <p className="text-sm 2xl:text-md text-gray-500 font-bold">
                          Enter Specific Location of / division name
                        </p>
                        <input
                          type="text"
                          required
                          value={shareLocation}
                          placeholder="e.g Boalia, Rajshahi"
                          onChange={(e) => setShareLocation(e.target.value)}
                          className="border-2 mt-3 h-8 w-[250px] rounded-lg border-gray-300 px-1 pl-2"
                        ></input>
                      </div>

                      <div className="mt-10  ">
                        <p className="text-sm 2xl:text-md text-gray-500 font-bold">
                          Enter your phone number
                        </p>
                        <input
                          required
                          className="border-2 mt-3 h-8  w-[250px] rounded-lg border-gray-300 px-1 pl-2"
                          placeholder="01xxxxxxxx"
                          type="number"
                          pattern="\d*"
                          onKeyPress={(e) => {
                            if (e.charCode < 48 || e.charCode > 57) {
                              e.preventDefault();
                            }
                          }}
                          value={shareNumber}
                          onChange={(e) => setShareNumber(e.target.value)}
                        ></input>
                      </div>

                      <div className="  flex justify-center  space-x-4 mt-5">
                        <button
                          type="submit"
                          className="w-[100px]  flex space-x-3  items-center justify-center py-2 rounded text-gray-800 bg-slate-400 font-bold hover:text-white hover:bg-slate-500 duration-200"
                        >
                          {spinnerLoading && (
                            <CgSpinner
                              size={20}
                              className="mt-1 animate-spin"
                            />
                          )}
                          <span className="text-md">Submit</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const PropertyCard = ({
  item,
  handleOpenDetail,
  handleSaveUnSavePost,
  savedPosts,
  getRelativeTime,
}) => (
  <div
    onClick={() => handleOpenDetail(item)}
    className="md:flex cursor-pointer md:h-[270px] 2xl:h-[350px] m-3 md:mx-0 mx-5 md:max-w-[100%] shadow-xl rounded-xl myHover overflow-hidden hover:translate-y-[-5px] duration-300"
  >
    <div className="relative overflow-hidden h-[220px] md:h-full md:w-1/2">
      <img
        src={item.imageUrls[0] || "default-image-url.jpg"}
        alt={item.imageUrls[0]}
        className="md:w-full md:h-full md:object-cover transition-transform imageHover duration-500"
      />
      <div className="absolute top-0 w-full h-[40px] bg-gradient-to-b from-[#000000c2] to-transparent duration-200">
        <div className="flex justify-between items-center">
          <div className="flex h-7 space-x-1 m-3">
            <img src={camera} alt="camera" className="h-5 w-5 mt-[2px]" />
            <h2 className="text-white">{item.imageUrls.length}</h2>
          </div>
          <div className="text-white px-2 py-1 bg-red-600 text-xs mr-2 rounded-lg">
            <p className="capitalize">{item.type}</p>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 w-full bg-gradient-to-b from-transparent to-[#000000e8]">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-1 mb-2 ml-2">
            <img
              src={item.userData.proPic || logo}
              alt="User Profile"
              className="h-10 w-10 rounded-full"
            />
            {/* <p className="text-white p-2 text-sm">{item.userData.username || "Loko Property"}</p> */}
            <p className="text-white p-2 text-sm">
              {/* {item.userData.username} */}
              {"Loko Property"}
            </p>
          </div>
          <button onClick={(event) => handleSaveUnSavePost(event, item._id)}>
            <div className="h-10 w-10 px-2 py-1 cursor-pointer rounded-lg hover:scale-105 duration-200">
              <img
                src={
                  savedPosts.some((savedPost) => savedPost._id === item._id)
                    ? save
                    : unsave
                }
                className="h-6 w-6 duration-200"
                alt="Save Icon"
              />
            </div>
          </button>
        </div>
      </div>
    </div>

    <div className="md:w-1/2 md:p-4 p-2 bg-white relative flex flex-col h-full">
      <div className="2xl:text-2xl md:text-xl text-xl text-[#666666] font-bold max-w-[100%] line-clamp-1">
        {item.postTitle}
      </div>
      <div className="flex justify-between bg-gradient-to-l px-2 my-1 mt-2 from-transparent to-[#40a5213f]">
        <p className="text-green-900 text-sm">
          {"Condition : "}
          {item.condition}
        </p>
      </div>
      <div className="mt-4">
        <div className="flex items-center">
          <img src={locations} className="h-4 w-4" alt="Location" />
          <p className="ml-2 lg:text-base text-[#666666] text-xs">
            {item.subDistrict}, {item.district}, {item.division}
          </p>
        </div>
        <div className="border-b-2 border-gray-300 mt-2"></div>
      </div>

      <div className="mt-2">
        <p className="text-xs font-semibold text-[#666666] capitalize">
          Type : {item.type}
        </p>
      </div>

      <div className="flex 2xl:space-x-12 lg:space-x-6 space-x-4  mt-4 text-[#666666]">
        <PropertyFeature icon={bed} title="Bedrooms" value={item.bedroom} />
        <PropertyFeature icon={bath} title="Bathrooms" value={item.bathroom} />
        <PropertyFeature icon={area} title="Area" value={`${item.size} ft²`} />
        <PropertyFeature icon={garage} title="Garage" value={item.garages} />
      </div>
      <div className="mt-4 sm:mt-auto">
        <div className="flex items-center justify-between">
          <div className="flex justify-start items-center space-x-5">
            <p className="md:text-2xl text-xl md:font-semibold text-gray-500">
              BDT :{" "}
              <span className="md:text-2xl text-xl md:font-semibold text-[#1a9649]">
                {item.price} Tk
              </span>
            </p>
            {item.exPrice && (
              <span className="ml-2 text-[#808080b2] line-through font-semibold">
                Tk {item.exPrice}
              </span>
            )}
          </div>
          <p className="md:text-sm text-green-900 text-xs">
            {getRelativeTime(item.postDate)}
          </p>
        </div>
      </div>
    </div>
  </div>
);

const PropertyFeature = ({ icon, title, value }) => (
  <div className="text-center md:font-semibold w-24 flex flex-col items-center text-xs duration-200">
    <div>
      <p>{title}</p>
    </div>
    <div className="flex space-x-2 mt-1">
      <img src={icon} alt={title} className="h-5 w-5" />
      <p className="text-center font-bold m-auto">{value}</p>
    </div>
  </div>
);

export default GroupWiseBuilding;
