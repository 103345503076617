import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { FaUser, FaClipboard, FaHeart, FaSignOutAlt } from "react-icons/fa";

// Import your components
import UserInfo from "./Profile";
import PostInfo from "../user/UserPostInfo";
import SavedPost from "./UserSavedPosts";

const UserDashboard = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [activeItem, setActiveItem] = useState("UserInfo");

  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const LogOutHandler = () => {
    localStorage.removeItem("userToken");

    delete axios.defaults.headers.common["Authorization"];
    window.location.reload();
    navigate("/SignIn");
  };

  const menuItems = [
    { id: "UserInfo", label: "Profile", icon: FaUser },
    { id: "PostInfo", label: "Your Posts", icon: FaClipboard },
    { id: "SavedPost", label: "Saved Posts", icon: FaHeart },
  ];

  const renderPage = () => {
    switch (activeItem) {
      case "UserInfo":
        return <UserInfo />;
      case "PostInfo":
        return <PostInfo />;
      case "SavedPost":
        return <SavedPost />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-gray-100 w-full flex justify-center ">
      <div className="2xl:w-[75%] xl:w-[80%] lg:w-[90%] w-[98%]">
        <div className="flex justify-center ">
          {/* Sidebar */}
          <div className="bg-white w-12 md:w-64  shadow-lg transition-all duration-300 ease-in-out">
            <div className="flex flex-col h-full">
              <div className="flex-grow">
                {menuItems.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => setActiveItem(item.id)}
                    className={`w-full flex items-center p-4 hover:bg-gray-100 transition-colors ${
                      activeItem === item.id ? "bg-gray-200" : ""
                    }`}
                  >
                    <item.icon className="w-6 h-6 text-gray-600" />
                    <span className="ml-4 hidden md:inline">
                      {t(item.label)}
                    </span>
                  </button>
                ))}
              </div>
              <button
                onClick={LogOutHandler}
                className="w-full flex items-center p-4 text-red-600 hover:bg-red-100 transition-colors"
              >
                <FaSignOutAlt className="w-6 h-6" />
                <span className="ml-4 hidden md:inline">{t("Logout")}</span>
              </button>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 lg:p-8 p-2">
            <div className="max-w-6xl mx-auto">{renderPage()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboard;
