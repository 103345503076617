import "./App.css";
import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Routing
import PrivateRoute from "./component/privateRoute/PrivateRoute";
import UserIsNotSuspendRoute from "./component/privateRoute/UserIsNotSuspendRoute";

import Navbar from "./component/Navbar";
import Footer from "./component/Footer";

//basic
import Home from "./pages/Home";
import BlogPosts from "./pages/BlogPosts/BlogPosts";
import BlogPostDetails from "./pages/BlogPosts/BlogPostDetails";
import AboutUs from "./pages/AboutUs";
import CustomerCare from "./pages/CustomerCare";
import DownloadApp from "./pages/DownloadApp";

//property pages
import PropertySell from "./pages/PropertyPages/PropertySell/PropertySell";
import PropertySellDetails from "./pages/PropertyPages/PropertySell/PropertySellDetails";
import GroupWiseBuilding from "./pages/PropertyPages/GroupWiseBuilding/GroupWiseBuilding";
import GroupWiseBuildingDetails from "./pages/PropertyPages/GroupWiseBuilding/GroupWiseBuildingDetails";

import PropertyRent from "./pages/PropertyPages/PropertyRent/PropertyRent";
import PropertyRentDetails from "./pages/PropertyPages/PropertyRent/PropertyRentDetails";

//inner pages
import SellPost from "./pages/PropertyPages/PropertySell/SellPost";
import RentPost from "./pages/PropertyPages/PropertyRent/RentPost";

//user
import SignIn from "./pages/user/SignIn";
import Register from "./pages/user/Register";
import ForgotPassword from "./pages/user/ForgotPassword";
import ResetPassword from "./pages/user/ResetPassword";
import PageNotFound from "./pages/PageNotFound";
import EditProfile from "./pages/user/EditProfile";
import EditRentPost from "./pages/user/EditRentPost";
import EditSellPost from "./pages/user/EditSellPost";
import UserDashboard from "./pages/user/UserDashboard";

//admin
import Admin from "./pages/admin/Login";
import Dashboard from "./pages/admin/DashBoard";
import UserManagement from "./pages/admin/UserManagement";
import AdminInfo from "./pages/admin/AdminInfo";
import GroupBuildingManage from "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/GroupBuildingManage";
import SetGroupBuildingPost from "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/SetGroupBuildingPost";

import Notification from "./pages/admin/Notifications";
import SellPostManagement from "./pages/admin/PropertyManagement/PropertySellManagement/SellPostManagement";
import RentPostManagement from "./pages/admin//PropertyManagement/PropertyRentManagement/RentPostManagement";
import NoticeBoard from "./pages/admin/NoticeBoard";

import { UserProvider } from "./context/UserContext";
import ScrollToTop from "./component/ScrollToTop";

import UserProfileDetails from "./pages/UserProfileDetails";
import GroupPostDetailsForAd from "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/GroupPostDetailsForAd";
import UpdateGroupBuildingPost from "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/UpdateGroupBuildingPost";
import BlogPost from "./pages/admin/BlogPost";
import InteriorManagement from "./pages/admin/PropertyManagement/InteriorManagement/InteriorManagement";
import InteriorAddPost from "./pages/admin/PropertyManagement/InteriorManagement/InteriorAddPost";

import UpdateInteriorPost from "./pages/admin/PropertyManagement/InteriorManagement/InteriorPostEdit";

import InteriorProperty from "./pages/PropertyPages/Interior/InteriorProperty";
import InteriorPropertyDetails from "./pages/PropertyPages/Interior/InteriorPropertyDetails";
import SiteSettings from "./pages/admin/SiteSettings";
import GroupBuildingDesiredRequest from "./pages/admin/PropertyManagement/GroupWiseBuildingManagement/GroupBuildingDesiredRequest";

function App() {
  return (
    <BrowserRouter>
      <div className=" flex flex-col  min-h-screen">
        <UserProvider>
          <div className="flex-1  ">
            <Navbar />
            <Toaster position="top-center" toastOptions={{ duration: 5000 }} />
            <ScrollToTop />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/CustomerCare" element={<CustomerCare />} />
              <Route path="/Blog" element={<BlogPosts />} />
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/DownloadApp" element={<DownloadApp />} />
              <Route path="/Property-Buy-Sell" element={<PropertySell />} />
              <Route path="/Property-Rent" element={<PropertyRent />} />

              <Route
                path="/Group-Wise-Building"
                element={<GroupWiseBuilding />}
              />

              <Route path="/Interior-Design" element={<InteriorProperty />} />
              <Route
                path="/Interior-Details/:id"
                element={<InteriorPropertyDetails />}
              />

              <Route
                path="/Profile"
                element={
                  <PrivateRoute>
                    <UserDashboard />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Edit-Rent-Post/:id"
                element={
                  <PrivateRoute>
                    <EditRentPost />
                  </PrivateRoute>
                }
              />

              <Route
                path="/Edit-Sell-Post/:id"
                element={
                  <PrivateRoute>
                    <EditSellPost />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profileedit"
                element={
                  <PrivateRoute>
                    <EditProfile />
                  </PrivateRoute>
                }
              />

              <Route
                path="/sellpost"
                element={
                  <PrivateRoute>
                    <UserIsNotSuspendRoute>
                      <SellPost />
                    </UserIsNotSuspendRoute>
                  </PrivateRoute>
                }
              />

              <Route
                path="/rentpost"
                element={
                  <PrivateRoute>
                    <UserIsNotSuspendRoute>
                      <RentPost />
                    </UserIsNotSuspendRoute>
                  </PrivateRoute>
                }
              />

              <Route path="/SignIn" element={<SignIn />} />

              <Route
                path="/Property-Buy-Sell-Details/:id"
                element={<PropertySellDetails />}
              />

              <Route
                path="/ProfileDetails/:id"
                element={<UserProfileDetails />}
              />

              <Route
                path="/Property-Rent-Details/:id"
                element={<PropertyRentDetails />}
              />

              <Route
                path="/Group-Wise-Building-Details/:id"
                element={<GroupWiseBuildingDetails />}
              />

              <Route
                path="/admin/Group-Building-Desired-Request"
                element={<GroupBuildingDesiredRequest />}
              />

              <Route
                path="/admin/Group-Wise-Building-Details-Ad/:id"
                element={<GroupPostDetailsForAd />}
              />

              <Route
                path="/BlogPostDetails/:id"
                element={<BlogPostDetails />}
              />

              <Route path="/Register" element={<Register />} />
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
              <Route
                path="/ResetPassword/:resetToken"
                element={<ResetPassword />}
              />
              <Route path="*" element={<PageNotFound />} />

              <Route path="/admin" element={<Admin />} />

              <Route path="/admin/dashboard" element={<Dashboard />} />

              <Route path="/admin/admininfo" element={<AdminInfo />} />
              <Route path="/admin/BlogPost" element={<BlogPost />} />

              <Route path="/admin/Notifications" element={<Notification />} />
              <Route path="/admin/interior" element={<InteriorManagement />} />
              <Route
                path="/admin/interiorAddPost"
                element={<InteriorAddPost />}
              />

              <Route
                path="/admin/EditInteriotPost/:id"
                element={<UpdateInteriorPost />}
              />

              <Route path="/admin/usermanage" element={<UserManagement />} />

              <Route
                path="/admin/sellpostmanage"
                element={<SellPostManagement />}
              />

              <Route
                path="/admin/rentpostmanage"
                element={<RentPostManagement />}
              />

              <Route
                path="/admin/Group-Building-Manage"
                element={<GroupBuildingManage />}
              />
              <Route
                path="/admin/Group-Building-Edit/:id"
                element={<UpdateGroupBuildingPost />}
              />

              <Route
                path="/admin/Set-Group-Building-Post"
                element={<SetGroupBuildingPost />}
              />

              <Route path="admin/noticeboard" element={<NoticeBoard />} />
              <Route path="admin/SiteSettings" element={<SiteSettings />} />
            </Routes>
          </div>
        </UserProvider>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
