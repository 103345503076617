import React from "react";

function PostLoading() {

  return (
    <div className=" space-y-3 px-2">
    <div className=" mt-10  p-4 w-full  duration-200">
      <div className="animate-pulse space-y-3 ">
        <div className="h-3 w-32 bg-slate-300 rounded col-span-2"></div>{" "}
        <div className="h-3 w-32 bg-slate-300 rounded col-span-2"></div>{" "}
        <div className="rounded-full bg-slate-400 h-10 w-10"></div>
      </div>

      <div className="animate-pulse  mt-10">
        <div className="h-[200px] w-4/6 bg-slate-300 rounded col-span-2"></div>{" "}
      </div>

      <div className="animate-pulse mt-5 flex justify-between  ">
        <div className="h-2 bg-slate-400 rounded col-span-2"></div>
        <div className="flex-1  space-y-6 ">
          <div className="h-2 bg-slate-400 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-slate-400 rounded col-span-2"></div>
              <div className="h-2 bg-slate-400 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-slate-400 rounded"></div>
          </div>
        </div>
        <div className="flex-1 space-y-6 ">
          <div className="h-2 bg-slate-400 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-slate-400 rounded col-span-2"></div>
              <div className="h-2 bg-slate-400 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-slate-400 rounded"></div>
          </div>
        </div>
      </div>

      <div className="animate-pulse mt-7 flex justify-between  ">
        <div className="h-2 bg-slate-400 rounded col-span-2"></div>
        <div className="flex-1  space-y-6 ">
          <div className="h-2 bg-slate-400 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-slate-400 rounded col-span-2"></div>
              <div className="h-2 bg-slate-400 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-slate-400 rounded"></div>
          </div>
        </div>
        <div className="flex-1 space-y-6 ">
          <div className="h-2 bg-slate-400 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-slate-400 rounded col-span-2"></div>
              <div className="h-2 bg-slate-400 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-slate-400 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default PostLoading;
