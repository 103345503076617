// ContactModal.js
import React, { useState } from 'react';

const ContactModal = ({ onClose, postId }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your logic to handle form submission
    // You can access form data using formData state
    //Send this to backend

    console.log('Post ID:', postId);
    console.log(formData);
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <button onClick={onClose} className="absolute top-0 right-0 p-2">
          &times;
        </button>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-sm font-bold text-gray-600">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              required
              placeholder='Your name'
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          {/* Other form fields */}
          <div className="mb-4">
            <label htmlFor="phone" className="block text-sm font-bold text-gray-600">
              Phone
            </label>
            <input
              type="text"
              id="phone"
              placeholder='Your phone number'
              required
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-bold text-gray-600">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder='example@email.com'
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block text-sm font-bold text-gray-600">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows="4"
              required
              value={formData.message}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            ></textarea>
          </div>
          <div className="flex justify-between">
            <button type="button" onClick={onClose} className="bg-red-400 text-white p-2 rounded">
              Cancel
            </button>
            <button type="submit" className="bg-blue-500 text-white p-2 rounded">
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactModal;
