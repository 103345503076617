import React, { useState, useEffect } from "react";
import { useUser } from "../context/UserContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import icn_user from "../img/icn/icn_user.png";
import icn_email from "../img/icn/icn_email.png";
import icn_gender from "../img/icn/icn_gender.png";
import { useTranslation } from "react-i18next";

const UserProfileDetails = () => {
  const { user, error, isLoading } = useUser();
  const [userData, setUserData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/profile/userprofiledetails/${id}`;
    axios
      .get(apiUrl)
      .then((response) => {
        setUserData(response.data.userData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  return error ? (
    <span className="error-message">{error}</span>
  ) : isLoading ? (
    <div className="flex justify-center items-center h-[80vh]">
      <div className="text-4xl text-gray-600">Loading...</div>
    </div>
  ) : (
    <div className="flex justify-center items-center ">
      <Helmet>
        <title>Profile Details - Loko Property</title>
      </Helmet>

      <div className="bg-white shadow-lg rounded-lg w-full max-w-[800px] p-8   lg:mt-10 mt-5 ">
        <div className="flex flex-col items-center">
          {userData.proPic ? (
            <img
              src={userData.proPic}
              className="w-24 h-24 rounded-full mb-4"
              alt="Profile"
            />
          ) : (
            <img
              src={icn_user}
              className="w-24 h-24 rounded-full mb-4"
              alt="Profile"
            />
          )}
          <h2 className="text-2xl font-bold mb-2">{userData.name}</h2>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
          <div>
            <div className="flex items-center mb-1">
              {/* <img src={icn_email} className="w-4 h-4 mr-2" alt="Email" /> */}
              <span className="font-medium"> Total Posts</span>
            </div>
            <div className="text-gray-600"> </div>
          </div>
          <div>
            <div className="flex items-center mb-1">
              {/* <img src={icn_gender} className="w-4 h-4 mr-2" alt="Gender" /> */}
              <span className="font-medium">Account Status</span>
            </div>
            <div className="text-gray-600"> </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6">
          <div>
            <div className="flex items-center mb-1">
              <img src={icn_email} className="w-4 h-4 mr-2" alt="Email" />
              <span className="font-medium">Email:</span>
            </div>
            <div className="text-gray-600">{userData.email}</div>
          </div>
          <div>
            <div className="flex items-center mb-1">
              <img src={icn_gender} className="w-4 h-4 mr-2" alt="Gender" />
              <span className="font-medium">Gender:</span>
            </div>
            <div className="text-gray-600">{userData.gender}</div>
          </div>
        </div>

        <div className="mt-6">
          <div className="flex items-center mb-1">
            <span className="font-medium">About:</span>
          </div>
          <div className="text-gray-600">{userData.about}</div>
        </div>
        <div className="mt-6">
          <div className="flex items-center mb-1">
            <span className="font-medium">Occupation:</span>
          </div>
          <div className="text-gray-600">{userData.occupation}</div>
        </div>
        <div className="mt-6">
          <div className="flex items-center mb-1">
            <span className="font-medium">Address:</span>
          </div>
          <div className="text-gray-600">{userData.address}</div>
        </div>
        <div className="mt-6">
          <div className="flex items-center mb-1">
            <span className="font-medium">Join Date:</span>
          </div>
          <div className="text-gray-600">{userData.joiningDate}</div>
        </div>
        <div className="mt-6">
          <div className="flex items-center mb-1">
            <span className="font-medium">Signature:</span>
          </div>
          <div className="text-gray-600">{userData.signature}</div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileDetails;
