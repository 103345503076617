import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import locationData from "../Location.json";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { CgSpinner } from "react-icons/cg";
import search from "../img/icn/search.png";
import imgSell from "../img/icn/for_sell.png";
import imgRent from "../img/icn/for_rent.png";
import bg from "../img/wave (3).png";
import logo from "../img/logo2.png";
import rent from "../img/rent.png";
import sell from "../img/sell.png";
import group from "../img/group.png";
import interior from "../img/interior-Design.jpg";
import reset from "../img/icn/reset.png";

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  ///Setting font style
  const font = localStorage.getItem("lng");
  let setFont;
  if (font == "bn") {
    setFont = "font-TiroBangla";
  } else if (font == "en") {
    setFont = "font-Poppins-Regular";
  }

  //get Notice data to manage
  const [noticeData, setNoticeData] = useState([]);
  useEffect(() => {
    // Define the function to fetch Blog posts
    const fetchSiteNotice = async () => {
      try {
        // Make a GET request to your server endpoint
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/getsitenotice`
        ); // Update the endpoint accordingly

        // Set the retrieved Blog posts in the component state
        setNoticeData(response.data.data);
        // setPageLoading(false);
      } catch (error) {
        // Handle errors if any
        console.error("Error fetching Site Notice:", error);
      }
    };

    fetchSiteNotice();
  }, []);

  //search
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [propertyType, setPropertyType] = useState("Apartment/Flats");

  const modalRef = useRef(null);

  const [sellType, setSellType] = useState("Sell");

  const [divisionId, setDivisionId] = useState("");
  const [district, setDistrict] = useState([]);
  const [districtId, setDistrictId] = useState("");

  const handleDivision = (e) => {
    const getDivisionId = e.target.value;
    // Check if "Select Division" is selected
    if (getDivisionId === "") {
      // Reset the district state
      setDistrict([]);
    } else {
      // Find the selected division and set the district data
      const getDistrictData = locationData.find(
        (division) => division.division_id === getDivisionId
      ).district;
      setDistrict(getDistrictData);
    }
    // Set the divisionId
    setDivisionId(getDivisionId);
  };

  // Modify the handleDistrict function to set sub-districts
  const handleDistrict = (e) => {
    const districtId = e.target.value;
    setDistrictId(districtId);
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/property${sellType}Search?searchText=${searchText}&propertyType=${propertyType}&division=${divisionId}&district=${districtId}
          `
        );
        // Check if response.data.items exists, and use it if available
        const suggestions = response.data.items || response.data;
        setSuggestions(suggestions);
      } catch (error) {
        console.error(error);
      }
    };

    // Only fetch suggestions if there is text in the input
    if (searchText.trim() !== "") {
      fetchSuggestions();
      setShowModal(true); // Show modal when there is text
    } else {
      setSuggestions([]); // Clear suggestions if the input is empty
      setShowModal(false); // Show modal when there is text
    }
  }, [searchText]);

  const handleSearch = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/property${sellType}Search?searchText=${searchText}&propertyType=${propertyType}&division=${divisionId}&district=${districtId}`
      );

      // Check if response.data.items exists, and use it if available
      const results = response.data.items || response.data;

      setSearchResults(results);
      setShowModal(true); // Show modal when search results are available
      setSuggestions([]);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false); // Make sure to set loading to false in case of an error
    }
  };

  const handleClear = () => {
    setSearchText("");
    setSearchResults([]);
    setSuggestions([]);
    setShowModal(false); // Hide modal when clearing
  };

  //close modal by clicked outside
  useEffect(() => {
    const handleOutsideClick = (e) => {
      // Check if the click is outside the modal
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        // Close the modal by setting the showModal state to false
        setShowModal(false);
        setSearchResults([]);
        setSuggestions([]);
      }
    };

    // Add the event listener to the document
    document.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showModal]); // Include showModal in the dependency array to update the effect when showModal changes

  // Function to handle opening detail view
  const handleOpenDetail = (item) => {
    if (item.type === "sell")
      navigate(`/Property-Buy-Sell-Details/${item._id}`);
    else {
      navigate(`/Property-Rent-Details/${item._id}`);
    }
  };

  const cards = [
    {
      title: t("home.card_1"),
      subtitle: t("home.card_1_sub"),
      image: sell,
      link: "/Property-Buy-Sell",
    },
    {
      title: t("home.card_2"),
      subtitle: t("home.card_2_sub"),
      image: rent,
      link: "/Property-Rent",
    },
    {
      title: t("home.card_3"),
      subtitle: t("home.card_3_sub"),
      image: group,
      link: "/Group-Wise-Building",
    },
    {
      title: t("home.card_4"),
      subtitle: t("home.card_4_sub"),
      image: interior,
      link: "/Interior-Design",
    },
  ];

  return (
    <div className={`w-full ${setFont} duration-200`}>
      <div className={` w-full ${setFont} duration-200`}>
        <Helmet>
          <title>Home - Loko Property</title>
        </Helmet>
        {noticeData.RedNotice && (
          <div className="flex space-x-2 overflow-hidden shadow-lg">
            <div className="w-full text-white bg-red-500 p-0">
              <div className="marquee">
                <p className=" text-sm 2xl:text-base">{noticeData.RedNotice}</p>
              </div>
            </div>
          </div>
        )}
        {noticeData.YellowNotice && (
          <div className="flex space-x-2 overflow-hidden shadow-lg">
            <div className="w-full text-white bg-yellow-500 p-0 ">
              <div className="marquee ">
                <p className=" text-sm 2xl:text-base">
                  {noticeData.YellowNotice}
                </p>
              </div>
            </div>
          </div>
        )}
        {noticeData.GreenNotice && (
          <div className="flex space-x-2 overflow-hidden  shadow-lg">
            <div className="w-full text-white bg-green-500 p-0">
              <div className="marquee">
                <p className=" text-sm 2xl:text-base">
                  {noticeData.GreenNotice}
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="section1 w-full relative  min-h-screen ">
          <img
            src={bg}
            className=" h-full w-screen bg-no-repeat bg-cover absolute "
            alt="background"
          />

          <img
            src={logo}
            alt=""
            className="background-svg xl:h-[450px] xl:w-[900px] bg-no-repeat absolute right-0 mt-6"
          />

          <div className="flex md:px-[8%] justify-center relative ">
            <div className=" w-full ">
              <h2
                className={`text-2xl sm:text-3xl xl:text-4xl 2xl:text-5xl px-5 lg:px-2 md:w-[600px] 2xl:w-[800px] md:pt-[3vh] mt-[6vh] sm:mt-[7vh] md:mt-[5vh] text-white font-bold drop-shadow-md duration-200`}
              >
                {t("home.heading_1")}
              </h2>

              {/* search section*/}
              <div className="relative 2xl:mt-16 mt-10 px-5 lg:px-2 xl:text-end duration-200">
                {/* SellType button*/}

                <div className=" md:w-1/4  md:left-1/4 md:ml-[0px] ml-5  duration-200 relative">
                  <div className="  flex items-center  ">
                    <div className=" flex space-x-1">
                      <label className="cursor-pointer">
                        <input
                          type="radio"
                          value="Sell"
                          className="peer sr-only"
                          name="type"
                          checked={sellType === "Sell"}
                          onChange={(e) => setSellType(e.target.value)}
                        />
                        <div className=" text-center w-[50px] sm:w-[70px] rounded-t-lg bg-[#ffffff8a] p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600  peer-checked:ring-offset-1 peer-checked:bg-[#ffffff]">
                          <div className="flex flex-col gap-1">
                            <p className="text-xs  font-semibold  text-gray-500">
                              Sell
                            </p>
                          </div>
                        </div>
                      </label>

                      <label className="cursor-pointer ">
                        <input
                          type="radio"
                          value="Rent"
                          className="peer sr-only"
                          name="type"
                          checked={sellType === "Rent"}
                          onChange={(e) => setSellType(e.target.value)}
                        />
                        <div className="text-center w-[50px] sm:w-[70px] rounded-t-lg bg-[#ffffff8a] p-1 text-gray-900 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600  peer-checked:ring-offset-1 peer-checked:bg-[#ffffff]">
                          <div className="flex flex-col gap-1">
                            <p className="text-xs font-semibold  text-gray-500">
                              Rent
                            </p>
                          </div>
                        </div>
                      </label>

                      <label className="cursor-pointer ">
                        <input
                          type="radio"
                          value="Group"
                          className="peer sr-only"
                          name="type"
                          checked={sellType === "Group"}
                          onChange={(e) => setSellType(e.target.value)}
                        />
                        <div className=" text-center w-[50px] sm:w-[70px] rounded-t-lg bg-[#ffffff8a] p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-offset-1 peer-checked:bg-[#ffffff]">
                          <div className="flex flex-col gap-1">
                            <p className="text-xs  font-semibold  text-gray-500">
                              Group
                            </p>
                          </div>
                        </div>
                      </label>

                      <label className="cursor-pointer">
                        <input
                          type="radio"
                          value="Interior"
                          className="peer sr-only"
                          name="type"
                          checked={sellType === "Interior"}
                          onChange={(e) => setSellType(e.target.value)}
                        />
                        <div className=" text-center w-[50px] sm:w-[70px]  rounded-t-lg bg-[#ffffff8a] p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-offset-1 peer-checked:bg-[#ffffff]">
                          <div className="flex flex-col gap-1">
                            <p className="text-xs  font-semibold  text-gray-500">
                              Interior
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center w-full duration-200 ">
                  <div className="h-28 sm:h-20 xl:h-16 text-sm w-[98%] md:[95%] lg:w-[90%] xl:w-[80%] 2xl:w-[70%] bg-[#ffffffc9] rounded-lg flex flex-wrap justify-center items-center duration-200 ">
                    {/* Property type*/}

                    {sellType !== "Interior" && sellType !== "Group" && (
                      <div v className=" 2xl:h-10 h-8 text-xs 2xl:text-sm  ">
                        <select
                          required
                          className="2xl:h-10 h-8 w-40 p-1 bg-gray-50 border border-gray-300 text-gray-900 rounded-xl focus:ring-gray-500 focus:border-gray-500 block  dark:bg-white dark:border-gray-500 dark:placeholder-gray-400 dark:text-black dark:focus:ring-gray-500 dark:focus:border-gray-500"
                          onChange={(e) => setPropertyType(e.target.value)}
                        >
                          <option value="Apartment/Flats">
                            Apartment/Flats
                          </option>
                          <option value="Land Sharing Flats">
                            Land Sharing Flats
                          </option>
                          <option value="Duplex Home">Duplex Home</option>
                          <option value="Commercial Office">
                            Commercial Office
                          </option>
                          <option value="Commercial Showroom">
                            Commercial Showroom
                          </option>
                          <option value="Commercial Shop">
                            Commercial Shop
                          </option>
                          <option value="Commercial Restaurant">
                            Commercial Restaurant
                          </option>
                          <option value="Commercial Factory">
                            Commercial Factory
                          </option>
                          <option value="Commercial Warehouse">
                            Commercial Warehouse
                          </option>
                          <option value="Land">Land</option>
                        </select>
                      </div>
                    )}

                    {/* Location*/}
                    <div className="2xl:h-10 h-8 text-xs 2xl:text-sm ml-3 duration-200">
                      <div className="flex space-x-3">
                        <div className="relative 2xl:h-10 h-8  ">
                          <select
                            name="Division"
                            required
                            className="2xl:h-10 h-8 w-full bg-gray-50 border p-1 border-gray-300 text-gray-900 rounded-xl focus:ring-gray-500 focus:border-gray-500 block  dark:bg-white dark:border-gray-500 dark:placeholder-gray-400 dark:text-black dark:focus:ring-gray-500 dark:focus:border-gray-500"
                            onChange={(e) => handleDivision(e)}
                          >
                            <option value="" className="text-pink-600">
                              Select Division
                            </option>
                            {locationData.map((getDivision, index) => (
                              <option
                                value={getDivision.division_id}
                                key={index}
                              >
                                {getDivision.division_name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {district.length > 0 && (
                          <div className="relative 2xl:h-10 h-8 w-[120px]">
                            <select
                              name="states"
                              className="2xl:h-10 h-8 w-full bg-gray-50 border  border-gray-300 text-gray-900 rounded-xl focus:ring-gray-500 focus:border-gray-500 block  dark:bg-white dark:border-gray-500 dark:placeholder-gray-400 dark:text-black dark:focus:ring-gray-500 dark:focus:border-gray-500"
                              onChange={(e) => handleDistrict(e)}
                            >
                              <option value="" className="text-pink-600">
                                Select District
                              </option>
                              {district.map((getDistrict, index) => (
                                <option
                                  value={getDistrict.district_id}
                                  key={index}
                                >
                                  {getDistrict.district_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="2xl:h-10 h-8 flex duration-200">
                      <input
                        type="text"
                        className="2xl:h-10 h-8 sm:ml-2 2xl:w-[350px]  xl:w-[300px] lg:w-[330px] md:w-[320px] w-[210px] border border-gray-500 lg:ml-2 rounded-xl p-2 duration-200"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Title, Division, District, (optional)"
                      />
                      {(searchText.trim() !== "" ||
                        searchResults.length > 0) && (
                        <div
                          onClick={handleClear}
                          className=" items-center flex cursor-pointer ml-3 duration-200"
                        >
                          <img className="h-8 w-8" src={reset} alt=""></img>
                        </div>
                      )}
                      <button
                        onClick={handleSearch}
                        className="2xl:h-10 h-8 w-8 sm:ml-2 sm:w-28 border-gray-500 font-semibold text-gray-500 rounded-xl space-x-2 flex justify-center items-center duration-200 sm:bg-white sm:border sm:border-gray-500 sm:rounded-xl "
                      >
                        {loading && (
                          <CgSpinner
                            size={20}
                            className="animate-spin duration-200"
                          />
                        )}
                        <span className="hidden sm:inline duration-200">
                          Search
                        </span>
                        <img
                          src={search}
                          alt="search"
                          className="inline sm:hidden w-6 h-6"
                        />
                      </button>
                    </div>
                  </div>

                  {/* search modal*/}
                  <div
                    ref={modalRef}
                    className="w-full xl:mt-28 mt-32  flex justify-center absolute z-20  duration-200"
                  >
                    {showModal && (
                      <div
                        className={`  lg:w-[50%] md:w-[75%] w-[96%] bottom-0 bg-white overflow-auto styleScrollbar max-h-[600px] md:p-4 p-2  transform  border border-gray-500 rounded-2xl shadow-xl  duration-200`}
                      >
                        {suggestions.length === 0 &&
                        searchResults.length === 0 ? (
                          <p className="text-center text-gray-600">
                            {" "}
                            Nothing found{" "}
                          </p>
                        ) : (
                          <div>
                            {/* Display suggestions as the user types */}
                            <div className=" ">
                              {suggestions.map((suggestion) => (
                                <div
                                  className=" h-[110px] border-2 flex items-center cursor-pointer  border-gray-400 rounded-xl my-3  duration-200 ease-in-out"
                                  key={suggestion._id}
                                  onClick={() => handleOpenDetail(suggestion)} // Add onClick event
                                >
                                  <div className="flex ml-1 ">
                                    <div className="  absolute">
                                      {suggestion.type === "sell" ? (
                                        <img
                                          src={imgSell} // Use only the first URL
                                          alt=""
                                          className="h-[50px] w-[50px]  rounded-lg object-cover"
                                        />
                                      ) : (
                                        <img
                                          src={imgRent} // Use only the first URL
                                          alt=""
                                          className="h-[50px] w-[50px]  rounded-lg object-cover"
                                        />
                                      )}
                                    </div>

                                    <img
                                      src={suggestion.imageUrls[0]} // Use only the first URL
                                      alt=""
                                      className="h-[100px] w-[100px]  rounded-lg object-cover"
                                    />
                                    <div className="ml-3">
                                      <p className="text-lg">
                                        {" "}
                                        {suggestion.postTitle}
                                      </p>
                                      <p>Price: {suggestion.price}</p>
                                      <p>
                                        {suggestion.subDistrict},{" "}
                                        {suggestion.district},{" "}
                                        {suggestion.division}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            {/* Display search results */}
                            <div>
                              {searchResults.map((result) => (
                                <div
                                  className="h-[110px] border-2 flex items-center cursor-pointer border-gray-400 rounded-xl my-3"
                                  key={result._id}
                                  onClick={() => handleOpenDetail(result)} // Add onClick event
                                >
                                  <div className="flex ml-1">
                                    <div className="absolute">
                                      {result.type === "sell" ? (
                                        <img
                                          src={imgSell} // Use only the first URL
                                          alt=""
                                          className="h-[50px] w-[50px] rounded-lg object-cover"
                                        />
                                      ) : (
                                        <img
                                          src={imgRent} // Use only the first URL
                                          alt=""
                                          className="h-[50px] w-[50px] rounded-lg object-cover"
                                        />
                                      )}
                                    </div>

                                    {/* Check if imageUrls exists and has at least one element */}
                                    {result.imageUrls &&
                                    result.imageUrls.length > 0 ? (
                                      <img
                                        src={result.imageUrls[0]} // Use only the first URL
                                        alt=""
                                        className="h-[100px] w-[100px] rounded-lg object-cover"
                                      />
                                    ) : (
                                      <img
                                        src={"placeholderImage"} // A placeholder image in case imageUrls is missing
                                        alt="No Image Available"
                                        className="h-[100px] w-[100px] rounded-lg object-cover"
                                      />
                                    )}

                                    <div className="ml-3">
                                      <p className="text-lg">
                                        {result.postTitle}
                                      </p>
                                      <p>Price: {result.price}</p>
                                      <p>
                                        {result.subDistrict}, {result.district},{" "}
                                        {result.division}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-0 2xl:mt-10 py-4 2xl:py-10 px-6 sm:px-10 lg:px-8">
                <div className=" mx-auto mt-5 lg:mt-0">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10">
                    {cards.map((card, index) => (
                      <Link
                        key={index}
                        to={card.link}
                        className="block  hover:scale-[1.02] duration-200"
                      >
                        <div className="bg-white rounded-3xl shadow-md overflow-hidden hover:shadow-xl transition-shadow duration-300 h-full text-center">
                          <div className="aspect-w-16 aspect-h-9">
                            <img
                              className="object-cover w-full h-full"
                              src={card.image}
                              alt={card.title}
                            />
                          </div>
                          <div className="p-2">
                            <h2 className="text-xl font-semibold text-gray-800 mb-1">
                              {card.title}
                            </h2>
                            <p className="text-gray-600 text-sm">
                              {card.subtitle}
                            </p>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
