import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Box, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CgSpinner } from "react-icons/cg";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useTranslation } from "react-i18next";
import { toast, Toaster } from "react-hot-toast";

import eyeOpen from "../../svg/eye-open.svg";
import eyeClosed from "../../svg/eye-closed.svg";

import Logo from "../../img/icn/Logo.png";


import signBg from "../../img/signbg.jpg";

const Register = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      navigate("/profile");
    }
  });

  //Date picker

  const handleDateChange = (newValue) => {
    const formattedDate = dayjs(newValue).format("YYYY-MM-DD");
    setBirthDate(formattedDate);
  };

  const [ph, setPh] = useState("");

  ///Language
  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  ///Setting font style
  const font = localStorage.getItem("lng");
  let setFont;
  if (font == "bn") {
    setFont = "font-TrioBangla";
  } else if (font == "en") {
    setFont = "font-Poppins-Regular";
  }

  //get value for Register
  const [username, setUsername] = useState("");
  const number = ph;
  const [email, setEmail] = useState("");
  const [birthDate, setBirthDate] = useState(null);

  const [gender, setGender] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");

  const togglePasswordVisibility = (field, e) => {
    e.preventDefault();
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const registerHandler = async (e) => {
    e.preventDefault();

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    // Check if all fields are filled
    if (
      !username ||
      !number ||
      !email ||
      !birthDate ||
      !gender ||
      !password ||
      !confirmPassword
    ) {
      toast.error("PLEASE FILL OUT ALL FIELDS");
      return;
    }

    // Check if username is at least 4 characters and only contains letters
    const usernameRegex = /^[a-zA-Z\s]+$/;
    if (username.length < 4 || !usernameRegex.test(username)) {
      toast.error("Full name required and should only contain letters.");
      return;
    }

    // Check if phone number is at least 10 digits
    if (number.length < 10) {
      toast.error("Invalid phone number.");
      return;
    }

    // Check if email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email address.");
      return;
    }

    // Check if password is at least 6 characters
    if (password.length < 6) {
      toast.error("Password must be at least 6 characters.");
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setTimeout(() => {
        setError("");
      }, 5000);
      toast.error("Passwords do not match");
      return;
    }

    try {
      // Send register data to server
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/register`,
        {
          username,
          number,
          email,
          birthDate,
          gender,
          password,
        },
        config
      );
      navigate("/profile");
      localStorage.setItem("userToken", data.token);
      setTimeout(() => {
        window.location.reload();
      }, 800);
    } catch (error) {
      setError(error.response.data.error);
      toast.error(error.response.data.error);
      setTimeout(() => {
        setError("");
      }, 1000);
    }
  };

  return (
    <div className=" w-full flex justify-center items-center duration-200 ">
      <Helmet>
        <title>Register - Loko Property</title>
      </Helmet>
      <div className=" mt-5 lg:h-[670px] h-[105vh] xl:w-[60%]  lg:w-[70%]  md:w-[70%]  w-[95%] bg-gradient-to-b lg:bg-gradient-to-r from-black via-red-300 to-orange-200 lg:flex justify-center items-center rounded-[20px] duration-300">
        <div className="lg:h-full lg:w-[50%] lg:flex h-[150px] relative justify-center items-center">
          <img
            className=" opacity-30 h-full w-full lg:object-fill absolute lg:rounded-l-[20px] rounded-t-[20px]"
            src={signBg}
            alt="bg"
          />

          <div className="absolute lg:w-[400px] w-full lg:mt-0 mt-3">
            <img
              class=" m-auto block lg:h-[150px] h-[60px] lg:w-[150px]  w-[60px]  duration-200 mb-5"
              src={Logo}
              alt="logo"
            />

            <p
              className={`px-6 lg:text-2xl text-lg text-white text-center duration-200`}
            >
              {t("home.heading_1")}
            </p>
          </div>

          <div className="leftItem "></div>
        </div>

        <div className="lg:h-[500px] lg:w-[50%] h-[350px]   flex justify-center items-center ">
          <div>
            <Toaster toastOptions={{ duration: 4000 }} />
            <div id="recaptcha-container"></div>
            {user ? (
              <h2 className="text-center text-white font-medium text-2xl">
                👍Login Success
              </h2>
            ) : (

            <div className=" lg:mt-0 mt-64">
                <p className=" lg:text-2xl text-xl lg:text-black text-white font-bold">
                  Registration Form
                </p>

                <form onSubmit={registerHandler}>
                  <div className="my-5">
                    <input
                      type="text"
                      className=" h-12 w-80 text-black border-[1px] rounded-xl border-cyan-900 placeholder-gray-900 p-3 text-base"
                      placeholder="Enter full name"
                      value={username}
                      required
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="w-80 rounded-[15px] " style={{}}>
                    <PhoneInput
                      placeholder="Enter Phone Number"
                      country={"bd"}
                      required
                      value={ph}
                      onChange={setPh}
                    />
                  </div>

                  <div className="my-3">
                    <input
                      type="email"
                      className=" h-12  w-80 text-black border-[1px] rounded-xl border-cyan-900 placeholder-gray-900 p-3 text-base"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="my-3">
                    <Box style={{ width: "100%" }}>
                      <Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Select Birth Date"
                            value={birthDate ? dayjs(birthDate) : null}
                            InputLabelProps={{
                              shrink: false, // This will remove the label from the border
                            }}
                            onChange={handleDateChange}
                            sx={{
                              width: "100%",
                              borderRadius: "15px",
                              background: "white", // Set background color and width
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "15px", // Apply border radius to the input field
                              },
                              "& .MuiInputBase-root": {
                                borderColor: "", // Apply border color to the input field
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Typography>
                    </Box>
                  </div>

                  <div>

                    <div className="my-3 relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        required
                        className="h-12 w-80 text-black border-[1px] rounded-xl border-cyan-900 placeholder-gray-900 p-3 text-base"
                        placeholder="Enter password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        className="absolute top-1/2 right-3 transform -translate-y-1/2 focus:outline-none"
                        onClick={(e) => togglePasswordVisibility("password", e)}
                      >
                        <img
                          src={showPassword ? eyeOpen : eyeClosed}
                          alt={showPassword ? "Hide password" : "Show password"}
                          className="h-5 w-5 text-gray-400"
                        />
                      </button>
                    </div>

                    <div className="my-3 relative">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        required
                        className="h-12 w-80 text-black border-[1px] rounded-xl border-cyan-900 placeholder-gray-900 p-3 text-base"
                        placeholder="Confirm password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <button
                        className="absolute top-1/2 right-3 transform -translate-y-1/2 focus:outline-none"
                        onClick={(e) =>
                          togglePasswordVisibility("confirmPassword", e)
                        }
                      >
                        <img
                          src={showConfirmPassword ? eyeOpen : eyeClosed}
                          alt={
                            showConfirmPassword
                              ? "Hide password"
                              : "Show password"
                          }
                          className="h-5 w-5 text-gray-400"
                        />
                      </button>
                    </div>
                  </div>

                  {/* Gender */}
                  <div className=" h-12 flex items-center  ">
                    <label className=" text-lg mr-4  ">{"Gender"}</label>

                    <div className="flex">
                      <label class="cursor-pointer mr-2">
                        <input
                          type="radio"
                          value="Male"
                          class="peer sr-only"
                          name="gender"
                          checked={gender === "Male"}
                          onChange={(e) => setGender(e.target.value)}
                        />
                        <div class=" text-center w-[70px] rounded-md bg-white p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-blue-400 peer-checked:ring-offset-1 peer-checked:bg-[#caffb1]">
                          <div class="flex flex-col gap-1">
                            <p class="text-sm font-semibold  text-gray-500">
                              Male
                            </p>
                          </div>
                        </div>
                      </label>

                      <label class="cursor-pointer mr-2">
                        <input
                          type="radio"
                          value="Female"
                          class="peer sr-only"
                          name="gender"
                          checked={gender === "Female"}
                          onChange={(e) => setGender(e.target.value)}
                        />
                        <div class="text-center w-[70px] max-w-xl rounded-md bg-white p-1 text-gray-900 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-blue-400 peer-checked:ring-offset-1 peer-checked:bg-[#caffb1]">
                          <div class="flex flex-col gap-1">
                            <p class="text-sm font-semibold  text-gray-500">
                              Female
                            </p>
                          </div>
                        </div>
                      </label>

                      <label class="cursor-pointer">
                        <input
                          type="radio"
                          value="Others"
                          class="peer sr-only"
                          name="gender"
                          checked={gender === "Others"}
                          onChange={(e) => setGender(e.target.value)}
                        />
                        <div class=" text-center w-[70px] max-w-xl rounded-md bg-white p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-blue-400 peer-checked:ring-offset-1 peer-checked:bg-[#caffb1]">
                          <div class="flex flex-col gap-1">
                            <p class="text-sm font-semibold  text-gray-500">
                              Others
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <button
                    type="submit"
                    //onClick={onSignup}
                    className="bg-[#F59E0B] hover:bg-[#fd7200] w-full flex gap-1 items-center justify-center mt-5  py-2 text-white rounded-xl"
                  >
                    {loading && (
                      <CgSpinner size={20} className="mt-1 animate-spin" />
                    )}
                    <span>Register</span>
                  </button>
                  <p className="mt-3 text-center ">
                    {" Already have an account?   "}
                    <Link
                      to="/SignIn"
                      className=" text-blue-500 font-bold italic"
                    >
                      Login
                    </Link>
                  </p>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Register;
