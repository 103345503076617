import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [profileStatus, setProfileStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    if (userToken) {
      // Create the config object with the Authorization header
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };
      // Make the Axios request with the config
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/profile`, config)
        .then((response) => {
          setUser(response.data.userData);
          setProfileStatus(response.data.userData.status);
          localStorage.setItem("userToken",response.data.userData.userToken);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user information:", error);
          localStorage.removeItem("userToken");
          setIsLoading(false); // No token, set loading to false
        });
    }
  }, [userToken]);

  return (
    <UserContext.Provider value={{ user, error, isLoading }}>
      {children}
    </UserContext.Provider>
  );
}
