import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import axios from "axios";
import PostLoading from "../../component/PostLoading";

export default function UserIsNotSuspendRoute({ children }) {
  const [loading, setLoading] = useState(true);
  const [profileStatus, setProfileStatus] = useState(null);
  const userToken = localStorage.getItem("userToken");
  const location = useLocation();

  useEffect(() => {
    async function fetchProfileStatus() {
      if (userToken) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        };

        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/profile`, config);
          setProfileStatus(response.data.userData.status);
          console.log(response.data.userData.status);
        } catch (error) {
          console.error("Error fetching user information:", error);
        } finally {
          setLoading(false); // Set loading to false regardless of success or failure
        }
      }
    }

    fetchProfileStatus();
  }, [userToken]);

  const renderPostLoadingPage = () => {
    return <PostLoading />;
  };

  if (loading) {
    return <div className="flex justify-center">
      <div className="w-4/6">{renderPostLoadingPage()}</div>
      </div>;
  }

  if (profileStatus !== null && profileStatus !== "normal") {
    toast.error("Your Account is Suspended. You don't have access to this page.");
    return <Navigate to="/Home" />;
  }

  return children;
}
