import React from "react";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import { useUser } from "../../context/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ListLoading from "../../component/ListLoading";

import { motion } from "framer-motion";

import close from "../../img/icn/close.png";
import menu_pic from "../../img/icn/menu.png";

const UserPostInfo = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const userID = user.id;
  const userToken = localStorage.getItem("userToken");
  const [statusCode, setStatusCode] = useState();
  const [message, setMessage] = useState();

  const renderListLoadingPage = () => {
    return <ListLoading />;
  };

  const [posts, setPosts] = useState([]);
  const [approvedUserSellPosts, setApprovedUserSellPosts] = useState([]);
  const [nonApprovedUserSellPosts, setNonApprovedUserSellPosts] = useState([]);
  const [approvedUserRentPosts, setApprovedUserRentPosts] = useState([]);
  const [nonApprovedUserRentPosts, setNonApprovedUserRentPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState("");

  const [showMenuApprovedUserSellPosts, setShowMenuApprovedUserSellPosts] =
    useState(null);
  const [
    showMenuNonApprovedUserSellPosts,
    setShowMenuNonApprovedUserSellPosts,
  ] = useState(null);
  const [showMenuApprovedUserRentPosts, setShowMenuApprovedUserRentPosts] =
    useState(null);
  const [
    showMenuNonApprovedUserRentPosts,
    setShowMenuNonApprovedUserRentPosts,
  ] = useState(null);

  useEffect(() => {
    const getUserPosts = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/profile/getUserPosts`,
          config
        );

        if (response.data.message === "User Posts not found") {
          setStatusMessage("No post available");
        } else {
          setPosts(response.data);
          setApprovedUserSellPosts(
            response.data.userPosts.approvedUserSellPosts
          );
          setNonApprovedUserSellPosts(
            response.data.userPosts.nonApprovedUserSellPosts
          );
          setApprovedUserRentPosts(
            response.data.userPosts.approvedUserRentPosts
          );
          setNonApprovedUserRentPosts(
            response.data.userPosts.nonApprovedUserRentPosts
          );
          setStatusMessage("");
        }

        setLoading(false);
      } catch (error) {
        console.error("Error making Axios request:", error);
        setLoading(false);
      }
    };

    getUserPosts();
  }, [userID, userToken]);
  // Function to handle opening detail view

  const handleOpenDetail = (post) => {
    if (post.type === "sell") {
      navigate(`/Property-Buy-Sell-Details/${post.PostID}`);
    }
    if (post.type === "rent") {
      navigate(`/Property-Rent-Details/${post.PostID}`);
    }
  };

  const handleApprovedUserSellPostsMenuClick = (event, index) => {
    event.stopPropagation();
    setShowMenuNonApprovedUserSellPosts(false);
    setShowMenuNonApprovedUserRentPosts(false);
    setShowMenuApprovedUserRentPosts(false);
    setShowMenuApprovedUserSellPosts(
      index === showMenuApprovedUserSellPosts ? null : index
    );
  };

  const handleNonApprovedUserSellPostsMenuClick = (event, index) => {
    event.stopPropagation();

    setShowMenuApprovedUserSellPosts(false);
    setShowMenuNonApprovedUserRentPosts(false);
    setShowMenuApprovedUserRentPosts(false);

    setShowMenuNonApprovedUserSellPosts(
      index === showMenuNonApprovedUserSellPosts ? null : index
    );
  };

  const handleApprovedUserRentPostsMenuClick = (event, index) => {
    event.stopPropagation();

    setShowMenuNonApprovedUserSellPosts(false);
    setShowMenuApprovedUserSellPosts(false);
    setShowMenuNonApprovedUserRentPosts(false);

    setShowMenuApprovedUserRentPosts(
      index === showMenuApprovedUserRentPosts ? null : index
    );
  };

  const handleNonApprovedUserRentPostsMenuClick = (event, index) => {
    event.stopPropagation();

    setShowMenuNonApprovedUserSellPosts(false);
    setShowMenuApprovedUserSellPosts(false);
    setShowMenuApprovedUserRentPosts(false);

    setShowMenuNonApprovedUserRentPosts(
      index === showMenuNonApprovedUserRentPosts ? null : index
    );
  };

  const handleEditPost = async (event, post) => {
    event.stopPropagation();

    if (post.type === "sell") {
      navigate(`/Edit-Sell-Post/${post.PostID}`);
    }

    if (post.type === "rent") {
      navigate(`/Edit-Rent-Post/${post.PostID}`);
    }
  };

  //Confirmation modal
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  const handleDeleteModal = (event, post) => {
    setShowMenuApprovedUserSellPosts(false);
    setShowMenuNonApprovedUserSellPosts(false);
    setShowMenuApprovedUserRentPosts(false);
    setShowMenuNonApprovedUserRentPosts(false);
    event.stopPropagation();
    setShowConfirmation(true);
    setSelectedPost(post.PostID);
  };

  const handleContinue = () => {
    setShowConfirmation(false);
    if (selectedPost) {
      handleDeletePost(selectedPost);
    }
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  // Function to handle delete post
  const handleDeletePost = async (PostID) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/profile/deleteUserPosts/${PostID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      // Remove the deleted post from the state
      setNonApprovedUserSellPosts((prevNonApprovedPosts) =>
        prevNonApprovedPosts.filter((post) => post.PostID !== PostID)
      );

      setApprovedUserSellPosts((prevApprovedPosts) =>
        prevApprovedPosts.filter((post) => post.PostID !== PostID)
      );

      // Remove the deleted post from the state
      setNonApprovedUserRentPosts((prevNonApprovedPosts) =>
        prevNonApprovedPosts.filter((post) => post.PostID !== PostID)
      );

      setApprovedUserRentPosts((prevApprovedPosts) =>
        prevApprovedPosts.filter((post) => post.PostID !== PostID)
      );

      toast.success("Post Removed");
    } catch (error) {
      console.error("Error deleting post:", error);

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Server responded with:", error.response.data);
        toast.error(`Error: ${error.response.data.message}`);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received from the server");
        toast.error("Error: No response received from the server");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up the request:", error.message);
        toast.error(`Error: ${error.message}`);
      }
    }
  };

  return (
    <div>
      <div className="MainBody h-[82vh] overflow-auto styleScrollbar duration-200">
        {loading ? (
          <div className="h-full w-full flex justify-center items-center">
            <div className="space-y-3 px-2 w-full">
              {renderListLoadingPage()}
            </div>
          </div>
        ) : statusMessage ? (
          <div className="h-[70vh] w-full flex text-center justify-center items-center duration-200">
            <p className="lg:text-5xl md:text-xl text-md font-bold text-gray-400">
              {statusMessage}
            </p>
          </div>
        ) : (
          <div className="flex space-x-10">
            <div className="w-full">
              {/* pending sell post*/}
              {nonApprovedUserSellPosts.length > 0 && (
                <div>
                  <p className="text-red-500 md:text-base text-sm">
                    Pending Sell posts ({nonApprovedUserSellPosts.length})
                  </p>
                  <div>
                    {nonApprovedUserSellPosts.map((post, index) => (
                      <div
                        key={index}
                        className="border border-gray-400 md:m-3 m-1 rounded-lg flex justify-between items-center duration-200 p-1 md:p-4 mb-2"
                        onClick={() => handleOpenDetail(post)}
                      >
                        <div className="flex">
                          <img
                            src={post.firstImageUrl}
                            alt={post.postTitle}
                            className="mb-2 mr-2 md:h-24 h-16 md:w-24 w-16"
                          />
                          <div className="cursor-pointer">
                            <div className="overflow-hidden">
                              <p className="line-clamp-2 font-bold md:text-base text-xs">
                                {post.postTitle}
                              </p>
                            </div>
                            <p className="md:text-base text-xs">
                              {post.postDate}
                            </p>
                            <p className="md:text-base text-xs">
                              {post.subDistrict}, {post.district},{" "}
                              {post.division}
                            </p>
                            <p className="md:text-base text-xs font-bold md:font-semibold text-[#1a9649]">
                              Tk. {post.price}
                            </p>
                          </div>
                        </div>
                        <div className="relative">
                          <button
                            className="py-5"
                            onClick={(event) =>
                              handleNonApprovedUserSellPostsMenuClick(
                                event,
                                index
                              )
                            }
                          >
                            <motion.img
                              initial={{
                                rotate: 0,
                                scale: 1,
                              }}
                              animate={{
                                rotate:
                                  showMenuNonApprovedUserSellPosts === index
                                    ? 90
                                    : 0,
                                scale:
                                  showMenuNonApprovedUserSellPosts === index
                                    ? 1
                                    : 1,
                              }}
                              transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 10,
                              }}
                              src={
                                showMenuNonApprovedUserSellPosts === index
                                  ? close
                                  : menu_pic
                              }
                              className="md:h-7 md:w-7 h-5 w-5"
                              alt={
                                showMenuNonApprovedUserSellPosts === index
                                  ? "close"
                                  : "menu"
                              }
                            />
                          </button>

                          {showMenuNonApprovedUserSellPosts === index && (
                            <div className="absolute right-8  top-0 bg-white border border-gray-300 rounded-lg shadow-lg z-10 text-sm font-semibold text-gray-700">
                              <button
                                className="flex items-center px-4 py-2 hover:bg-gray-200 w-full "
                                onClick={(event) => handleEditPost(event, post)}
                              >
                                Edit
                              </button>
                              <button
                                className="flex items-center px-4 py-2 hover:bg-gray-200 w-full "
                                onClick={(event) =>
                                  handleDeleteModal(event, post)
                                }
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* pending rent post*/}
              {nonApprovedUserRentPosts.length > 0 && (
                <div>
                  <p className="text-red-500 md:text-base text-sm">
                    Pending Rent posts ({nonApprovedUserRentPosts.length})
                  </p>
                  <div>
                    {nonApprovedUserRentPosts.map((post, index) => (
                      <div
                        key={index}
                        className="border border-gray-400 md:m-3 m-1 rounded-lg flex justify-between items-center duration-200 p-1 md:p-4 mb-2"
                        onClick={() => handleOpenDetail(post)}
                      >
                        <div className="flex">
                          <img
                            src={post.firstImageUrl}
                            alt={post.postTitle}
                            className="mb-2 mr-2 md:h-24 h-16 md:w-24 w-16"
                          />
                          <div className="cursor-pointer">
                            <div className="overflow-hidden">
                              <p className="line-clamp-2 md:text-base text-xs font-bold">
                                {post.postTitle}
                              </p>
                            </div>
                            <p className="md:text-base text-xs">
                              {post.postDate}
                            </p>
                            <p className="md:text-base text-xs">
                              {post.subDistrict}, {post.district},{" "}
                              {post.division}
                            </p>
                            <p className="md:text-base text-xs font-bold md:font-semibold text-[#1a9649]">
                              Tk. {post.price}
                            </p>
                          </div>
                        </div>

                        <div className="relative">
                          <button
                            className="py-5"
                            onClick={(event) =>
                              handleNonApprovedUserRentPostsMenuClick(
                                event,
                                index
                              )
                            }
                          >
                            <motion.img
                              initial={{
                                rotate: 0,
                                scale: 1,
                              }}
                              animate={{
                                rotate:
                                  showMenuNonApprovedUserRentPosts === index
                                    ? 90
                                    : 0,
                                scale:
                                  showMenuNonApprovedUserRentPosts === index
                                    ? 1
                                    : 1,
                              }}
                              transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 10,
                              }}
                              src={
                                showMenuNonApprovedUserRentPosts === index
                                  ? close
                                  : menu_pic
                              }
                              className="md:h-7 md:w-7 h-5 w-5"
                              alt={
                                showMenuNonApprovedUserRentPosts === index
                                  ? "close"
                                  : "menu"
                              }
                            />
                          </button>

                          {showMenuNonApprovedUserRentPosts === index && (
                            <div className="absolute right-8  top-0 bg-white border border-gray-300 rounded-lg shadow-lg z-10 text-sm font-semibold text-gray-700">
                              <button
                                className="flex items-center px-4 py-2 hover:bg-gray-200 w-full "
                                onClick={(event) => handleEditPost(event, post)}
                              >
                                Edit
                              </button>
                              <button
                                className="flex items-center px-4 py-2 hover:bg-gray-200 w-full "
                                onClick={(event) =>
                                  handleDeleteModal(event, post)
                                }
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {approvedUserSellPosts.length > 0 && (
                <div>
                  <p className="text-green-700 md:text-base text-sm">
                    Allowed Sell posts ({approvedUserSellPosts.length})
                  </p>
                  <div>
                    {approvedUserSellPosts.map((post, index) => (
                      <div
                        key={index}
                        className="border border-gray-400 md:m-3 m-1 rounded-lg flex justify-between items-center duration-200 p-1 md:p-4 mb-2"
                        onClick={() => handleOpenDetail(post)}
                      >
                        <div className="flex">
                          <img
                            src={post.firstImageUrl}
                            alt={post.postTitle}
                            className="mb-2 mr-2 md:h-24 h-16 md:w-24 w-16"
                          />
                          <div className="cursor-pointer">
                            <div className="overflow-hidden">
                              <p className="line-clamp-2 md:text-base text-xs font-bold">
                                {post.postTitle}
                              </p>
                            </div>
                            <p className="md:text-base text-xs">
                              {post.postDate}
                            </p>
                            <p className="md:text-base text-xs">
                              {post.subDistrict}, {post.district},{" "}
                              {post.division}
                            </p>
                            <p className="md:text-base text-xs font-bold md:font-semibold text-[#1a9649]">
                              Tk. {post.price}
                            </p>
                          </div>
                        </div>
                        <div className="relative">
                          <button
                            className="py-5"
                            onClick={(event) =>
                              handleApprovedUserSellPostsMenuClick(event, index)
                            }
                          >
                            <motion.img
                              initial={{
                                rotate: 0,
                                scale: 1,
                              }}
                              animate={{
                                rotate:
                                  showMenuApprovedUserSellPosts === index
                                    ? 90
                                    : 0,
                                scale:
                                  showMenuApprovedUserSellPosts === index
                                    ? 1
                                    : 1,
                              }}
                              transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 10,
                              }}
                              src={
                                showMenuApprovedUserSellPosts === index
                                  ? close
                                  : menu_pic
                              }
                              className="md:h-7 md:w-7 h-5 w-5"
                              alt={
                                showMenuApprovedUserSellPosts === index
                                  ? "close"
                                  : "menu"
                              }
                            />
                          </button>

                          {showMenuApprovedUserSellPosts === index && (
                            <div className="absolute right-8  top-0 bg-white border border-gray-300 rounded-lg shadow-lg z-10 text-sm font-semibold text-gray-700">
                              <button
                                className="flex items-center px-4 py-2 hover:bg-gray-200 w-full "
                                onClick={(event) => handleEditPost(event, post)}
                              >
                                Edit
                              </button>
                              <button
                                className="flex items-center px-4 py-2 hover:bg-gray-200 w-full "
                                onClick={(event) =>
                                  handleDeleteModal(event, post)
                                }
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {approvedUserRentPosts.length > 0 && (
                <div>
                  <p className="text-green-700 md:text-base text-sm">
                    Allowed Rent posts ({approvedUserRentPosts.length})
                  </p>
                  <div>
                    {approvedUserRentPosts.map((post, index) => (
                      <div
                        key={index}
                        className="border border-gray-400 md:m-3 m-1 rounded-lg flex justify-between items-center duration-200 p-1 md:p-4 mb-2"
                        onClick={() => handleOpenDetail(post)}
                      >
                        <div className="flex">
                          <img
                            src={post.firstImageUrl}
                            alt={post.postTitle}
                            className="mb-2 mr-2 md:h-24 h-16 md:w-24 w-16"
                          />
                          <div className="cursor-pointer">
                            <div className="overflow-hidden">
                              <p className="md:text-base text-xs line-clamp-2 font-bold">
                                {post.postTitle}
                              </p>
                            </div>
                            <p className="md:text-base text-xs">
                              {post.postDate}
                            </p>
                            <p className="md:text-base text-xs">
                              {post.subDistrict}, {post.district},{" "}
                              {post.division}
                            </p>
                            <p className="md:text-base text-xs font-bold md:font-semibold text-[#1a9649]">
                              Tk. {post.price}
                            </p>
                          </div>
                        </div>

                        <div className="relative">
                          <button
                            className="py-5"
                            onClick={(event) =>
                              handleApprovedUserRentPostsMenuClick(event, index)
                            }
                          >
                            <motion.img
                              initial={{
                                rotate: 0,
                                scale: 1,
                              }}
                              animate={{
                                rotate:
                                  showMenuApprovedUserRentPosts === index
                                    ? 90
                                    : 0,
                                scale:
                                  showMenuApprovedUserRentPosts === index
                                    ? 1
                                    : 1,
                              }}
                              transition={{
                                type: "spring",
                                stiffness: 100,
                                damping: 10,
                              }}
                              src={
                                showMenuApprovedUserRentPosts === index
                                  ? close
                                  : menu_pic
                              }
                              className="md:h-7 md:w-7 h-5 w-5"
                              alt={
                                showMenuApprovedUserRentPosts === index
                                  ? "close"
                                  : "menu"
                              }
                            />
                          </button>

                          {showMenuApprovedUserRentPosts === index && (
                            <div className="absolute right-8  top-0 bg-white border border-gray-300 rounded-lg shadow-lg z-10 text-sm font-semibold text-gray-700">
                              <button
                                className="flex items-center px-4 py-2 hover:bg-gray-200 w-full "
                                onClick={(event) => handleEditPost(event, post)}
                              >
                                Edit
                              </button>
                              <button
                                className="flex items-center px-4 py-2 hover:bg-gray-200 w-full "
                                onClick={(event) =>
                                  handleDeleteModal(event, post)
                                }
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white h-[250px] w-[450px] p-6 rounded-2xl  relative">
            <h3 className="text-2xl  mb-4 text-green-500 font-bold">
              Confirmation
            </h3>
            <div>
              <p>are you sure ? </p>
            </div>
            <div className="mt-4 flex absolute bottom-0 right-0 p-5">
              <button
                className="mr-2 px-4 py-2 bg-red-500 text-white rounded-md"
                onClick={handleContinue}
              >
                Delete
              </button>
              <button
                className="px-4 py-2 bg-green-300 rounded-md"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserPostInfo;
