import { Navigate,useLocation  } from "react-router-dom";
import { toast } from "react-hot-toast";
export default function PrivateRoute({ children }) {

  const  currentUser  =  localStorage.getItem("userToken");
  const location = useLocation();

  if (!currentUser) {
    localStorage.setItem("redirectUrl", location.pathname);
    //console.log(currentUser)
   // toast.error("You have to login first");
    return <Navigate to='/SignIn' />
  }

  return children;
}
