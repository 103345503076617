import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import PostLoading from "../../component/PostLoading";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import icn_user from "../../img/icn/icn_user.png";


const BlogPostDetails = () => {
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(true);
  const [vlogData, setVlogData] = useState(null);
  const [vlogAuthorData, setVlogAuthorData] = useState(null);
  const [vlogImages, setVlogImages] = useState([]);

  const { t, i18n } = useTranslation();
  const font = localStorage.getItem("lng") === "bn" ? "font-TiroBangla" : "font-Poppins-Regular";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/getvlogpostdetails/${id}`);
        setVlogData(response.data.vlogPost);
        setVlogImages(response.data.vlogPost.imageUrls);
        setVlogAuthorData(response.data.author);
        setPageLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setPageLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const sanitizeHTML = (html) => ({
    __html: DOMPurify.sanitize(html.replace(/\n/g, "<br>"))
  });

  if (pageLoading) return <PostLoading />;
  if (!vlogData) return <div className="text-center text-2xl text-gray-600 py-20">No post available</div>;

  return (
    <div className={`${font} bg-gray-100 min-h-screen py-10`}>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <article className="bg-white shadow-lg rounded-lg overflow-hidden">
          <img
            src={vlogImages[0]}
            alt="Blog cover"
            className="w-full h-64 sm:h-80 object-cover"
          />

          <div className="p-6 sm:p-8">
            <p className="text-sm text-gray-500 uppercase tracking-wider">{vlogData.Topic}</p>
            <h1 className="mt-2 text-3xl sm:text-4xl font-bold text-gray-900 leading-tight">
              {vlogData.Title}
            </h1>

            <div className="mt-4 flex items-center">
              <div className="flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src={vlogAuthorData.avatar || icn_user}
                  alt={vlogAuthorData.username}
                />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900">{vlogAuthorData.username}</p>
                <p className="text-sm text-gray-500">
                  Posted on {new Date(vlogData.postDate).toLocaleDateString()}
                </p>
              </div>
            </div>

            <div
              className="mt-6 prose prose-lg max-w-none"
              dangerouslySetInnerHTML={sanitizeHTML(vlogData.Post)}
            />
          </div>
        </article>
      </div>
    </div>
  );
};

export default BlogPostDetails;