import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../context/UserContext";
import { toast } from "react-hot-toast";

import locationData from "../../../Location.json";
import CardLoading from "../../../component/HorizontalCardLoading";

//icons
import search from "../../../img/icn/search.png";
import { CgSpinner } from "react-icons/cg";
import cancel from "../../../img/icn/cancel.png";
import icn_user from "../../../img/icn/icn_user.png";
import add from "../../../img/icn/add.png";
import save from "../../../img/icn/love.png";
import unsave from "../../../img/icn/unsave.png";
import locations from "../../../img/icn/location.png";
import camera from "../../../img/icn/camera.png";
import bed from "../../../img/icn/bed.png";
import bath from "../../../img/icn/bath.png";
import area from "../../../img/icn/area.png";
import garage from "../../../img/icn/garage.png";
import conditions from "../../../img/icn/conditions.png";
import { Helmet } from "react-helmet";
import axios from "axios";

const PropertySell = () => {
  //search
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const [propertyType, setPropertyType] = useState("Apartment/Flats");

  //const [sellType, setSellType] = useState("Buy");
  const [divisionId, setDivisionId] = useState("");
  const [district, setDistrict] = useState([]);
  const [districtId, setDistrictId] = useState("");

  const currentUser = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const location = useLocation();

  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  ///Setting font style
  const font = localStorage.getItem("lng");
  let setFont;
  if (font == "bn") {
    setFont = "font-TiroBangla";
  } else if (font == "en") {
    setFont = "font-Poppins-Regular";
  }

  const renderCardLoadingPage = () => {
    return <CardLoading />;
  };

  //get current user data
  const userToken = localStorage.getItem("userToken");

  //get user saved post
  const { user } = useUser();

  const [savedPosts, setSavedPosts] = useState([]);

  const userID = user?.id;
  const status = user?.status;

  //get user SavePost
  useEffect(() => {
    // Check if user exists before making the Axios request
    if (user) {
      const handleFetchSavedPosts = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/profile/getSavePost`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`, // Ensure userToken is defined
              },
            }
          );
          setSavedPosts(response.data.SavedPosts);
        } catch (error) {
          console.error("Error making Axios request:", error);
        }
      };

      handleFetchSavedPosts();
    }
  }, [user]); // Run when the user changes

  const queryParams = new URLSearchParams(location.search);
  const currentPageQueryParam = queryParams.get("page");
  const initialPage = currentPageQueryParam
    ? parseInt(currentPageQueryParam)
    : 1;

  const [currentPage, setCurrentPage] = useState(initialPage);
  const postPerPage = 8;
  const [loading, setLoading] = useState(true);
  const [dataFromAPI, setDataFromAPI] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState();

  // Function to fetch data based on page and limit
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/propertysellposts?page=${currentPage}&limit=${postPerPage}`
      );
      setDataFromAPI(response.data.items);
      setTotalPages(Math.ceil(response.data.totalItems / postPerPage));
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const updateURL = (page) => {
    const newParams = new URLSearchParams(queryParams);
    newParams.set("page", page);
    window.history.replaceState(
      null,
      null,
      `${location.pathname}?${newParams.toString()}`
    );
  };

  //Save unsave Optimistic UI Update
  const handleSaveUnSavePost = async (event, postId) => {
    event.stopPropagation();

    if (!currentUser) {
      localStorage.setItem("redirectUrl", location.pathname);
      toast.error("You have to login first");
      navigate("/SignIn");
    } else {
      const userID = user.id;
      const PostID = postId;
      const isPostSaved = savedPosts.some(
        (savedPost) => savedPost._id === PostID
      );

      try {
        if (isPostSaved) {
          // Optimistically update the UI
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
          toast.success("Post Unsaved");

          // Make the network request in the background
          await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/profile/deleteUserSavedPost/${PostID}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
        } else {
          // Optimistically update the UI
          const newSavedPost = { _id: PostID }; // Create a minimal representation of the saved post
          setSavedPosts((prevPosts) => [...prevPosts, newSavedPost]);
          toast.success("Post Saved");

          // Make the network request in the background
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/profile/setSavePost`,
            { userID, PostID },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );

          // Update the UI with the full post details from the server response
          const savedPost = response.data.SavedPosts.find(
            (post) => post._id === PostID
          );
          setSavedPosts((prevPosts) =>
            prevPosts.map((post) => (post._id === PostID ? savedPost : post))
          );
        }
      } catch (error) {
        // Revert the optimistic UI update on error
        if (isPostSaved) {
          setSavedPosts((prevPosts) => [...prevPosts, { _id: PostID }]);
        } else {
          setSavedPosts((prevPosts) =>
            prevPosts.filter((post) => post._id !== PostID)
          );
        }

        console.error("Error saving/unsaving post:", error);
        if (error.response && error.response.data) {
          const errorMessage = error.response.data.error;
          console.error("Server error message:", errorMessage);
          //toast.error(`Error: ${errorMessage}`);
        } else {
          //toast.error("Error saving/unsaving post");
        }
      }
    }
  };

  // Function to handle opening detail view
  const handleOpenDetail = (item) => {
    navigate(`/Property-Buy-Sell-Details/${item.PostID}`);
  };

  //search engine
  const handleDivision = (e) => {
    const getDivisionId = e.target.value;
    // Check if "Select Division" is selected
    if (getDivisionId === "") {
      // Reset the district state
      setDistrict([]);
    } else {
      // Find the selected division and set the district data
      const getDistrictData = locationData.find(
        (division) => division.division_id === getDivisionId
      ).district;
      setDistrict(getDistrictData);
    }
    // Set the divisionId
    setDivisionId(getDivisionId);
  };

  // Modify the handleDistrict function to set sub-districts
  const handleDistrict = (e) => {
    const districtId = e.target.value;
    setDistrictId(districtId);
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/propertySellSearch?searchText=${searchText}&propertyType=${propertyType}&division=${divisionId}&district=${districtId}
          `
        );
        setSuggestions(response.data.items);
      } catch (error) {
        console.error(error);
      }
    };

    // Only fetch suggestions if there is text in the input
    if (searchText.trim() !== "") {
      fetchSuggestions();
    } else {
      setSuggestions([]); // Clear suggestions if the input is empty
    }
  }, [searchText]);

  const handleSearch = async () => {
    setSearchLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/propertySellSearch?searchText=${searchText}&propertyType=${propertyType}&division=${divisionId}&district=${districtId}
          `
      );
      if (response.data.items.length === 0) {
        toast.error("No result found");
      }
      setTotalItems(response.data.totalItems);
      setSearchResults(response.data.items);
      setSearchLoading(false);
      setSuggestions([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClear = () => {
    setSearchText("");
    setSearchResults([]);
    setSuggestions([]);
  };

  function getRelativeTime(postDate) {
    const post = new Date(postDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now - post) / 1000);

    if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    }
  }

  return (
    <div
      className={`MainSection  ${setFont} min-h-screen w-full flex justify-center`}
    >
      <Helmet>
        <title>Property Buy/Sell - Loko Property</title>
      </Helmet>
      <div className="w-[98%] md:w-[90%] xl:w-[85%] 2xl:w-[75%]">
        {/* search section*/}

        <div className="w-full px-4 py-6 bg-gray-100">
          <div className="mx-auto">
            <div className="bg-white rounded-xl shadow-md p-4 md:p-6">
              <div className="flex flex-col md:flex-row justify-between items-start md:items-center ">
                <div className="mb-4 md:mb-0 w-full md:w-auto">
                  <Link to="/sellpost">
                    <button className="w-full md:w-auto px-4 py-2 bg-green-200 text-green-800 rounded-lg border border-green-500 hover:bg-green-300 transition duration-200 flex items-center justify-center">
                      <img src={add} alt="add" className="w-5 h-5 mr-2" />
                      <span className="text-sm">Add Sell Post</span>
                    </button>
                  </Link>
                </div>

                <div className="w-full md:w-3/4 xl:w-4/5">
                  <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-2">
                    <select
                      className="w-full p-1 text-sm bg-gray-50 border border-gray-300 rounded-lg focus:ring-green-500 focus:border-green-500"
                      onChange={(e) => setPropertyType(e.target.value)}
                    >
                      <option value="Apartment/Flats">Apartment/Flats</option>
                      <option value="Land Sharing Flats">
                        Land Sharing Flats
                      </option>
                      <option value="Duplex Home">Duplex Home</option>
                      <option value="Commercial Office">
                        Commercial Office
                      </option>
                      <option value="Commercial Showroom">
                        Commercial Showroom
                      </option>
                      {/* Add more options as needed */}
                    </select>

                    <select
                      name="Division"
                      className="w-full p-1 text-sm bg-gray-50 border border-gray-300 rounded-lg focus:ring-green-500 focus:border-green-500"
                      onChange={handleDivision}
                    >
                      <option value="">Select Division</option>
                      {locationData.map((getDivision, index) => (
                        <option value={getDivision.division_id} key={index}>
                          {getDivision.division_name}
                        </option>
                      ))}
                    </select>

                    {district.length > 0 && (
                      <select
                        name="states"
                        className="w-full p-1 text-sm bg-gray-50 border border-gray-300 rounded-lg focus:ring-green-500 focus:border-green-500"
                        onChange={handleDistrict}
                      >
                        <option value="">Select District</option>
                        {district.map((getDistrict, index) => (
                          <option value={getDistrict.district_id} key={index}>
                            {getDistrict.district_name}
                          </option>
                        ))}
                      </select>
                    )}

                    <div className="col-span-2 w-full flex">
                      <input
                        type="text"
                        className="p-1 w-full text-sm border border-gray-300 rounded-l-lg focus:ring-green-500 focus:border-green-500"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Title, Division, District"
                      />

                      <button
                        onClick={handleSearch}
                        className="px-4 py-2 bg-green-200 rounded-r-lg text-green-800 border border-green-500 hover:bg-green-300 transition duration-200 flex items-center justify-center"
                      >
                        {searchLoading && (
                          <CgSpinner size={20} className="animate-spin mr-2" />
                        )}
                        <span className="text-sm">Search</span>
                      </button>

                      {(searchText.trim() !== "" ||
                        suggestions.length > 0 ||
                        searchResults.length > 0) && (
                        <button
                          onClick={handleClear}
                          className="flex items-center justify-center duration-200"
                        >
                          <img src={cancel} alt="Clear" className="h-8  mx-4" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Display loading indicator */}
        {loading ? (
          <div className=" w-full flex justify-center  items-center ">
            <div className="  px-2 w-full">{renderCardLoadingPage()}</div>
          </div>
        ) : dataFromAPI.length === 0 ? (
          <div className="h-[50vh] w-full flex text-center justify-center items-center duration-200">
            <p className="text-xl xl:text-3xl 2xl:text-5xl font-bold text-gray-400">
              No post available
            </p>
          </div>
        ) : (
          <div className=" md:flex">
            <div className="md:w-[70%] w-[98%]">
              {suggestions.length > 0 ? (
                <div>
                  <p className=" text-center my-5 text-yellow-700 bg-yellow-100">
                    Matching with....
                  </p>

                  <div className=" items-center mt-5 duration-200">
                    {suggestions.map((item) => (
                      <PropertyCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                        handleSaveUnSavePost={handleSaveUnSavePost}
                        savedPosts={savedPosts}
                        getRelativeTime={getRelativeTime}
                      />
                    ))}
                  </div>
                </div>
              ) : searchResults && searchResults.length > 0 ? (
                <div>
                  <p className=" text-center my-5 text-green-700 bg-green-200">
                    {totalItems} result found
                  </p>
                  <div className=" items-center mt-5 duration-200">
                    {searchResults.map((item) => (
                      <PropertyCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                        handleSaveUnSavePost={handleSaveUnSavePost}
                        savedPosts={savedPosts}
                        getRelativeTime={getRelativeTime}
                      />
                    ))}
                  </div>
                </div>
              ) : dataFromAPI && dataFromAPI.length > 0 ? (
                <div className=" items-center mt-5 duration-200 ">
                  {/* main post */}
                  <div className=" space-y-8 ">
                    {dataFromAPI.map((item) => (
                      <PropertyCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                        handleSaveUnSavePost={handleSaveUnSavePost}
                        savedPosts={savedPosts}
                        getRelativeTime={getRelativeTime}
                      />
                    ))}
                  </div>
                  {/* Pagination buttons */}
                  <div className="pagination text-center mt-5 h-10">
                    {totalPages > 1 && (
                      <div className="pagination text-center mt-5 h-10">
                        {currentPage > 1 && (
                          <button
                            className="pagination-button h-8 w-24 mx-1 mr-3 rounded-md border border-green-600 duration-200"
                            onClick={() => {
                              if (currentPage > 1) {
                                setCurrentPage(currentPage - 1);
                                updateURL(currentPage - 1);
                              }
                            }}
                          >
                            {"« Previous"}
                          </button>
                        )}

                        {Array.from({ length: totalPages }).map((_, index) => (
                          <button
                            key={index}
                            className={`pagination-button h-8 w-7 mx-1 rounded-md border border-green-600 duration-200 ${
                              currentPage === index + 1
                                ? "active bg-green-400"
                                : ""
                            }`}
                            onClick={() => {
                              setCurrentPage(index + 1);
                              updateURL(index + 1);
                            }}
                          >
                            {index + 1}
                          </button>
                        ))}

                        {currentPage < totalPages && (
                          <button
                            className="pagination-button h-8 ml-3 w-24 mx-1 rounded-md border border-green-600 duration-200"
                            onClick={() => {
                              if (currentPage < totalPages) {
                                setCurrentPage(currentPage + 1);
                                updateURL(currentPage + 1);
                              }
                            }}
                          >
                            {"Next »"}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="md:w-[30%] flex justify-center md:ml-3 md:mt-5">
              <div className="md:ml-3 ">
                <div className=" p-3 shadow-md text-center">
                  <h2 className="text-xl md:px-10   2xl:text-2xl font-bold text-gray-600">
                    Let us know your interest
                  </h2>
                  <div
                    className={` w-full flex justify-center  items-center duration-200 `}
                  >
                    <p className=" text-justify mt-5">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const PropertyCard = ({
  item,
  handleOpenDetail,
  handleSaveUnSavePost,
  savedPosts,
  getRelativeTime,
}) => (
  <div
    onClick={() => handleOpenDetail(item)}
    className="md:flex cursor-pointer md:h-[300px] 2xl:h-[350px] m-3 md:mx-0 mx-5 md:max-w-[100%] shadow-xl rounded-xl myHover overflow-hidden hover:translate-y-[-5px] duration-300"
  >
    <div className="relative overflow-hidden h-[220px] md:h-full md:w-1/2">
      <img
        src={
          item.imageUrls.length > 0
            ? item.imageUrls[0]
            : "default-image-url.jpg"
        }
        alt="Property Image"
        className="md:w-full md:h-full md:object-cover transition-transform imageHover duration-500"
      />
      <div className="absolute top-0 w-full h-[40px] bg-gradient-to-b from-[#000000c2] to-transparent duration-200">
        <div className="flex justify-between items-center">
          <div className="flex h-7 space-x-1 m-3">
            <img src={camera} alt="camera" className="h-5 w-5 mt-[2px]" />
            <h2 className="text-white">{item.imageUrls.length}</h2>
          </div>
          <div className="text-white px-2 py-1 bg-red-600 text-xs mr-2 rounded-lg">
            <p className="capitalize">{item.type}</p>
          </div>
        </div>
      </div>
      <div className="px-3 h-[50px] w-full m-auto bottom-0 flex items-center justify-between absolute bg-gradient-to-b from-transparent to-[#000000e8] duration-200">
        <div className="flex items-center space-x-1 mb-2">
          <img
            src={item.userData.proPic ? item.userData.proPic : icn_user}
            alt="User Profile"
            className="h-5 lg:h-7 w-5 lg:w-7 rounded-full"
          />
          <p className="text-white p-2 text-sm">{item.userData.username}</p>
        </div>
        <button onClick={(event) => handleSaveUnSavePost(event, item._id)}>
          <div className="m-auto flex items-center justify-end h-10 w-10 px-1 cursor-pointer rounded-lg hover:scale-105 duration-200">
            <img
              src={
                savedPosts.some((savedPost) => savedPost._id === item._id)
                  ? save
                  : unsave
              }
              className="h-6 w-6 duration-200"
              alt="Save Icon"
            />
          </div>
        </button>
      </div>
    </div>

    <div className="md:w-1/2 md:p-4 p-2 bg-white relative flex flex-col h-full">
      <div className="2xl:text-2xl md:text-xl text-xl text-[#666666] font-bold  max-w-[100%] line-clamp-1">
        {item.postTitle}
      </div>
      <div className="flex justify-between bg-gradient-to-l px-2 my-1 mt-2 from-transparent to-[#40a5213f]">
        <p className="text-green-900 text-sm">
          {"Condition : "}
          {item.condition}
        </p>
      </div>
      <div className="mt-4">
        <div className="flex items-center">
          <img src={locations} className="h-4 w-4" alt="Location" />
          <p className="ml-2 lg:text-base text-[#666666] text-xs">
            {item.subDistrict}, {item.district}, {item.division}
          </p>
        </div>
        <div className="border-b-2 border-gray-300 mt-2"></div>
      </div>
      <div className="mt-2">
        <p className="text-xs font-semibold text-[#666666]">
        Property Type : {item.propertyType}
        </p>
      </div>

      <div className="flex  2xl:space-x-12 lg:space-x-6 space-x-4  mt-4 text-[#666666]  ">
        {item.propertyType === "Apartment/Flats" ||
        item.propertyType === "Land Sharing Flats" ||
        item.propertyType === "Duplex Home" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200 ">
            <div>
              <p>Bedrooms</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={bed} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.bedroom}</p>
            </div>
          </div>
        ) : null}

        {item.propertyType === "Apartment/Flats" ||
        item.propertyType === "Land Sharing Flats" ||
        item.propertyType === "Duplex Home" ||
        item.propertyType === "Commercial Restaurant" ||
        item.propertyType === "Commercial Shop" ||
        item.propertyType === "Commercial Office" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200 ">
            <div>
              <p>Bathrooms</p>
            </div>
            <div className="flex space-x-1 mt-1">
              <img src={bath} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.bathroom}</p>
            </div>
          </div>
        ) : null}

        {item.propertyType === "Commercial Office" ||
        item.propertyType === "Commercial Restaurant" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200">
            <div>
              <p>Rooms</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={bed} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.rooms}</p>
            </div>
          </div>
        ) : null}

        {item.propertyType !== "Commercial Factory" &&
        item.propertyType !== "Commercial Warehouse" &&
        item.propertyType !== "Land" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200 ">
            <div>
              <p>Garage</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={garage} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.garages}</p>
            </div>
          </div>
        ) : null}

        {item.propertyType === "Commercial Warehouse" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200 ">
            <div>
              <p>Status</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={conditions} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.construction}</p>
            </div>
          </div>
        ) : null}

        {item.propertyType === "Commercial Warehouse" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200">
            <div>
              <p>Condition</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={conditions} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.condition}</p>
            </div>
          </div>
        ) : null}

        {item.propertyType !== "Land" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200 ">
            <div>
              <p>Area</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={area} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.size} ft²</p>
            </div>
          </div>
        ) : null}

        {item.propertyType === "Land" ? (
          <div className="text-center md:font-semibold  flex flex-col items-center text-xs duration-200 ">
            <div>
              <p>Land Size</p>
            </div>
            <div className="flex space-x-1  mt-1">
              <img src={area} alt="bed" className="h-5 w-5"></img>
              <p className=" text-center m-auto">{item.landSize} (Katha)</p>
            </div>
          </div>
        ) : null}
      </div>

      <div className="mt-4 sm:mt-auto">
        <div className="flex justify-between items-end">
          <div>
            <p className="font-bold text-gray-500">For Sale</p>
            <span className="md:text-2xl text-xl md:font-semibold text-[#1a9649]">
              Tk {item.price}
            </span>
            {item.exPrice && (
              <span className="ml-2 text-[#808080b2] line-through font-semibold">
                Tk {item.exPrice}
              </span>
            )}
          </div>
          <p className="md:text-sm text-green-900 text-xs">
            {getRelativeTime(item.postDate)}
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default PropertySell;
