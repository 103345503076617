import React from "react";
const ForgotPassword = () => {
  return (
    <div className="MainSection h-[72vh]  w-screen  flex justify-center text-center">
      <h1> Forget password here</h1>
    
    </div>
  );
};

export default ForgotPassword;
