import React, { useEffect, useState, useRef } from "react";
import locationData from "../../../Location.json";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import CardLoading from "../../../component/HorizontalCardLoading";
import { toast } from "react-hot-toast";
import bg from "../../../img/interior-Design.jpg";
import bg2 from "../../../img/interior-bg.jpg";
import { Helmet } from "react-helmet";

//icon
import { CgSpinner } from "react-icons/cg";
import locations from "../../../img/icn/location.png";
import call from "../../../img/icn/call.png";
import cancel from "../../../img/icn/cancel.png";

const InteriorProperty = () => {
  const renderCardLoadingPage = () => {
    return <CardLoading />;
  };

  const navigate = useNavigate();
  const location = useLocation();

  //language
  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  ///Setting font style
  const font = localStorage.getItem("lng");
  let setFont;
  if (font == "bn") {
    setFont = "font-TiroBangla";
  } else if (font == "en") {
    setFont = "font-Poppins-Regular";
  }

  const queryParams = new URLSearchParams(location.search);
  const currentPageQueryParam = queryParams.get("page");
  const initialPage = currentPageQueryParam
    ? parseInt(currentPageQueryParam)
    : 1;

  const [currentPage, setCurrentPage] = useState(initialPage);
  const postPerPage = 8;
  const [loading, setLoading] = useState(true);
  const [dataFromAPI, setDataFromAPI] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const [searchLoading, setSearchLoading] = useState(false);

  const [totalItems, setTotalItems] = useState();

  // Function to fetch data based on page and limit
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/InteriorPostCard?page=${currentPage}&limit=${postPerPage}`
      );
      setDataFromAPI(response.data.items);
      setTotalPages(Math.ceil(response.data.totalItems / postPerPage));
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const updateURL = (page) => {
    const newParams = new URLSearchParams(queryParams);
    newParams.set("page", page);
    window.history.replaceState(
      null,
      null,
      `${location.pathname}?${newParams.toString()}`
    );
  };

  // Function to handle opening detail view
  const handleOpenDetail = (item) => {
    console.log(item._id);
    navigate(`/Interior-Details/${item._id}`);
  };

  //Search
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [divisionId, setDivisionId] = useState("");
  const [district, setDistrict] = useState([]);
  const [districtId, setDistrictId] = useState("");

  const handleDivision = (e) => {
    const getDivisionId = e.target.value;
    // Check if "Select Division" is selected
    if (getDivisionId === "") {
      // Reset the district state
      setDistrict([]);
    } else {
      // Find the selected division and set the district data
      const getDistrictData = locationData.find(
        (division) => division.division_id === getDivisionId
      ).district;
      setDistrict(getDistrictData);
    }
    // Set the divisionId
    setDivisionId(getDivisionId);
  };

  // Modify the handleDistrict function to set sub-districts
  const handleDistrict = (e) => {
    const districtId = e.target.value;
    setDistrictId(districtId);
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/propertyInteriorSearch?searchText=${searchText}&division=${divisionId}&district=${districtId}
        `
        );
        setSuggestions(response.data.items);
      } catch (error) {
        console.error(error);
      }
    };

    // Only fetch suggestions if there is text in the input
    if (searchText.trim() !== "") {
      fetchSuggestions();
    } else {
      setSuggestions([]); // Clear suggestions if the input is empty
    }
  }, [searchText]);

  const handleSearch = async () => {
    setSearchLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/propertyInteriorSearch?searchText=${searchText}&division=${divisionId}&district=${districtId}`
      );
      if (response.data.items.length === 0) {
        toast.error("No result found");
      }
      setTotalItems(response.data.totalItems);
      setSearchResults(response.data.items);
      setSuggestions([]);
      setSearchLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClear = () => {
    setSearchText("");
    setSearchResults([]);
    setSuggestions([]);
  };

  return (
    <div className={`MainSection ${setFont} w-full min-h-screen flex justify-center`}>
      <Helmet>
        <title>Interior Service - Loko Property</title>
      </Helmet>
      <div className="lg:w-[90%] md:w-[95%] w-[98%] max-w-7xl mx-auto">
        <div className="mt-10 h-[50vh] sm:h-[60vh] md:h-[70vh] relative">
          <div className="h-full">
            <img
              src={bg2}
              className="h-full w-full object-cover absolute"
              alt="background"
            />
          </div>

          <div className="TextSection absolute bottom-0 w-full px-4 sm:px-6 md:px-10 lg:px-20 py-6 sm:py-8 md:py-10">
            <div>
              <p className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white drop-shadow-lg">
                Interior Design
              </p>
            </div>

            <div className="mt-3 sm:mt-4 md:mt-5">
              <p className="text-xl sm:text-2xl md:text-3xl font-bold text-white">
                Make your room more Aesthetic
              </p>
            </div>

            <div className="mt-3 sm:mt-4 md:mt-5">
              <p className="text-sm sm:text-base md:text-md text-white line-clamp-3 sm:line-clamp-4 md:line-clamp-none">
                Our Vision is Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book.
              </p>
            </div>

            {/* search section*/}
            <div className="relative mt-6 sm:mt-8 md:mt-12 lg:mt-16 w-full duration-200">
              <div className="flex justify-center w-full duration-200">
                <div className="w-full sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] bg-[#ffffff7c] rounded-lg p-4 flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
                  {/* Location */}
                  <div className="w-full sm:w-auto">
                    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
                      <select
                        name="Division"
                        required
                        className="w-full sm:w-auto h-10 font-semibold text-gray-500 bg-gray-50 border border-gray-300 rounded-xl focus:ring-gray-500 focus:border-gray-500 block"
                        onChange={(e) => handleDivision(e)}
                      >
                        <option value="" className="text-pink-600">
                          Select Division
                        </option>
                        {locationData.map((getDivision, index) => (
                          <option value={getDivision.division_id} key={index}>
                            {getDivision.division_name}
                          </option>
                        ))}
                      </select>

                      {district.length > 0 && (
                        <select
                          name="states"
                          className="w-full sm:w-auto h-10 bg-gray-50 border font-semibold text-gray-500 border-gray-300 rounded-xl focus:ring-gray-500 focus:border-gray-500 block"
                          onChange={(e) => handleDistrict(e)}
                        >
                          <option value="" className="text-pink-600">
                            Select District
                          </option>
                          {district.map((getDistrict, index) => (
                            <option value={getDistrict.district_id} key={index}>
                              {getDistrict.district_name}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>

                  <div className="w-full sm:w-auto flex-grow flex items-center space-x-2">
                    <input
                      type="text"
                      className="w-full h-10 border border-gray-500 rounded-xl p-2"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Title, Division, District"
                    />

                    <button
                      onClick={handleSearch}
                      className="h-10 px-3 bg-white border border-gray-500 font-semibold text-gray-500 rounded-xl flex items-center space-x-2"
                    >
                      {searchLoading && (
                        <CgSpinner size={20} className="animate-spin" />
                      )}
                      <span>Search</span>
                    </button>

                    {(searchText.trim() !== "" ||
                      suggestions.length > 0 ||
                      searchResults.length > 0) && (
                      <button
                        onClick={handleClear}
                        className="h-10 w-10 flex items-center justify-center cursor-pointer"
                      >
                        <img className="h-6 w-6" src={cancel} alt="Clear" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Display loading indicator */}
        {loading ? (
          <div className="w-full flex justify-center items-center">
            <div className="px-2 w-full">{renderCardLoadingPage()}</div>
          </div>
        ) : dataFromAPI.length === 0 ? (
          <div className="h-[50vh] w-full flex text-center justify-center items-center duration-200">
            <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-gray-400">
              No post available
            </p>
          </div>
        ) : (
          <div className="flex">
            <div className="w-full">
              {suggestions.length > 0 ? (
                <div>
                  <p className="text-center my-5 text-yellow-700 bg-yellow-100">
                    Matching with....
                  </p>

                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 place-items-center">
                    {suggestions.map((item) => (
                      <InteriorCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                      />
                    ))}
                  </div>
                </div>
              ) : searchResults && searchResults.length > 0 ? (
                <div>
                  <p className="text-center my-5 text-green-700 bg-green-200">
                    {totalItems} result found
                  </p>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 place-items-center">
                    {searchResults.map((item) => (
                      <InteriorCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                      />
                    ))}
                  </div>
                </div>
              ) : dataFromAPI && dataFromAPI.length > 0 ? (
                <div className="mt-5 duration-200">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 place-items-center">
                    {dataFromAPI.map((item) => (
                      <InteriorCard
                        key={item._id}
                        item={item}
                        handleOpenDetail={handleOpenDetail}
                      />
                    ))}
                  </div>

                  {/* Pagination buttons */}
                  {totalPages > 1 && (
                    <div className="pagination text-center mt-8 mb-8">
                      <div className="flex justify-center items-center space-x-2">
                        {currentPage > 1 && (
                          <button
                            className="pagination-button h-8 w-24 rounded-md border border-green-600 duration-200"
                            onClick={() => {
                              if (currentPage > 1) {
                                setCurrentPage(currentPage - 1);
                                updateURL(currentPage - 1);
                              }
                            }}
                          >
                            {"« Previous"}
                          </button>
                        )}

                        {Array.from({ length: totalPages }).map((_, index) => (
                          <button
                            key={index}
                            className={`pagination-button h-8 w-8 rounded-md border border-green-600 duration-200 ${
                              currentPage === index + 1
                                ? "active bg-green-400"
                                : ""
                            }`}
                            onClick={() => {
                              setCurrentPage(index + 1);
                              updateURL(index + 1);
                            }}
                          >
                            {index + 1}
                          </button>
                        ))}

                        {currentPage < totalPages && (
                          <button
                            className="pagination-button h-8 w-24 rounded-md border border-green-600 duration-200"
                            onClick={() => {
                              if (currentPage < totalPages) {
                                setCurrentPage(currentPage + 1);
                                updateURL(currentPage + 1);
                              }
                            }}
                          >
                            {"Next »"}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const InteriorCard = ({ item, handleOpenDetail }) => (
  <div
    onClick={() => handleOpenDetail(item)}
    className="relative w-full max-w-[330px] h-[460px] cursor-pointer rounded-lg shadow-xl hover:translate-y-[-5px] duration-200"
  >
    <div className="relative overflow-hidden rounded-t-lg">
      <img
        src={item.coverPhoto || "default-image-url.jpg"}
        alt="Cover Photo"
        className="h-[200px] w-full object-cover transition-transform hover:scale-110 duration-500"
      />
    </div>

    <div className="px-4 py-3">
      <div className="flex justify-between bg-gradient-to-l from-transparent to-[#40a5213f] -mx-4 p-2">
        <p className="text-green-900 text-lg font-semibold">
          {item.companyName}
        </p>
      </div>

      <div className="mt-3">
        <div className="flex items-center">
          <img src={locations} className="h-4 w-4 mr-2" alt="Location" />
          <p className="text-sm">
            {item.district}, {item.division}
          </p>
        </div>
        <div className="border-b-2 border-gray-300 mt-2"></div>
      </div>

      <div className="mt-3">
        <p className="text-sm line-clamp-2">{item.companySlogan}</p>
      </div>

      <div className="mt-3">
        <div className="flex justify-between bg-gradient-to-l from-transparent to-[#40a5213f] -mx-4 mb-2 p-2">
          <p className="text-green-900 text-sm font-semibold">
            Available services
          </p>
        </div>

        <div className="flex flex-wrap">
          {Object.entries(item.selections)
            .filter(([key, value]) => value === true)
            .slice(0, 5)
            .map(([key, value]) => (
              <span
                key={key}
                className="bg-green-200 text-green-900 px-2 py-1 rounded-full mr-2 mb-2 text-xs"
              >
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </span>
            ))}
        </div>
      </div>

      <div className="absolute bottom-3 left-4">
        <div className="flex items-center">
          <img src={call} className="h-5 w-5 mr-2" alt="Phone" />
          <p className="text-sm">{item.number}</p>
        </div>
      </div>
    </div>
  </div>
);

export default InteriorProperty;
