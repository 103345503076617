import React, { useRef, useEffect } from "react";
import { useUser } from "../context/UserContext";
import { Link } from "react-router-dom";
import icn_home from "../img/icn/home.png";
import icn_vlogger from "../img/icn/vlogger.png";
import icn_about from "../img/icn/info.png";
import icn_lang from "../img/icn/lang.png";
import icn_support from "../img/icn/support.png";
import icn_user from "../img/icn/icn_user.png";

const SidebarModal = ({
  isOpen,
  onClose,
  t,
  handleChangeLng,
  GetLng,
  lang,
}) => {
  const { user } = useUser();
  const modalRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <div
      className={`fixed inset-0 blur-background transition-opacity duration-300 z-50 flex justify-end ${
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        ref={modalRef}
        className={`bg-white w-64 h-full shadow-lg transform transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="p-4 flex justify-between items-center">
          <h2 className="text-xl font-bold text-[#f5882b]">LOKO PROPERTY</h2>

          <button onClick={onClose} className="text-black hover:text-gray-700">
            <svg
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="p-2 space-y-3">
          {user ? (
            <Link
              to="/Profile"
              className="block text-gray-600 py-2 hover:bg-gray-200 rounded-lg p-2 font-semibold"
              onClick={onClose}
            >
              <img
                src={user.proPic || icn_user}
                className="h-8 w-8 rounded-full inline mr-2"
                alt="Profile"
              />
              {user.name}
            </Link>
          ) : (
            <Link
              to="/SignIn"
              className="bg-[#f5882b] font-semibold p-2 rounded-lg text-white inline hover:text-gray-100"
              onClick={onClose}
            >
              {t("navbar.login")}
            </Link>
          )}

          <Link
            to="https://lokoproperty.com/Home"
            className="block py-2 hover:bg-gray-200  p-2 text-sm uppercase font-semibold text-gray-600"
            onClick={onClose}
          >
            <img
              src={icn_home}
              className="h-4 w-4 inline mr-2 mb-1 "
              alt="home"
            />
            {t("navbar.home")}
          </Link>

          <Link
            to="/Blog"
            className="block py-2 hover:bg-gray-200  p-2 text-sm uppercase font-semibold text-gray-600"
            onClick={onClose}
          >
            <img
              src={icn_vlogger}
              className="h-4 w-4 inline mr-2  mb-1"
              alt="Blog"
            />
            {t("navbar.Blog")}
          </Link>
          <Link
            to="/CustomerCare"
            className="block py-2 hover:bg-gray-200  p-2 text-sm uppercase font-semibold text-gray-600"
            onClick={onClose}
          >
            <img
              src={icn_support}
              className="h-4 w-4 inline mr-2  mb-1"
              alt="Customer Care"
            />
            {t("navbar.customer_care")}
          </Link>
          <Link
            to="/AboutUs"
            className="block py-2 hover:bg-gray-200  p-2 text-sm uppercase font-semibold text-gray-600"
            onClick={onClose}
          >
            <img
              src={icn_about}
              className="h-4 w-4 inline mr-2  mb-1"
              alt="About"
            />
            {t("navbar.about_us")}
          </Link>
          <div
            className="block py-2 hover:bg-gray-200 cursor-pointer p-2 text-sm uppercase font-semibold text-gray-600"
            onClick={() => {
              handleChangeLng(GetLng === "bn" ? "en" : "bn");
              onClose();
            }}
          >
            <img
              src={icn_lang}
              className="h-4 w-4 inline mr-2  mb-1"
              alt="Language"
            />
            {lang}
          </div>
          <Link
            to="/DownloadApp"
            className="block py-2 hover:bg-gray-200 p-2 text-sm uppercase font-semibold text-gray-600"
            onClick={onClose}
          >
            {t("navbar.download_app")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SidebarModal;
