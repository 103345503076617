import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import AdminNavBar from "../admin/AdminNavBar";
import { motion } from 'framer-motion';
import { FaUser, FaEnvelope, FaPhone, FaUserShield } from 'react-icons/fa';
import { Helmet } from "react-helmet";

const InfoCard = ({ title, value, icon }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="bg-white p-6 rounded-xl shadow-lg flex items-center space-x-4"
  >
    <div className="text-3xl text-blue-500">{icon}</div>
    <div>
      <h3 className="text-lg font-semibold text-gray-700">{title}</h3>
      <p className="text-xl text-gray-900">{value}</p>
    </div>
  </motion.div>
);

function AdminInfo() {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [adminData, setAdminData] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const font = localStorage.getItem("lng");
  const setFont = font === "bn" ? "font-TiroBangla" : "font-Poppins-Regular";

  useEffect(() => {
    if (!localStorage.getItem("ad-t")) {
      navigate("/admin");
    }

    const fetchPrivateDate = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ad-t")}`,
        },
      };

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/adminpanel`,
          config
        );
        setAdminData(data.admin);
        setIsLoading(false);
        localStorage.setItem("ad-n", data.admin.name);
      } catch (error) {
        localStorage.removeItem("ad-t");
        setError("You are not authorized, please login");
        navigate("/admin");
      }
    };

    fetchPrivateDate();
  }, [navigate]);

  return (
    <div className={`MainSection ${setFont} min-h-screen`}>
       <Helmet>
        <title>Admin Info - Admin Panel</title>
      </Helmet>
      <div className="md:flex md:my-5 my-1">
        <AdminNavBar activePage="AdminInfo" />
        <div className="w-full p-8 bg-gray-100">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-3xl font-bold mb-8 text-gray-800 flex items-center">
              <FaUserShield className="mr-4 text-blue-500" />
              Admin Information
            </h1>
          </motion.div>
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <motion.div
                animate={{ rotate: 360 }}
                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full"
              />
            </div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
            >
              <InfoCard
                title="Name"
                value={adminData.name}
                icon={<FaUser />}
              />
              <InfoCard
                title="Email"
                value={adminData.email}
                icon={<FaEnvelope />}
              />
              <InfoCard
                title="Phone"
                value={adminData.number}
                icon={<FaPhone />}
              />
            </motion.div>
          )}
          {error && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="mt-6 p-4 bg-red-100 border border-red-400 text-red-700 rounded"
            >
              {error}
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminInfo;