import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { Helmet } from "react-helmet";

import { useNavigate } from "react-router-dom";
import {
  FaUser,
  FaPhone,
  FaEnvelope,
  FaCalendar,
  FaVenusMars,
  FaPencilAlt,
  FaMapMarkerAlt,
  FaCalendarCheck,
  FaSignature,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import PostLoading from "../../component/PostLoading";
import icn_user from "../../img/icn/icn_user.png";

const Profile = () => {
  const navigate = useNavigate();

  const renderPostLoadingPage = () => {
    return <PostLoading />;
  };

  const { user, error, isLoading } = useUser();

  return error ? (
    <span className="error-message">{error}</span>
  ) : isLoading ? (
    <div className="h-full w-full flex justify-center items-center">
      <div className="space-y-3 px-2 w-full">{renderPostLoadingPage()}</div>
    </div>
  ) : (
    <div className="bg-gray-100  min-h-[80vh] ">
      <Helmet>
        <title>Profile - Loko Property</title>
      </Helmet>

      {user.status === "Suspended" && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded">
          <p className="font-bold">Account Suspended</p>
          <p>
            {user.suspendRemaining === null
              ? "Your account is suspended indefinitely."
              : `Your account is suspended until ${
                  user.suspendRemaining.split("T")[0]
                }`}
          </p>
          <Link
            to="/CustomerCare"
            className="text-blue-600 hover:text-blue-800 underline mt-2 inline-block"
          >
            Contact Customer Care
          </Link>
        </div>
      )}

      <div className="max-w-6xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="md:flex">
          {/* Left Side - User Info */}
          <div className="md:w-1/3 bg-gray-50 p-6">
            <div className="text-right mb-4">
              <Link
                to="/profileedit"
                className="inline-flex items-center text-sm text-gray-600 hover:text-gray-800"
              >
                <FaPencilAlt className="mr-1" />
                Edit Profile
              </Link>
            </div>
            <div className="text-center">
              <img
                src={user.proPic || icn_user}
                alt="Profile"
                className="w-32 h-32 rounded-full mx-auto border-4 border-white shadow-lg"
              />
              <h2 className="mt-4 text-2xl font-semibold">{user.name}</h2>
            </div>
            <div className="mt-6 space-y-4">
              {[
                { icon: FaPhone, value: user.number },
                { icon: FaEnvelope, value: user.email },
                { icon: FaCalendar, value: user.birthDate },
                { icon: FaVenusMars, value: user.gender },
              ].map((item, index) => (
                <div key={index} className="flex items-center text-gray-700">
                  <item.icon className="w-5 h-5 mr-3 text-gray-500" />
                  <span>{item.value}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Right Side - Additional Info */}
          <div className="md:w-2/3 p-6">
            <div className="space-y-6">
              <InfoSection title="About" content={user.about} />
              <InfoSection
                title="Address"
                content={user.address}
                icon={FaMapMarkerAlt}
              />
              <InfoSection
                title="Join Date"
                content={user.joiningDate}
                icon={FaCalendarCheck}
              />
              <InfoSection
                title="Signature"
                content={user.signature}
                icon={FaSignature}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoSection = ({ title, content, icon: Icon }) => (
  <div className="border-b pb-4">
    <h3 className="text-lg font-semibold mb-2 flex items-center">
      {Icon && <Icon className="mr-2 text-gray-500" />}
      {title}
    </h3>
    <p className="text-gray-700">{content}</p>
  </div>
);

export default Profile;
