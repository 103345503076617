import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useUser } from "../../context/UserContext";

import ListLoading from "../../component/ListLoading";

import deleted from "../../img/deleted.png";
import location from "../../img/icn/location.png";
import sell from "../../img/icn/for_sell.png";
import rent from "../../img/icn/for_rent.png";
import delete_pic from "../../img/icn/delete.png";

const UserSavedPosts = () => {
  const { user } = useUser();
  const [statusCode, setStatusCode] = useState();
  const navigate = useNavigate();
  const [SavedPosts, setSavedPosts] = useState([]);
  const [deletedPosts, setDeletedPosts] = useState([]);
  const userID = user.id;
  const userToken = localStorage.getItem("userToken");

  const renderListLoadingPage = () => {
    return <ListLoading />;
  };

  useEffect(() => {
    const handleFetchSavedPosts = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/profile/getSavePost`,
          config
        );
        setSavedPosts(response.data.SavedPosts);
        setDeletedPosts(response.data.notFoundIds);
        setStatusCode(response.status);
      } catch (error) {
        console.error("Error making Axios request:", error);
        toast.error(error.message);
      }
    };
    handleFetchSavedPosts();
  }, [userID]);

  // Function to handle opening detail view
  const handleOpenDetail = (item) => {
    if (item.type === "sell") {
      navigate(`/Property-Buy-Sell-Details/${item._id}`);
    }
    if (item.type === "rent") {
      navigate(`/Property-Rent-Details/${item._id}`);
    }
    if (item.type === "group") {
      navigate(`/Group-Wise-Building-Details/${item._id}`);
    }
  };

  const handleDeletePost = async (event, postId) => {
    event.stopPropagation();
    // console.log(postId);
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/profile/deleteUserSavedPost/${postId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      setSavedPosts((prevPosts) =>
        prevPosts ? prevPosts.filter((post) => post._id !== postId) : []
      );
      setDeletedPosts((prevNotFoundIds) =>
        prevNotFoundIds ? prevNotFoundIds.filter((id) => id !== postId) : []
      );
      toast.success("Post Removed");
    } catch (error) {
      console.error("Error deleting post:", error);
      toast.error("Error deleting post");
    }
  };

  return (
    <div className="MainBody overflow-auto styleScrollbar duration-200">
      {statusCode !== 200 ? (
        <div className=" h-[82vh] w-full flex justify-center items-center">
          <div className="space-y-3 px-2 w-full">{renderListLoadingPage()}</div>
        </div>
      ) : (
        <div className="MainBody h-[82vh] space-y-2  overflow-auto styleScrollbar duration-200">
          {SavedPosts && SavedPosts.length > 0 ? (
            SavedPosts.map((post) => (
              <div className="flex space-x-10 duration-200">
                <div className="  w-full duration-200">
                  <div>
                    <div
                      key={post._id}
                      className="border border-gray-400 rounded-lg flex justify-between items-center duration-200 p-1 md:p-4"
                      onClick={() => handleOpenDetail(post)} // Add onClick event
                    >
                      <div className="flex duration-200">
                        <div className="absolute duration-200">
                          {post.type === "sell" && (
                            <div>
                              <img
                                src={sell}
                                alt="sell"
                                className="md:h-12 md:w-12 h-10 w-10 rounded-t-lg object-cover"
                              />
                            </div>
                          )}
                          {post.type === "rent" && (
                            <div>
                              <img
                                src={rent}
                                alt="rent"
                                className="md:h-[45px] md:w-[45px] h-[35px] w-[35px] rounded-t-lg object-cover duration-200"
                              />
                            </div>
                          )}
                        </div>

                        <img
                          src={post.imageUrl} // Use only the first URL
                          alt={post.postTitle}
                          className="mr-2 md:h-24 h-16  rounded-lg  md:w-24 w-16"
                        />
                        <div className="   cursor-pointer">
                          <div className="overflow-hidden">
                            <p className="line-clamp-2 font-bold md:text-base text-xs">
                              {post.postTitle}
                            </p>
                          </div>

                          <p className="md:text-base text-xs">
                            {post.postDate}
                          </p>

                          <p className="md:text-base text-xs">
                            {post.location ? (
                              post.location
                            ) : (
                              <>
                                {post.subDistrict}, {post.district},{" "}
                                {post.division}
                              </>
                            )}
                          </p>

                          <p className="md:text-base text-xs font-bold md:font-semibold text-[#1a9649]">
                            ${post.price}
                          </p>
                        </div>
                      </div>
                      <div className=" flex space-x-3 text-center">
                        <button
                          className="  "
                          onClick={(event) => handleDeletePost(event, post._id)}
                        >
                          <img
                            src={delete_pic}
                            className="md:h-10 md:w-10 h-7 w-7"
                            alt="delete"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              {(!SavedPosts || SavedPosts.length === 0) && !deletedPosts ? (
                <div className="h-[70vh] w-full flex text-center justify-center items-center duration-200">
                  <p className="lg:text-5xl md:text-xl text-md font-bold text-gray-400">
                    No saved posts available.
                  </p>
                </div>
              ) : null}
            </div>
          )}

          {/* deleted posts*/}
          {deletedPosts && deletedPosts.length > 0
            ? deletedPosts.map((post) => (
                <div className=" animate-bounceLr">
                  <div
                    key={post._id}
                    className="border border-gray-400  bg-red-200 rounded-lg flex justify-between items-center duration-200 p-1 md:p-4 mb-2"
                  >
                    <div className="flex">
                      <img
                        src={deleted}
                        alt={post.postTitle}
                        className=" rounded-lg mr-2 md:h-24 h-16 md:w-24 w-16"
                      />
                      <div className="">
                        <p className="md:text-base text-xs">
                          The post you looking for <br /> that is deleted.
                        </p>
                      </div>
                    </div>

                    <div className=" flex space-x-3 text-center">
                      <button
                        className="  "
                        onClick={(event) => handleDeletePost(event, post)}
                      >
                        <img
                          src={delete_pic}
                          className="md:h-10 md:w-10 h-7 w-7"
                          alt="delete"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      )}
    </div>
  );
};

export default UserSavedPosts;
