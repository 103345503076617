import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import PostLoading from "../../../component/PostLoading";
import { Helmet } from "react-helmet";

// icons
import locations from "../../../img/icn/location.png";
import not_found from "../../../img/not-found.png";
import call from "../../../img/icn/call.png";

const InteriorPropertyDetails = () => {
  const { id } = useParams();
  const [interiorPost, setInteriorPost] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const font = localStorage.getItem("lng");
  const setFont = font === "bn" ? "font-TiroBangla" : "font-Poppins-Regular";

  useEffect(() => {
    const fetchInteriorPostDetails = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/api/InteriorPostDetails/${id}`;
        const response = await axios.get(apiUrl);
        setInteriorPost(response.data.interiorPost);
      } catch (error) {
        setErrorMessage("Interior property not found");
        console.error("Error fetching data:", error);
      }
    };

    fetchInteriorPostDetails();
  }, [id]);

  if (!interiorPost && !errorMessage) {
    return (
      <div className="w-full h-[90vh] flex justify-center">
        <div className="w-full md:w-[70%] px-4">
          <PostLoading />
        </div>
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div className="w-full h-[90vh] flex justify-center items-center px-4">
        <div className="text-center">
          <img
            className="mx-auto w-1/2 md:w-1/3 max-w-xs"
            src={not_found}
            alt="not found"
          />
          <p className="text-3xl md:text-5xl mt-5 font-bold text-red-200">
            {errorMessage}
          </p>
          <div className="mt-10">
            <Link
              to="/Interior-Design"
              className="inline-block border-2 hover:bg-slate-200 duration-200 py-2 px-5 rounded-lg text-xl font-bold text-gray-500"
            >
              ← Go Back
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const { images } = interiorPost;

  return (
    <div className={`MainSection ${setFont} w-full flex justify-center`}>
      <Helmet>
        <title>Interior Details - Loko Property</title>
      </Helmet>
      <div className="w-full md:w-[90%] lg:w-[80%] xl:w-[70%] px-4">
        <div>
          <div className="mt-10 h-[30vh] md:h-[40vh] lg:h-[50vh] relative">
            <img
              src={interiorPost.coverPhoto}
              className="h-full w-full object-cover absolute"
              alt="background"
            />
            <div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
            <div className="absolute bottom-0 p-4 md:p-6 lg:p-10 w-full">
              <div className="relative">
                <p className="text-2xl md:text-3xl lg:text-4xl font-bold text-white">
                  {interiorPost.companyName}
                </p>
                <p className="text-lg md:text-xl text-white mt-2">
                  Make your room more Aesthetic
                </p>
              </div>
            </div>
          </div>
          <div className="my-4">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-2 md:space-y-0">
              <div className="flex items-center">
                <img src={locations} className="h-5 w-5 mr-2" alt="location icon" />
                <p className="text-sm md:text-base">
                  Address: {interiorPost.district}, {interiorPost.division}
                </p>
              </div>
              <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
                <div className="flex items-center">
                  <img src={call} className="h-5 w-5 mr-2" alt="call icon" />
                  <p className="text-sm md:text-base">{interiorPost.number}</p>
                </div>
                <div className="flex items-center">
                  <img src={call} className="h-5 w-5 mr-2" alt="call icon" />
                  <p className="text-sm md:text-base">{interiorPost.moreNumber}</p>
                </div>
              </div>
            </div>
            <p className="mt-6 text-xl md:text-2xl text-gray-700 font-bold">
              Description
            </p>
            <p className="mt-3 text-sm md:text-base text-gray-900">
              {interiorPost.postDetails}
            </p>
            {images && Object.keys(images).length > 0 && (
              <div className="w-full mt-8 md:mt-16">
                <div className="space-y-8 md:space-y-16">
                  {Object.keys(images).map((key) => (
                    <div
                      key={key}
                      className="border border-gray-300 rounded-b-lg"
                    >
                      <img
                        src={images[key]}
                        alt={`${key} design`}
                        className="w-full"
                      />
                      <h3 className="text-center py-2 text-sm md:text-base text-gray-500 font-semibold">
                        {key.charAt(0).toUpperCase() + key.slice(1)} Design
                      </h3>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteriorPropertyDetails;