import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PostLoading from "../../../../component/PostLoading";

//icons

import Logo from "../../../../img/icn/Logo.png";
import warningIcon from "../../../../img/icn/warningIcon.png";
import icn_user from "../../../../img/icn/icn_user.png";
import check from "../../../../img/icn/check.png";
import rent from "../../../../img/icn/for_rent.png";
import group from "../../../../img/icn/group.png";
import loc from "../../../../img/icn/location.png";
import bed from "../../../../img/icn/bed.png";
import bath from "../../../../img/icn/bath.png";
import area from "../../../../img/icn/area.png";
import garage from "../../../../img/icn/garage.png";

const GroupPostDetailsForAd = () => {
  const { id } = useParams(); // Get the product ID from the URL
  const [propertyData, setPropertyData] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const [propertyImages, setPropertyImages] = useState([]); // Initialize as an empty array
  const [bookings, setBookings] = useState([]); // Initialize as an empty array
  const [img, setImg] = useState(null); // Initialize as null
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const token = localStorage.getItem("ad-t");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  ///Language
  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  ///Setting font style
  const font = localStorage.getItem("lng");
  let setFont;
  if (font == "bn") {
    setFont = "font-TiroBangla";
  } else if (font == "en") {
    setFont = "font-Poppins-Regular";
  }

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/api/admin/getGroupBuildingDetailsForAd/${id}`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(apiUrl, config)
      .then((response) => {
        // Set the retrieved data in the state
        setPropertyData(response.data.groupBuildingPost);
        setPropertyImages(response.data.groupBuildingPost.imageUrls);
        setAdminData(response.data.admin);
        setBookings(response.data.bookings);
      })
      .catch((error) => {
        navigate("/home");
        console.error("Error fetching data:", error);
      });
  }, [id]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Change image every 10 seconds
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % propertyImages.length
      );
    }, 10000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [propertyImages.length]);

  const changeImage = (direction) => {
    // Change image based on direction (prev or next)
    if (direction === "prev") {
      setCurrentImageIndex(
        (prevIndex) =>
          (prevIndex - 1 + propertyImages.length) % propertyImages.length
      );
    } else {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % propertyImages.length
      );
    }
  };

  const handleThumbnailClick = (index) => {
    // Handle thumbnail click to change main image
    setCurrentImageIndex(index);
  };

  const hasOtherFacility =
    propertyData &&
    Object.keys(propertyData).some(
      (key) =>
        [
          "mosque",
          "security",
          "fireExit",
          "fireProtection",
          "securityAlarm",
          "electronicSecurity",
          "conversation",
          "wasaConnection",
          "selfWaterSupply",
          "hotWater",
          "cylinderGas",
          "gymnasium",
          "pool",
          "garden",
          "generator",
          "intercom",
          "wifiConnection",
          "satelliteCableTv",
          "solarPanel",
          "servantRoom",
          "guestParking",
        ].includes(key) && propertyData[key] === true
    );

  return (
    <div className={`  ${setFont} `}>
      {propertyData ? (
        <div>
          <Helmet>
            <title>Group Building Post Details - Admin Panel</title>
          </Helmet>
          {/* Image slider section is here*/}
          <div
            className={`mt-4 w-full flex justify-center items-center duration-200  `}
          >
            <div className=" w-[98%] md:w-[90%] xl:w-[85%] 2xl:w-[75%] duration-200 ">
              <div className="image-slider-container w-full bg-slate-50 p-5 rounded-2xl overflow-hidden">
                <div className="main-image-container relative overflow-hidden">
                  <div className="absolute bg-cover blur-md  scale-[5]">
                    <img
                      src={propertyImages[currentImageIndex]}
                      className="m-auto block bg-cover "
                    />
                  </div>

                  <img
                    className="m-auto block 2xl:h-[500px] xl:h-[400px] h-[300px] rounded-xl object-contain relative"
                    src={propertyImages[currentImageIndex]}
                    alt={`property-${currentImageIndex}`}
                  />

                  <div className="h-[40px] flex  justify-between  w-full  top-0 absolute  duration-200">
                    {propertyData.type === "group" ? (
                      <div>
                        <img src={group} alt="camera" className="h-24 w-24" />
                      </div>
                    ) : (
                      <div>
                        <img
                          src={rent}
                          alt="camera"
                          className="h-[85px] w-[85px]"
                        />
                      </div>
                    )}
                  </div>

                  {propertyImages.length > 1 && (
                    <>
                      <div className="absolute top-1/2 left-0 transform -translate-y-1/2 z-20">
                        <button
                          className="h-8 w-5 hover:w-10 duration-200 text-center items-center bg-[#0000008f] text-white font-bold rounded-[100%]"
                          onClick={() => changeImage("prev")}
                        >
                          ◀
                        </button>
                      </div>

                      <div className="absolute top-1/2 right-0 transform -translate-y-1/2 z-20">
                        <button
                          className="h-8 w-5 hover:w-10 duration-200 text-center items-center bg-[#0000008f] text-white font-bold rounded-[100%]"
                          onClick={() => changeImage("next")}
                        >
                          ▶
                        </button>
                      </div>
                    </>
                  )}



                  <div className="lg:px-3 px-1 h-[50px] w-full m-auto bottom-0 flex absolute  bg-gradient-to-b from-transparent to-[#000000]  duration-200">
                    {adminData.proPic ? (
                      <img
                        src={adminData.proPic}
                        alt="User Profile"
                        className="lg:h-10 h-8 lg:w-10 w-8 rounded-full"
                      />
                    ) : (
                      <img
                        src={Logo}
                        alt="User Profile"
                        className="lg:h-10 h-8 lg:w-10 w-8 rounded-full"
                      />
                    )}

                    <h2 className="text-white p-2 lg:text-base text-xs ">
                      {"Admin: "} {adminData.username}
                    </h2>

                    <h2 className="text-white p-2 lg:text-base text-xs">
                      {"Added: "}
                      {propertyData.postDate}
                    </h2>
                  </div>
                </div>

                <div className="thumbnail-container  mt-3 flex justify-between items-center ">
                  <div>
                    {propertyImages.map((image, index) => (
                      <button
                        className="focus:outline-none"
                        key={index}
                        onClick={() => handleThumbnailClick(index)}
                      >
                        <img
                          className="mr-2 block 2xl:h-20 lg:h-16 h-12 xl:w-36 lg:w-24 w-16 rounded-md duration-200 hover:translate-y-[-3px] ease-in-out hover:shadow-xl"
                          src={image}
                          alt={`thumbnail-${index}`}
                        />
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Display property details here using propertyData */}
          <div
            className={` w-full flex  justify-center items-center duration-200  `}
          >
            <div className="w-[98%] md:w-[90%] xl:w-[85%] 2xl:w-[75%] duration-200  ">
              <div className="w-full bg-slate-100 lg:p-5 p-3 rounded-2xl mt-4">
                <div className="Title font-bold lg:text-3xl text-xl">
                  <p>{propertyData.postTitle}</p>
                </div>

                <div className=" flex flex-wrap lg:space-x-4 space-x-1  ">
                  {/*Total Price*/}
                  <div className="Price flex items-center justify-center rounded-lg font-bold 2xl:text-2xl text-lg mt-4 lg:h-12 h-10 px-2 bg-white">
                    <span className="">{"Total Price :\u00A0"} </span>
                    <span className="text-green-700 ">
                      {propertyData.price} Tk.
                    </span>
                    <span className=" text-sm">{"\u00A0 (approximate) "} </span>
                  </div>

                  {/*Land Price*/}
                  <div className="Price flex items-center justify-center rounded-lg font-bold 2xl:text-2xl text-lg mt-4 lg:h-12 h-10 px-2 bg-white">
                    <span className="">{"Land Price :\u00A0"} </span>
                    <span className="text-green-700 ">
                      {propertyData.landPrice} Tk.
                    </span>
                    <span className=" text-sm">{"\u00A0 (approximate) "} </span>
                  </div>

                  {/*location*/}
                  <div className="division flex items-center justify-center rounded-lg  text-md mt-4 lg:h-12 h-10 px-2 bg-white">
                    <img src={loc} alt="" className="h-5 w-5 mr-2"></img>

                    <p className="lg:text-base text-sm">
                      {propertyData.subDistrict}, {propertyData.district},{" "}
                      {propertyData.division}{" "}
                    </p>
                  </div>
                </div>

                <div className=" flex flex-wrap lg:space-x-4 space-x-1  ">
                  <div className="flex flex-wrap space-x-4">
                    <div className="Bedroom flex items-center justify-center rounded-lg  text-md mt-4 lg:h-12 h-10 px-2 bg-white">
                      <img src={bed} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {propertyData.bedroom}
                        {" Bedrooms"}
                      </p>
                    </div>

                    <div className="Bathroom flex items-center justify-center rounded-lg  text-md mt-4 lg:h-12 h-10 px-2 bg-white">
                      <img src={bath} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {propertyData.bathroom}
                        {" Bathrooms"}
                      </p>
                    </div>

                    <div className="Bathroom flex items-center justify-center rounded-lg  text-md mt-4 lg:h-12 h-10 px-2 bg-white">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {propertyData.balconies}
                        {" Balconies"}
                      </p>
                    </div>

                    {propertyData.garages ? (
                      <div className="Garage flex items-center justify-center rounded-lg  text-md mt-4 lg:h-12 h-10 px-2 bg-white">
                        <img src={garage} alt="" className="h-5 w-5 mr-2"></img>
                        <p className="text-black lg:text-base text-xs">
                          {"Garage :"} {propertyData.garages}
                        </p>
                      </div>
                    ) : null}

                    <div className="size flex items-center justify-center rounded-lg  text-md mt-4 lg:h-12 h-10 px-2 bg-white">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Flat Size "}
                        {propertyData.size}
                        {" ft²"}
                      </p>
                    </div>

                    <div className="landSize flex items-center justify-center rounded-lg  text-md mt-4 lg:h-12 h-10 px-2 bg-white">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Land Size "}
                        {propertyData.landSize}
                        {" Katha"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-10">
                  <h2 className="2xl:text-3xl xl:text-xl text-lg font-bold text-gray-500">
                    Description
                  </h2>
                  <p className="mt-4"> {propertyData.postDetails}</p>
                </div>
              </div>
            </div>
          </div>

          {/*Property Specifications*/}
          <div
            className={` w-full flex justify-center items-center duration-200  `}
          >
            <div className="  w-[98%] md:w-[90%] xl:w-[85%] 2xl:w-[75%] duration-200  ">
              <div className="mt-4 bg-slate-100 p-5 rounded-2xl">
                <p className="font-bold 2xl:text-3xl xl:text-xl text-lg text-gray-500">
                  Property Specifications
                </p>

                <div className=" grid  xl:grid-cols-5 grid-cols-2 lg:grid-cols-4 gap-2 ">
                  {/* Construction Status*/}
                  <div className=" flex items-center rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-fuchsia-100">
                    <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="text-black lg:text-base text-xs">
                      {"Construction Status: "}
                      {propertyData.construction}
                      {""}
                    </p>
                  </div>

                  {/* Condition*/}
                  <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-purple-200">
                    <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="text-black lg:text-base text-xs">
                      {"Condition : "}
                      {propertyData.condition}
                    </p>
                  </div>

                  {/* Bedroom*/}
                  {propertyData.bedroom ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-violet-100">
                      <img src={bed} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Bedrooms : "}
                        {propertyData.bedroom}
                      </p>
                    </div>
                  ) : null}

                  {/* Bathroom*/}
                  {propertyData.bathroom ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-indigo-100">
                      <img src={bath} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Bathrooms : "}
                        {propertyData.bathroom}
                      </p>
                    </div>
                  ) : null}

                  {/* Balconies*/}
                  {propertyData.balconies ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-emerald-100">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Balconies : "}
                        {propertyData.balconies}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Lift*/}
                  {propertyData.lift ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-yellow-100">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Lift : "}
                        {propertyData.lift}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Drawing rooms*/}
                  {propertyData.drawingRooms ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-amber-100">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Drawing Rooms : "}
                        {propertyData.drawingRooms}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Garages*/}
                  {propertyData.garages ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-cyan-200">
                      <img src={garage} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Garage : "}
                        {propertyData.garages}
                      </p>
                    </div>
                  ) : null}

                  {/* Furnishing*/}
                  {propertyData.furnishing ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-purple-100">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Furnishing : "}
                        {propertyData.furnishing}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Facing*/}
                  {propertyData.facing ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2  bg-orange-100">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Facing : "}
                        {propertyData.facing}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Total Unit*/}
                  {propertyData.unit ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-blue-200">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Unit : "}
                        {propertyData.unit}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Total floor*/}
                  {propertyData.totalFloor ? (
                    <div className=" flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-green-200">
                      <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                      <p className="text-black lg:text-base text-xs">
                        {"Total Floor : "}
                        {propertyData.totalFloor}
                        {""}
                      </p>
                    </div>
                  ) : null}

                  {/* Total available floor*/}
                  {propertyData.availableFloor ? (
                    <div
                      className={`flex items-center rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-red-200 relative ${
                        propertyData.availableFloor.split(",").length > 5
                          ? "group"
                          : ""
                      }`}
                      title={
                        propertyData.availableFloor.split(",").length > 5
                          ? propertyData.availableFloor
                          : ""
                      }
                    >
                      <img src={area} alt="" className="h-5 w-5 mr-2" />
                      <p className="text-black lg:text-base text-xs truncate">
                        {"Available Floor : "}
                        {propertyData.availableFloor.split(",").length > 5
                          ? propertyData.availableFloor
                              .split(",")
                              .slice(0, 5)
                              .join(",") + "..."
                          : propertyData.availableFloor}
                      </p>
                      {propertyData.availableFloor.split(",").length > 5 && (
                        <div className="absolute left-0 bottom-full mt-2 p-2 bg-red-200 shadow-lg rounded-lg hidden group-hover:block z-10">
                          {propertyData.availableFloor}
                        </div>
                      )}
                    </div>
                  ) : null}

                  <div className="size flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                    <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="text-black lg:text-base text-xs">
                      {"Flat Size :"}
                      {propertyData.size}
                      {" ft²"}
                    </p>
                  </div>

                  <div className="landSize flex items-center  rounded-lg  text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                    <img src={area} alt="" className="h-5 w-5 mr-2"></img>
                    <p className="text-black lg:text-base text-xs">
                      {"Land Size :"}
                      {propertyData.landSize}
                      {" Katha"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Other facility 2.0*/}
          <div
            className={` w-full flex justify-center items-center duration-200  `}
          >
            <div className="w-[98%] md:w-[90%] xl:w-[85%] 2xl:w-[75%] duration-200 ">
              <div className="mt-3 bg-slate-100 p-5  rounded-2xl">
                <div className="">
                  <p className="2xl:text-3xl xl:text-xl text-lg font-bold text-gray-500">
                    {" "}
                    {hasOtherFacility ? <div>Others facility</div> : null}
                  </p>

                  <div className="grid  xl:grid-cols-5 grid-cols-2 lg:grid-cols-4 gap-2 ">
                    {propertyData.mosque === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Mosque"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Mosque
                        </p>
                      </div>
                    ) : null}

                    {propertyData.security === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="security"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Security
                        </p>
                      </div>
                    ) : null}

                    {propertyData.fireExit === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="fireExit"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Fire Exit
                        </p>
                      </div>
                    ) : null}

                    {propertyData.fireProtection === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="fireProtection"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Fire protection
                        </p>
                      </div>
                    ) : null}

                    {propertyData.securityAlarm === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt=" Security Alarm system"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          {" "}
                          Security Alarm system
                        </p>
                      </div>
                    ) : null}

                    {propertyData.electronicSecurity === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Electronic security"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Electronic security
                        </p>
                      </div>
                    ) : null}

                    {propertyData.conversation === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Conversation Room"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Conversation Room
                        </p>
                      </div>
                    ) : null}

                    {propertyData.wasaConnection === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt=" Wasa connection"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          {" "}
                          Wasa connection
                        </p>
                      </div>
                    ) : null}

                    {propertyData.hotWater === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Hot water"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Hot water
                        </p>
                      </div>
                    ) : null}

                    {propertyData.selfWaterSupply === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="selfWaterSupply"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Self Water Supply
                        </p>
                      </div>
                    ) : null}

                    {propertyData.cylinderGas === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Cylinder gas"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Cylinder gas
                        </p>
                      </div>
                    ) : null}

                    {propertyData.gymnasium === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Gymnasium"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Gymnasium
                        </p>
                      </div>
                    ) : null}

                    {propertyData.pool === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Pool"
                        ></img>
                        <p className="text-black lg:text-base text-xs">Pool</p>
                      </div>
                    ) : null}

                    {propertyData.garden === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Garden"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Garden
                        </p>
                      </div>
                    ) : null}

                    {propertyData.generator === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Generator"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Generator
                        </p>
                      </div>
                    ) : null}

                    {propertyData.intercom === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Intercom"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Intercom
                        </p>
                      </div>
                    ) : null}

                    {propertyData.wifiConnection === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Wi-Fi connection"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Wi-Fi connection
                        </p>
                      </div>
                    ) : null}

                    {propertyData.satelliteCableTv === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt=" Satellite or cable TV"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          {" "}
                          Satellite or cable TV
                        </p>
                      </div>
                    ) : null}

                    {propertyData.solarPanel === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Solar panel"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Solar panel
                        </p>
                      </div>
                    ) : null}

                    {propertyData.servantRoom === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md mt-4 lg:h-14 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Servant room"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Servant room
                        </p>
                      </div>
                    ) : null}

                    {propertyData.guestParking === true ? (
                      <div className="construction mr-2 flex items-center  rounded-lg text-md  mt-4 lg:h-12 h-10 px-2 bg-white">
                        <img
                          src={check}
                          className="lg:h-5 h-3 lg:w-5 w-3 mr-2"
                          alt="Guest parking"
                        ></img>
                        <p className="text-black lg:text-base text-xs">
                          Guest parking
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Booked users*/}
          <div
            className={` w-full flex justify-center items-center duration-200 `}
          >
            <div className=" w-[98%] md:w-[90%] xl:w-[85%] 2xl:w-[75%] duration-200 ">
              <div className="comments-section p-5 mt-8">
                <h3 className="text-xl font-semibold mb-4 text-gray-500">
                  {bookings.length > 0 && bookings[0].bookings.length > 0 ? (
                    `Booked by (${bookings[0].bookings.length})`
                  ) : (
                    <p>Booked by</p>
                  )}
                </h3>
                {bookings.length > 0 && bookings[0].bookings.length > 0 ? (
                  bookings[0].bookings.map((booking, index) => (
                    <div
                      key={index}
                      className="comment mb-4 p-4 bg-gray-100 rounded-lg shadow-lg"
                    >
                      <div className="comment-header">
                        <div className="flex  space-x-6 items-center">
                          <div className="flex flex-col space-y-2 items-center">
                            <img
                              src={icn_user}
                              alt="Comment"
                              className="w-10 h-10 rounded-full  "
                            />
                            <p className=" text-gray-500 text-xs">
                              {booking.commentDate.split("•")[0]}
                            </p>
                          </div>
                          <div>
                            <h5 className="font-semibold text-gray-500">
                              {"Name : "}
                              {booking.userName}
                            </h5>
                            <h4 className="font-semibold text-gray-500">
                              {"Occupation : "}
                              {booking.userOccupation}
                            </h4>
                            <h4 className="font-semibold text-gray-500">
                              {"Number : "} {booking.userNumber}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No comments yet.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full h-[90vh] flex justify-center">
          <div className="w-[80%]">
            <PostLoading />
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupPostDetailsForAd;
