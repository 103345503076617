import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AdminNavBar from "./AdminNavBar";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import {
  FaUserTie,
  FaUsers,
  FaHome,
  FaBuilding,
  FaPaintBrush,
} from "react-icons/fa";
import { MdPendingActions } from "react-icons/md";

const DashboardCard = ({
  title,
  value,
  icon,
  gradient,
  additionalInfo,
  pending = false,
  children,
}) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className={`2xl:p-6 p-3 rounded-2xl shadow-xl ${gradient}`}
  >
    <div className="flex items-center justify-between mb-4">
      <div className="text-4xl text-white">{icon}</div>
      {pending && (
        <div className="animate-pulse bg-red-500 rounded-full h-3 w-3"></div>
      )}
    </div>
    <h3 className="2xl:text-xl text:lg font-semibold text-white mb-2">{title}</h3>
    <span className="text-3xl font-bold text-white">{value}</span>
    {additionalInfo && (
      <span className="text-sm text-white ml-4">{additionalInfo}</span>
    )}
    {children}
  </motion.div>
);

function Dashboard() {
  const navigate = useNavigate();
  const [DashboardData, setDashboardData] = useState("");
  const [isLoadingLocal, setIsLoadingLocal] = useState(true);

  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const font = localStorage.getItem("lng");
  let setFont = font === "bn" ? "font-TiroBangla" : "font-Poppins-Regular";

  useEffect(() => {
    if (!localStorage.getItem("ad-t")) {
      navigate("/admin");
    }

    const fetchPrivateData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ad-t")}`,
        },
      };

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/dashboard`,
          config
        );
        setDashboardData(data.DashboardInfo);
        setIsLoadingLocal(false);
      } catch (error) {
        localStorage.removeItem("ad-t");
        localStorage.removeItem("ad-n");
        // Handle error (e.g., redirect to login page)
      }
    };

    fetchPrivateData();
  }, [navigate]);

  return (
    <div className={`MainBody w-full min-h-screen rounded-[20px] duration-200 ${setFont}`}>
       <Helmet>
        <title>Dashboard - Admin Panel</title>
      </Helmet>
      <div className="md:flex md:my-5 my-1 ">
        <AdminNavBar activePage="Dashboard" />

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="w-full"
        >
          <div className="">
            {isLoadingLocal ? (
              <div className="flex justify-center items-center h-64">
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                  className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full"
                />
              </div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="p-8 bg-gray-100"
              >
                <h1 className="text-3xl font-bold mb-8 text-gray-800">
                  Dashboard Overview
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                  <Link to="/admin/admininfo">
                    <DashboardCard
                      title="Total Admin"
                      value={DashboardData.TotalAdmin}
                      icon={<FaUserTie />}
                      gradient="bg-gradient-to-br from-purple-600 to-blue-500"
                    />
                  </Link>
                  <Link to="/admin/usermanage">
                    <DashboardCard
                      title="Total User"
                      value={DashboardData.TotalUser}
                      icon={<FaUsers />}
                      gradient="bg-gradient-to-br from-pink-500 to-orange-400"
                    />
                  </Link>
                  <Link to="/admin/sellpostmanage">
                    <DashboardCard
                      title="Total Sell Post"
                      value={DashboardData.TotalSellPost}
                      icon={<FaHome />}
                      gradient="bg-gradient-to-br from-green-500 to-teal-400"
                    />
                  </Link>
                  <Link to="/admin/rentpostmanage">
                    <DashboardCard
                      title="Total Rent Post"
                      value={DashboardData.TotalRentPost}
                      icon={<FaBuilding />}
                      gradient="bg-gradient-to-br from-blue-500 to-indigo-500"
                    />
                  </Link>
                  <Link to="/admin/sellpostmanage">
                    <DashboardCard
                      title="Pending Sell Post"
                      value={DashboardData.TotalPendingSellPost}
                      icon={<MdPendingActions />}
                      gradient="bg-gradient-to-br from-red-500 to-pink-500"
                      pending={true}
                    />
                  </Link>
                  <Link to="/admin/rentpostmanage">
                    <DashboardCard
                      title="Pending Rent Post"
                      value={DashboardData.TotalPendingRentPost}
                      icon={<MdPendingActions />}
                      gradient="bg-gradient-to-br from-yellow-400 to-orange-500"
                      pending={true}
                    />
                  </Link>
                  <Link to="/admin/Group-Building-Manage">
                    <DashboardCard
                      title="Total Group Building Posts"
                      value={DashboardData.TotalGroupBuildingPost}
                      icon={<FaBuilding />}
                      gradient="bg-gradient-to-br from-indigo-500 to-purple-500"
                    />
                  </Link>
                  <Link to="/admin/interior">
                    <DashboardCard
                      title="Total Interior Posts"
                      value={DashboardData.TotalInteriorPost}
                      icon={<FaPaintBrush />}
                      gradient="bg-gradient-to-br from-teal-400 to-green-500"
                      additionalInfo={`Enabled: ${DashboardData.TotalEnabledInteriorPost} | Disabled: ${DashboardData.TotalDisabledInteriorPost}`}
                    >

                    </DashboardCard>
                  </Link>
                </div>
              </motion.div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Dashboard;
