import { React, useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import locationData from "../../../../Location.json";
import axios from "axios";
import { useUser } from "../../../../context/UserContext";
import { CgSpinner } from "react-icons/cg";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

const InteriorAddPost = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  ///Language
  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  ///Setting font style
  const font = localStorage.getItem("lng");
  let setFont;
  if (font == "bn") {
    setFont = "font-TrioBangla";
  } else if (font == "en") {
    setFont = "font-Poppins-Regular";
  }
  const token = localStorage.getItem("ad-t");

  //get value from Form
  const [error, setError] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companySlogan, setCompanySlogan] = useState("");
  const [postDetails, setPostDetails] = useState("");
  const [number, setNumber] = useState("");
  const [moreNumber, setMoreNumber] = useState("");
  const [coverPic, setCoverPic] = useState(null);
  const [divisionId, setDivisionId] = useState("");
  const [district, setDistrict] = useState([]);
  const [districtId, setDistrictId] = useState("");

  const handleDivision = (e) => {
    const getDivisionId = e.target.value;
    if (getDivisionId === "") {
      setDistrict([]);
    } else {
      const getDistrictData = locationData.find(
        (division) => division.division_id === getDivisionId
      ).district;
      setDistrict(getDistrictData);
    }
    setDivisionId(getDivisionId);
  };

  const handleDistrict = (e) => {
    const districtId = e.target.value;
    setDistrictId(districtId);
  };

  const [selections, setSelections] = useState({
    interior: false,
    exterior: false,
    furniture: false,
    home: false,
    duplexHouse: false,
    office: false,
    villa: false,
    restaurant: false,
    cafe: false,
    hotel: false,
    hospital: false,
    bank: false,
    showroom: false,
    gym: false,
    dentalClinic: false,
  });

  const [images, setImages] = useState({
    interior: null,
    exterior: null,
    furniture: null,
    home: null,
    duplexHouse: null,
    office: null,
    villa: null,
    restaurant: null,
    cafe: null,
    hotel: null,
    hospital: null,
    bank: null,
    showroom: null,
    gym: null,
    dentalClinic: null,
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setSelections({
      ...selections,
      [name]: checked,
    });
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    setImages({
      ...images,
      [name]: files[0],
    });
  };

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleModalSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };
  const handleContinue = () => {
    setShowConfirmation(false);
    handleSubmit();
  };
  const handleCancel = () => {
    setShowConfirmation(false);
  };

  // Universal function to resize an image
  const resizeImage = async (imageFile, maxWidth = 1080, maxHeight = 1920) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(imageFile);
      img.onload = () => {
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          if (width / maxWidth > height / maxHeight) {
            height *= maxWidth / width;
            width = maxWidth;
          } else {
            width *= maxHeight / height;
            height = maxHeight;
          }

          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            resolve(blob);
          }, imageFile.type);
        } else {
          resolve(imageFile);
        }
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    if (!coverPic) {
      toast.error("Please select an image.");
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("companyName", companyName);
      formData.append("companySlogan", companySlogan);
      formData.append("postDetails", postDetails);
      formData.append("number", number);
      formData.append("moreNumber", moreNumber);
      formData.append("division", divisionId);
      formData.append("district", districtId);

      const resizedImageBlob = await resizeImage(coverPic);

      formData.append("coverPic", resizedImageBlob, coverPic.name);

      // Append selections and images to formData
      formData.append("selections", JSON.stringify(selections));
      for (const [key, value] of Object.entries(images)) {
        if (value) {
          const resizedImage = await resizeImage(value);
          formData.append(key, resizedImage, value.name);
        }
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/setInteriorAddPost`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Response:", response.data);

      if (response.status !== 200) {
        toast.error(response.data.error);
      } else {
        toast.success("Post uploaded");
        navigate(-1);
      }
    } catch (axiosError) {
      if (axiosError.response) {
        toast.error(axiosError.response.data.error);
        console.error("Response error:", axiosError.response.data);
      } else if (axiosError.request) {
        toast.error("No response from the server. Please try again.");
        console.error("Request error:", axiosError.request);
      } else {
        toast.error("An error occurred. Please try again.");
        console.error("Axios error:", axiosError.message);
      }
    }
  };

  return (
    <div
      className={` ${setFont} w-full flex justify-center items-center duration-200 `}
    >
       <Helmet>
        <title>Interior Post - Admin Panel</title>
      </Helmet>
      <div className="h-full w-full 2xl:max-w-[85%] xl:max-w-[90%] max-w-[99%] lg:p-0 p-2  duration-200 ">
        <div className="  ">
          <p></p>
        </div>

        <form
          onSubmit={handleModalSubmit}
          encType="multipart/form-data "
          className=" mt-5 "
        >
          <div className="  lg:flex justify-center md:space-y-0 space-y-5 lg:space-x-10 ">
            {/* Left Side*/}
            <div className="leftSide  lg:w-[50%]   ">
              {/* Company Name*/}
              <div className=" ">
                <p className=" text-xl">Interior Company Name</p>
                <textarea
                  className=" border-2 mt-5 border-gray-300 rounded-xl p-2 lg:w-[100%]  w-[330px]"
                  type="text"
                  rows="1"
                  required
                  placeholder="Company Name"
                  onChange={(e) => setCompanyName(e.target.value)}
                ></textarea>
              </div>

              {/* Company CompanySlogan*/}
              <div className=" ">
                <p className=" text-xl">Company Slogan</p>
                <textarea
                  className=" border-2 border-gray-300 rounded-xl p-2 lg:w-[100%]  w-[330px]"
                  type="text"
                  rows="1"
                  placeholder="Company Tagline/Slogan (Optional)"
                  onChange={(e) => setCompanySlogan(e.target.value)}
                ></textarea>
              </div>

              {/* Description */}
              <div className="my-3">
                <p className=" text-xl">Description </p>

                <textarea
                  className=" border-2 border-gray-300 rounded-xl p-2 lg:w-[100%] w-[330px]"
                  type="text"
                  rows="8"
                  required
                  placeholder="less than 500 characters"
                  onChange={(e) => setPostDetails(e.target.value)}
                ></textarea>
              </div>

              {/* contact number*/}
              <div className="mt-4  ">
                <div className="mt-2">
                  <p className="inline mr-2">Contact Number </p>
                  <input
                    required
                    className="border-2 h-8 w-[165px] rounded-lg border-gray-300 px-1 pl-2"
                    placeholder="1xxxxxxxx"
                    type="number"
                    onChange={(e) => setNumber(e.target.value)}
                  ></input>
                </div>
              </div>

              {/* contact number secondery*/}
              <div className="mt-4  ">
                <div className="mt-2">
                  <p className="inline mr-2">More Contact Number </p>
                  <input
                    required
                    className="border-2 h-8 w-[165px] rounded-lg border-gray-300 px-1 pl-2"
                    placeholder="1xxxxxxxx"
                    type="number"
                    onChange={(e) => setMoreNumber(e.target.value)}
                  ></input>
                </div>
              </div>

              {/* Image upload*/}

              <div className="mt-10 mr-2">
                <div className="text-xl mb-5">Attachment for Cover Picture</div>
                <input
                  id="coverImage"
                  type="file"
                  className="mt-1 block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
                  accept="image/*"
                  onChange={(e) => setCoverPic(e.target.files[0])}
                />
              </div>

              {/* Location*/}
              <div className="mt-5 duration-200">
                <div className="flex space-x-3">
                  <div className="relative md:h-10 h-8  lg:w-full md:w-[200px]">
                    <select
                      name="Division"
                      required
                      className="md:h-10 h-8 w-full bg-gray-50 border  border-gray-300 text-gray-900 rounded-xl focus:ring-gray-500 focus:border-gray-500 block  dark:bg-white dark:border-gray-500 dark:placeholder-gray-400 dark:text-black dark:focus:ring-gray-500 dark:focus:border-gray-500"
                      onChange={(e) => handleDivision(e)}
                    >
                      <option value="" className="text-pink-600">
                        Select Division
                      </option>
                      {locationData.map((getDivision, index) => (
                        <option value={getDivision.division_id} key={index}>
                          {getDivision.division_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {district.length > 0 && (
                    <div className="relative md:h-10 h-8 w-full">
                      <select
                        name="states"
                        className="md:h-10 h-8 w-full bg-gray-50 border  border-gray-300 text-gray-900 rounded-xl focus:ring-gray-500 focus:border-gray-500 block  dark:bg-white dark:border-gray-500 dark:placeholder-gray-400 dark:text-black dark:focus:ring-gray-500 dark:focus:border-gray-500"
                        onChange={(e) => handleDistrict(e)}
                      >
                        <option value="" className="text-pink-600">
                          Select District
                        </option>
                        {district.map((getDistrict, index) => (
                          <option value={getDistrict.district_id} key={index}>
                            {getDistrict.district_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Right Side*/}
            <div className="rightSide  lg:w-[50%]  ">
              <p className="text-xl">Available Services</p>

              <div className="mt-5">
                {Object.keys(selections).map((key) => (
                  <div className="border-2 rounded-lg p-2 mb-2" key={key}>
                    <input
                      className="mr-2"
                      type="checkbox"
                      name={key}
                      checked={selections[key]}
                      onChange={handleCheckboxChange}
                    />
                    <label>{key.charAt(0).toUpperCase() + key.slice(1)}</label>

                    {selections[key] && (
                      <div className=" flex items-center">
                        <p>Upload a Demo Image of your Service</p>
                        <input
                          className="ml-2"
                          type="file"
                          required
                          name={key}
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="w-[250px] m-auto lg:mt-3 mt-5 flex gap-1 items-center justify-center py-2.5 text-white rounded-xl bg-[#F59E0B] hover:bg-[#fd7200] duration-200"
          >
            {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
            <span className="text-md">Submit</span>
          </button>
        </form>
      </div>

      {/* Confirmation Overlay Modal*/}

      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white h-[300px] w-[400px] p-6 rounded-2xl  relative">
            <h3 className="text-2xl  mb-4 text-green-500 font-bold">
              Confirmation
            </h3>

            <div className=" text-center items-center">
              <p>সব কিছু ঠিক ঠাক আছে ?</p>
              <p className="mt-6">আরেকবার পড়ে নেন</p>
              <p>আপনি কি সব পরেছেন ? </p>
            </div>

            <div className=" flex absolute bottom-0 space-x-2 right-0 p-5">

            <button
                className=" px-4 m-auto lg:mt-3 mt-5 flex gap-1 items-center justify-center py-2.5 text-white rounded-xl bg-[#ff4d4d] hover:bg-[#ff5462] duration-200"

                onClick={handleCancel}
              >
                Cancel
              </button>

              <button
                onClick={handleSubmit}
                className=" px-4 m-auto lg:mt-3 mt-5 flex gap-1 items-center justify-center py-2.5 text-white rounded-xl bg-[#2cbd33] hover:bg-[#4ce954] hover:text-green-800 hover:font-semibold duration-200"
              >
                {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span className="text-md">Continue</span>
              </button>


            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default InteriorAddPost;
