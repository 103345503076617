import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { React, useState, useEffect } from "react";
import axios from "axios";
import { CgSpinner } from "react-icons/cg";
import logo from "../../img/icn/Logo.png";

export default function Login() {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const [loading, setLoading] = useState(false);

  //get value for Sign in
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (localStorage.getItem("ad-t")) {
      navigate("/admin/dashboard");
    }
  });

  const loginHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/login`,
        { number, password },
        config
      );
      localStorage.setItem("ad-t", data.token);

      navigate("/admin/dashboard");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1500);
      toast.success("Welcome Back!");
    } catch (error) {
      setError(error.response.data.error);
      toast.error("Pleas insert correct number or password");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <div className=" lg:h-[80.5vh] h-[70vh] w-full flex justify-center items-center ">
      <div className=" lg:h-[500px] xl:w-[30%] lg:w-[40%] md:w-[50%] h-[480px] w-[90%] bg-orange-200  lg:rounded-[60px] rounded-[40px] duration-300">
        <div className="lg:h-[500px]  h-[350px]  flex justify-center items-center ">
          <div className="">
            <p className=" text-2xl text-black duration-200"> Admin Login</p>

            <form className="mt-10 duration-200" onSubmit={loginHandler}>
              {error && (
                <p className="error-message duration-200 text-red-600 font-bold ">
                  {error}
                </p>
              )}
              <input
                className="h-12  w-72 text-black border-[1px] rounded-xl border-cyan-900 placeholder-gray-900 p-3 text-xl"
                type="number"
                required
                id="number"
                placeholder="Phone number"
                onChange={(e) => setNumber(e.target.value)}
                value={number}
                tabIndex={1}
              />

              <div className="my-5">
                <input
                  type="password"
                  className="h-12  w-72 text-black border-[1px] rounded-xl border-cyan-900 placeholder-gray-900 p-3 text-xl"
                  required
                  id="password"
                  autoComplete="true"
                  placeholder="Enter password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  tabIndex={2}
                />
              </div>

              <button
                type="submit"
                className="bg-emerald-600 w-full flex gap-1 mt-12 items-center justify-center py-2.5 text-white rounded-xl"
              >
                {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span>Login</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
