import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import AdminNavBar from "../pages/admin/AdminNavBar";

export default function AboutUs() {
  const { t, i18n } = useTranslation();
  const handleChangeLng = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const renderPostLoadingPage = () => {
    return <AdminNavBar />;
  };

  return (
    <div className="w-full">
      	<Helmet>
        <title>About us - Loko Property</title>
      </Helmet>
      <div className="mt-10">{renderPostLoadingPage()}</div>
    </div>
  );
}
